import { DEFAULT_ROW_PER_PAGE } from '@/src/app.constants';
import { EnhancedTableHead } from '@/src/components';
import { headCellsUsers } from '@/src/static';
import { QueryParamsGetList } from '@/src/types';
import { cookieOptions } from '@/src/utils';
import { isNullOrEmpty } from '@/src/utils/validation/stringValidate';
import { useUserStoreContext } from '@/store/user.store';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

const UserTableHeader = () => {
  const { pagination, staffs } = useUserStoreContext();
  const [rowsPerPage] = useState(DEFAULT_ROW_PER_PAGE);
  // eslint-disable-next-line no-unused-vars
  const [paginationParams, setPaginationParams] = useState<QueryParamsGetList<any>>({ page: pagination.page, itemPerPage: rowsPerPage });
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['sortingUsers']);

  useEffect(() => {
    if (!isNullOrEmpty(pagination?.order) && !isNullOrEmpty(pagination?.orderBy)) {
      setCookie('sortingUsers' as any, JSON.stringify({ orderBy: pagination.orderBy, order: pagination.order }), cookieOptions());
    }
  }, [pagination.order, pagination.orderBy]);

  return (
    <EnhancedTableHead
      disabledSorting={staffs.length === 0}
      headCells={headCellsUsers}
      order={pagination.order}
      orderBy={pagination.orderBy}
      setOrder={pagination.onSetOrder}
      setOrderBy={pagination.onSetOrderBy}
      setPaginationParams={setPaginationParams}
      respectSortAble
      isGreenHeader={true}
    />
  );
};

export default UserTableHeader;

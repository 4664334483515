import React, { useEffect, useState } from 'react';
import { Box, Paper, Table, TableContainer } from '@mui/material';
import {
  EnhancedTableHead,
  EnhancedTableBodyRestaurantMenu,
  LoadingIcon,
  AddRestaurantMenu,
  PopupSnackbar,
  EnhancedTablePaginationV2,
  ConditionalWrapper,
} from '@/components/index';
import { DEFAULT_ROW_PER_PAGE } from '@/app.constants/index';
import { QueryParamsGetList, QueryMenuListSortBy, order } from '@/types/index';
import { headCellsRestaurantMenu } from '@/static/restaurantMenu';
import { initialMenuState, useMenuContextV2, useRestaurantContext, useSnackbarContext } from '@/services/index';

import { useParams } from 'react-router-dom';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { useCookies } from 'react-cookie';
import { cookieOptions } from '@/src/utils';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import TableBodyNodata from '@/src/components/ui/tableBodyNoData';
import noOne from '@/images/noOne.png';
import ModalForFirstMenuFallbackMeal from '@/src/components/ui/modal/modalFirstFallbackMeal';
import SnackBarChangeFallbackMeal from '@/src/components/ui/snackBarChangeFallbackMeal';

const defaultParamsGetList: QueryParamsGetList<QueryMenuListSortBy> = {
  page: 0,
  itemPerPage: DEFAULT_ROW_PER_PAGE,
  orderBy: 'thName',
  sort: 'asc',
};

export function Menu(props: any) {
  const { id } = useParams();
  const [menuPaginationParams, setMenuPaginationParams] = useState(defaultParamsGetList);
  const [isInitialGetData, setIsInitialGetData] = useState(true);
  const { setResponseStatus } = useSnackbarContext();
  // SORTING / PAGINATION
  const [order, setOrder] = useState<order | null>(menuPaginationParams.sort || 'asc');
  const [orderBy, setOrderBy] = useState<QueryMenuListSortBy | null>(menuPaginationParams.orderBy || 'thName');
  const [page, setPage] = useState(menuPaginationParams.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(menuPaginationParams.itemPerPage || DEFAULT_ROW_PER_PAGE);
  const [open, setOpen] = useState(false);
  const [openFallback, setOpenFallback] = useState(false);
  const [total, setTotal] = useState(0);
  const [isChange, setIsChange] = useState(false);
  const { menuList, menuCreate, fallbackMealChange, menuListForOrder } = useMenuContextV2();
  const { restaurantDropdownList, setRestaurantDropdownList, setRestaurantDropdownListData } = useRestaurantContext();
  const [dropdownAddOnsData, setDropdownAddOnsData] = useState<{ label: string; value: string }[]>([]);
  const [dropdownOptionGroupsData, setDropdownOptionGroupsData] = useState<{ label: string; value: string }[]>([]);
  const [cookies, setCookie] = useCookies(['sortingMenu']);
  const sorting: any = {};
  const handleOpenModalFallbackMeal = () => setOpenFallback(true);
  const handleCloseModalFallbackMeal = () => setOpenFallback(false);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!fallbackMealChange?.current) return;

    switch (fallbackMealChange.current.state) {
      case STATE_ENUM.READY:
        if (id) {
          setResponseStatus({
            status: 'success',
            message: fallbackMealChange.current?.error || 'The fallback meal has been successfully updated.',
          });
        }
        fallbackMealChange.setState({ state: STATE_ENUM.INITIAL });
        break;
      case STATE_ENUM.ERROR:
        if (id) {
          setResponseStatus({
            status: 'error',
            message: fallbackMealChange.current?.status?.message || 'Something went wrong. Please try again.',
          });
        }
        fallbackMealChange.setState({ state: STATE_ENUM.INITIAL });
        break;
      default:
        break;
    }
  }, [fallbackMealChange, id]);

  useEffect(() => {
    if (menuCreate?.current?.data?.fallbackMeal === true) {
      handleOpenModalFallbackMeal();
    }
  }, [menuCreate]);

  // NOTE: initial API call
  useEffect(() => {
    if (id) {
      setIsInitialGetData(false);
      if (typeof cookies.sortingMenu !== 'undefined') {
        menuPaginationParams.orderBy = cookies.sortingMenu?.orderBy;
        menuPaginationParams.sort = cookies.sortingMenu?.sort;
      }
      menuList.setState(initialMenuState.menuList.current);
      setRestaurantDropdownListData({
        state: STATE_ENUM.INITIAL,
        data: [],
      });

      setOrderBy(menuPaginationParams.orderBy || orderBy);
      setOrder(menuPaginationParams.sort || order);

      menuList.requestAct(id, menuPaginationParams);

      menuListForOrder.requestAct(id || '');
    }
  }, []);

  useEffect(() => {
    if (menuList.current.state === STATE_ENUM.READY && restaurantDropdownList.state === STATE_ENUM.INITIAL) {
      setRestaurantDropdownList(apiPathV1.restaurantDropdownList, id, { types: ['addon', 'group'] });
    }
    if (menuList.current.state === STATE_ENUM.READY && !isChange) {
      setTotal(menuList.current.pagination?.total || 0);
    }
  }, [menuList.current]);

  useEffect(() => {
    if (menuList.current.state !== STATE_ENUM.INITIAL && id && !isInitialGetData) {
      if (typeof menuPaginationParams.orderBy !== 'undefined') sorting.orderBy = menuPaginationParams.orderBy;
      if (typeof menuPaginationParams.sort !== 'undefined') sorting.sort = menuPaginationParams.sort;

      setCookie('sortingMenu' as any, JSON.stringify(sorting), cookieOptions());
      menuList.requestAct(id, menuPaginationParams);
    }
  }, [menuPaginationParams]);

  useEffect(() => {
    // NOTE: check if restaurantDropdownList data is available and update the dropdown data accordingly
    if (restaurantDropdownList.state === STATE_ENUM.READY && restaurantDropdownList.data) {
      const getAddonsData = restaurantDropdownList.data?.find((list) => list.type === 'addon')?.data;
      const getOptionGroupsData = restaurantDropdownList.data?.find((list) => list.type === 'group')?.data;
      setDropdownAddOnsData(getAddonsData || []);
      setDropdownOptionGroupsData(getOptionGroupsData || []);
    }
  }, [restaurantDropdownList]);

  return (
    <Box>
      <AddRestaurantMenu
        dropdownAddOnsData={dropdownAddOnsData}
        dropdownOptionGroupsData={dropdownOptionGroupsData}
        setOpen={setOpen}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        setPage={setPage}
        setTotal={setTotal}
        total={total}
        page={page}
        rowsPerPage={rowsPerPage}
        menuPaginationParams={menuPaginationParams}
        setMenuPaginationParams={setMenuPaginationParams}
      />
      <ConditionalWrapper condition={menuList.current.state !== STATE_ENUM.LOADING} alternativeNode={<LoadingIcon setCenter={false}></LoadingIcon>}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
          <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                sx={{
                  minWidth: 750,
                }}
                id="menu-item-table"
              >
                <EnhancedTableHead
                  headCells={headCellsRestaurantMenu}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  disabledSorting={menuList.current.data?.menuAddonOverviewList.length === 0}
                  setPaginationParams={setMenuPaginationParams}
                  respectSortAble
                />
                {menuList.current.state === STATE_ENUM.READY && menuList.current.data!.menuAddonOverviewList.length > 0 ? (
                  <EnhancedTableBodyRestaurantMenu
                    rows={menuList.current.data?.menuAddonOverviewList || []}
                    order={order}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    setOrder={setOrder}
                    setOpen={setOpen}
                    setTotal={setTotal}
                    setIsChange={setIsChange}
                    total={total}
                    dropdownAddOnsData={dropdownAddOnsData}
                    dropdownOptionGroupsData={dropdownOptionGroupsData}
                    setPage={setPage}
                    setPaginationParams={setMenuPaginationParams}
                  />
                ) : (
                  <TableBodyNodata page={'optionGroup'} src={noOne} title="No results found." />
                )}
              </Table>
              <EnhancedTablePaginationV2
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                total={total}
                setPaginationParams={setMenuPaginationParams}
                disabledPagination={!menuList.current.data?.menuAddonOverviewList.length}
              />
            </TableContainer>
            <PopupSnackbar open={open} setOpen={setOpen}></PopupSnackbar>
            <SnackBarChangeFallbackMeal
              open={snackBarOpen}
              handleClose={() => {
                setSnackBarOpen(false);
              }}
              message={'Success!'}
              description={'The fallback meal has been successfully updated.'}
            />
          </Paper>
        </Box>
      </ConditionalWrapper>
      {/* TABLE */}
      <div>
        <ModalForFirstMenuFallbackMeal open={openFallback} handleClose={handleCloseModalFallbackMeal} />
      </div>
    </Box>
  );
}

import React, { useState, useMemo } from 'react';
import TableBody from '@mui/material/TableBody';
import { Button, Stack, TableCell, TableRow, Tooltip } from '@mui/material';

import { Delete as DeleteIcon } from '@mui/icons-material';
import Modal from '../modal/modalOrder';
import { useOrdersContextV2 } from '@/services/index';

export function EnhancedTableBodyOrder(props: any) {
  const { rows, page, rowsPerPage, bookingId } = props;
  const { orderDelete, ordersSummaryList } = useOrdersContextV2();

  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState('');

  const [orderId, setOrderId] = useState('');

  const handleOpenDelete = (orderId: string) => {
    setType('DELETE');
    setOpenModal(true);
    setOrderId(orderId);
  };

  const rowsPerPageShow = useMemo(() => {
    return rowsPerPage < 0 ? 0 : rowsPerPage;
  }, [rowsPerPage]);

  return (
    <TableBody id="tbody-order">
      {rows?.map((row: any, index: number) => {
        return (
          <TableRow id={'tr-order-' + index} hover tabIndex={-1} key={`${row.userId}+ ${index}`} sx={{ width: 20 }}>
            <TableCell id={'td-order-col1-' + index} align="right">
              {page * rowsPerPageShow + index + 1}
            </TableCell>
            <TableCell id={'td-order-col2-' + index} align="left">
              {row.userName}
            </TableCell>
            <TableCell id={'td-order-col3-' + index} align="left">
              {row.menu.menuThName}
            </TableCell>
            <TableCell id={'td-order-col4-' + index} align="left">
              {row.addon.map((data: any) => data.addonThName).join(', ')}
            </TableCell>
            <TableCell id={'td-order-col5-' + index} sx={{ maxWidth: '30vw', overflowWrap: 'break-word' }} align="left">
              {row.remark}
            </TableCell>
            <TableCell id={'td-order-col6-' + index} className="export-omit" align="right">
              {row.price}
            </TableCell>
            <TableCell id={'td-order-col7-' + index} align="center" className="btnActionArea export-omit">
              <Stack direction="row" spacing={2} className="btnAreaAction export-omit">
                <Tooltip id="tooltip-delete-order" title="Delete" placement="bottom-start">
                  {ordersSummaryList.current.data?.bookingStatus !== 'Ordered' && ordersSummaryList.current.data?.bookingStatus !== 'Closed' ? (
                    <Button id="btn-delete-order" variant="outlined" color="error" size="small" disableElevation onClick={() => handleOpenDelete(row.orderId)}>
                      <DeleteIcon></DeleteIcon>
                    </Button>
                  ) : (
                    <></>
                  )}
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        );
      })}

      {/* NOTE: this TableRow is hidden, just for allow table to place modal inside */}
      <TableRow id="tr-order-modal">
        <TableCell id="td-order-modal" sx={{ padding: '0px', border: 'none' }}>
          <Modal
            id="modal-order"
            openModal={openModal}
            type={type}
            setOpenModal={setOpenModal}
            bookingId={bookingId}
            orderId={orderId}
            orderDelete={orderDelete?.current}
            setOrderDelete={orderDelete?.requestAct}
            setType={setType}
          ></Modal>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { EnhancedTablePaginationV2, LoadingIcon, PopupSnackbar } from '@/src/components';
import { useUserStoreContext } from '@/store/user.store';
import UserTableHeader from './userTableHeader';
import UserTableBody from './userTableBody';
import { TableCell, TableFooter, TableRow } from '@mui/material';
import ModalConfirmInactiveUser from '@/src/components/ui/modal/modalConfirmInactiveUser';
import { useState, useEffect } from 'react';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { useRoleStoreContext } from '@/store/role.store';
import ModalForChangeUserRole from '@/src/components/ui/modal/modalChangeUserRole';
import { useSnackbarContext } from '@/store/snackbar.store';
import { ChangeUserRole } from '@/src/types/crud/role/user.rol';

const styles = {
  tableData: {
    '& th': {
      padding: '16px 20px',
      fontWeight: 'bold',
    },
    '& td': {
      fontWeight: 'lighter !important',
    },
  },
};

const UserTableData = () => {
  const { staffs, pagination, loading, updateUserStatus, changeUserRole, removeUserRole } = useUserStoreContext();
  const { fetchRoles } = useRoleStoreContext();
  const [openModalUserStatus, setOpenModalUserStatus] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState('');
  const [isActiveUser, setIsActiveUser] = useState<boolean>(false);
  const [currentStaff, setCurrentStaff] = useState<any>(undefined);
  const [open, setOpen] = useState(false);
  const { roles } = useRoleStoreContext();
  const { setResponseStatus } = useSnackbarContext();

  const handleEditRole = (staff: any) => {
    if (staff) {
      setCurrentStaff(staff);
      setOpen(true);
    }
  };

  const handleConfirmChangeUserRole = (checkedItems: any[]) => {
    if (currentStaff) {
      const removeList: string[] = [];
      const updateList: string[] = [];
      // loop for remove role
      currentStaff.roles.forEach((role) => {
        if (!checkedItems?.find((t) => t.id === role.id && t.checked)) {
          removeList.push(role.id);
        }
      });
      // loop for add new role
      checkedItems
        ?.filter((t) => t.checked)
        ?.forEach((role) => {
          if (!currentStaff.roles.find((t) => t.id === role.id)) {
            updateList.push(role.id);
          }
        });

      if (removeList.length) {
        const remove = {
          userId: currentStaff.userId,
          roleIds: removeList,
          isReloadUser: updateList.length === 0,
        };
        removeUserRole.requestAct(remove);
      }

      if (updateList.length) {
        const change: ChangeUserRole = {
          userId: currentStaff.userId,
          roleIds: updateList,
        };
        changeUserRole.requestAct(change);
      }
    }

    setOpen(false);
  };

  const handleCloseChangeUserRole = () => {
    setCurrentStaff(undefined);
    setOpen(false);
  };

  const handleUserStatus = (userId: string, activeUser: boolean) => {
    setUserId(userId);
    setIsActiveUser(activeUser);
    setOpenModalUserStatus(true);
  };

  const handleConfirmInActive = () => {
    setOpenModalUserStatus(false);
    if (userId && isActiveUser !== undefined) {
      updateUserStatus.requestAct(userId, isActiveUser);
    }
  };

  useEffect(() => {
    if (fetchRoles.current.state !== STATE_ENUM.LOADING && fetchRoles.current.state !== STATE_ENUM.READY) {
      fetchRoles.requestAct();
    }
  }, [fetchRoles]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    switch (changeUserRole.current.state) {
      case STATE_ENUM.ERROR:
        setResponseStatus({
          status: 'error',
          message: changeUserRole.current.error || 'Update role of user has been something went wrong. Please try again.',
        });
        break;
    }
  }, [changeUserRole]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    switch (removeUserRole.current.state) {
      case STATE_ENUM.ERROR:
        setResponseStatus({
          status: 'error',
          message: removeUserRole.current.error || 'Update role of user has been something went wrong. Please try again.',
        });
        break;
    }
  }, [removeUserRole]);

  if (loading) {
    return <LoadingIcon setCenter={false}></LoadingIcon>;
  }

  return (
    <TableContainer>
      <Table aria-label="users table" sx={styles.tableData}>
        <UserTableHeader />
        <UserTableBody handleUserStatus={handleUserStatus} handleEditRole={handleEditRole} />
        <TableFooter>
          <TableRow sx={{ borderTop: '1px solid #E0E0E0' }}>
            <TableCell colSpan={9} sx={{ padding: 0 }}>
              <EnhancedTablePaginationV2
                disabledPagination={staffs?.length === 0}
                total={pagination.total}
                rowsPerPage={pagination.rowsPerPage}
                page={pagination.page}
                setPage={pagination.onSetPage}
                setRowsPerPage={pagination.onSetRowsPerPage}
                setPaginationParams={() => {}}
                countCurrentRows={0}
                addRowsPerPageOption={[
                  { value: 60, label: '60' },
                  { value: -1, label: 'All' },
                ]}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <PopupSnackbar open={snackBarOpen} setOpen={setSnackBarOpen}></PopupSnackbar>
      <ModalConfirmInactiveUser
        open={openModalUserStatus}
        isActiveUser={isActiveUser}
        onConfirm={() => {
          handleConfirmInActive();
        }}
        onCancel={() => {
          setOpenModalUserStatus(false);
        }}
      />
      <ModalForChangeUserRole
        open={open}
        handleClose={handleCloseChangeUserRole}
        handleConfirm={handleConfirmChangeUserRole}
        user={currentStaff}
        roles={roles || []}
      ></ModalForChangeUserRole>
    </TableContainer>
  );
};

export default UserTableData;

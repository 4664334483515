import React, { useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Box, Toolbar, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Logo from '@/images/logo.png';
import { LoginMenu } from '@/components/index';
import { useAccessMenuContext } from '@/services/index';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { apiPathV1 } from '@/src/app.constants';
import { AccessMenu } from '@/src/types';
const APPLICATION_NAME = 'Manao Meals';

const useStyles = makeStyles({
  Navbar_link: {
    padding: '0',
    marginRight: '40px',
    backgroundColor: 'transparent',
    textDecoration: 'none',
    fontSize: '18px',
    fontFamily: 'Prompt',
    color: '#4CAF50',
    '&:hover, &.active': {
      color: '#9E9E9E !important',
      backgroundColor: 'transparent',
    },
  },
});
export const Header = (props: any) => {
  const classes = useStyles();
  const { accessMenusData, getAccessMenu } = useAccessMenuContext();
  const [pages, setPages] = React.useState<AccessMenu[]>([]);

  useEffect(() => {
    if (accessMenusData.state === STATE_ENUM.INITIAL) {
      getAccessMenu(apiPathV1.authPermissions);
    }
  }, []);

  useEffect(() => {
    if (accessMenusData.state === STATE_ENUM.READY) {
      setPages(accessMenusData?.data || []);
    }
  }, [accessMenusData]);
  const navBars = useMemo(() => {
    return pages
      .sort((a, b) => b.sequence - a.sequence)
      .map((page, id) => (
        <NavLink key={id} id={'menu-' + page.permissionId} to={page.menuPath} className={classes.Navbar_link}>
          {page.displayNameEn}
        </NavLink>
      ));
  }, [pages]);

  return (
    <AppBar
      position="sticky"
      color="inherit"
      sx={{
        boxShadow: 3,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters id="toolbar">
          {/* DESKTOP size : Icon and App name */}
          <NavLink to="/dashboard" id="manao-meals-logo">
            <Box
              component="img"
              sx={{
                height: 30,
                mr: 2,
              }}
              alt={APPLICATION_NAME}
              src={Logo}
            />
          </NavLink>
          {/* DESKTOP size : Navigation button*/}
          <Box
            sx={{
              flexGrow: 1,
              flexDirection: 'row-reverse',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {navBars}
          </Box>

          {/* User menu */}
          <LoginMenu {...props}></LoginMenu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

import React, { useEffect, useState } from 'react';
import { Box, Table, TableContainer, FormControl, Paper } from '@mui/material';
import noOne from '@/images/noOne.png';
import { YearPicker } from '@/src/components/ui/yearPicker';
import { useUserOrderHistoryList } from '@/store/userOderHistory.store';
import { useParams } from 'react-router-dom';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { MonthPicker } from '@/src/components/ui/monthPicker';
import { ConditionalWrapper, EnhancedTableBodyUserOrderHistory, EnhancedTableHead, LoadingIcon } from '@/src/components';
import { order } from '@/src/types';
import { headCellsUserOrderHistory } from '@/src/static/userOrderHistory';
import { OrderHistoryItem } from '@/src/types/schema/userOrderHistory';
import TableBodyNodata from '@/src/components/ui/tableBodyNoData';
const UserOrderHistory = () => {
  const { year, month, fetchUserOrderHistoryList } = useUserOrderHistoryList();

  const [order, setOrder] = useState<order | null>('desc');

  const [orderBy, setOrderBy] = useState<keyof OrderHistoryItem>('date');

  const { userId } = useParams();

  const userOrderHistoryData = fetchUserOrderHistoryList.current.data?.historyList;

  useEffect(() => {
    if (fetchUserOrderHistoryList.current.state === STATE_ENUM.INITIAL && userId) {
      fetchUserOrderHistoryList.requestAct(userId, year.current, month.current, true);
    }
  }, []);

  const onSetYear = (v: number) => {
    if (userId) {
      fetchUserOrderHistoryList.requestAct(userId, v, month.current, false);
    }
  };

  const onSetMonth = (v: number) => {
    if (userId) {
      fetchUserOrderHistoryList.requestAct(userId, year.current, v, false);
    }
  };

  useEffect(() => {
    if (fetchUserOrderHistoryList.current.state !== STATE_ENUM.INITIAL && userId && order) {
      fetchUserOrderHistoryList.requestAct(userId, year.current, month.current, false, orderBy, order);
    }
  }, [order, orderBy, userId]);

  return (
    <Box>
      <Box py={0} component="form" sx={{ mb: '50px', display: 'flex', gap: 5 }} noValidate autoComplete="off">
        <FormControl variant="outlined" size="small" color="secondary" sx={{ width: '25ch' }}>
          <MonthPicker Month={month.current} setMonth={onSetMonth} />
        </FormControl>
        <FormControl variant="outlined" size="small" color="secondary" sx={{ width: '25ch' }}>
          <YearPicker year={year.current} setYear={onSetYear} />
        </FormControl>
      </Box>

      <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table aria-label="users-order-history-table">
            <EnhancedTableHead
              headCells={headCellsUserOrderHistory}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              disabledSorting={fetchUserOrderHistoryList.current.data?.historyList?.length === 0}
            />

            {fetchUserOrderHistoryList.current.data?.historyList?.length === 0 || userOrderHistoryData?.length === 0 ? (
              <TableBodyNodata page="user-order-history" src={noOne} title="No results found." />
            ) : (
              <EnhancedTableBodyUserOrderHistory rows={userOrderHistoryData} order={order} orderBy={orderBy} setOrder={setOrder} setOrderBy={setOrderBy} />
            )}
          </Table>
        </TableContainer>
      </Paper>

      <ConditionalWrapper condition={fetchUserOrderHistoryList.current.state === STATE_ENUM.LOADING}>
        <LoadingIcon setCenter={false}></LoadingIcon>
      </ConditionalWrapper>
    </Box>
  );
};

export default UserOrderHistory;

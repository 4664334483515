import { Box, Button, ButtonGroup, Container, FormControl, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SummaryData from './summary';
import { AnonymProps } from '@/src/types';
import { BrowserHistory } from 'history';
import DashBoardCostView from './costs';
import DashBoardOrderView from './orders';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDashboardData } from '@/store/dashboard.store';
import { LoadingIcon } from '@/src/components';
import { DashboardPeriod, DashboardView } from '@/src/types/crud/dashboard/dashboard.summary';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

const PAGE_TITLE = 'Dashboard';

interface RedirectProps extends AnonymProps {
  history: BrowserHistory;
}

const Dashboard: React.FC<any> = (props: RedirectProps) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const [view, setView] = useState<DashboardView>('d');
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);
  const { fetchDashboardData } = useDashboardData();

  const viewMap: Record<DashboardView, DashboardPeriod> = {
    w: 'weekly',
    m: 'monthly',
    d: 'daily',
    y: 'yearly',
  };

  useEffect(() => {
    if (view) {
      fetchDashboardData.requestAct({ period: viewMap[view], year, month });
    }
  }, [view, year, month]);

  const yearOptions = [2022, 2023, 2024];
  const monthOptions = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const handleViewChange = (event) => {
    setView(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ mx: 1 }}>
        <Typography className="pt-8" variant="h1">
          {PAGE_TITLE}
        </Typography>
        <Box sx={{ mb: 15 }}>
          <Box>
            <Box sx={{ my: 2 }}>
              <ButtonGroup variant="outlined" aria-label="Basic button group" defaultValue={'d'} onClick={handleViewChange}>
                <Button value={'d'} variant={view === 'd' ? 'contained' : 'outlined'} sx={{ textTransform: 'capitalize' }}>
                  Daily
                </Button>
                <Button value={'w'} variant={view === 'w' ? 'contained' : 'outlined'} sx={{ textTransform: 'capitalize' }}>
                  Weekly
                </Button>
                <Button value={'m'} variant={view === 'm' ? 'contained' : 'outlined'} sx={{ textTransform: 'capitalize' }}>
                  Monthly
                </Button>
                <Button value={'y'} variant={view === 'y' ? 'contained' : 'outlined'} sx={{ textTransform: 'capitalize' }}>
                  Yearly
                </Button>
              </ButtonGroup>
            </Box>

            <Box sx={{ mb: 5, display: 'flex', gap: 2, width: 800 }}>
              <FormControl sx={{ width: 200 }} size="small">
                {['d', 'w', 'm'].includes(view) && (
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    labelId="select-year"
                    id="select-year"
                    value={year}
                    inputProps={{
                      id: 'select-year-input',
                    }}
                    onChange={handleYearChange}
                  >
                    {yearOptions.map((v, i) => (
                      <MenuItem value={v} key={`${v}-${i}`}>
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>

              <FormControl sx={{ width: 200 }} size="small">
                {['d'].includes(view) && (
                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    labelId="select-month"
                    id="select-month"
                    value={month}
                    inputProps={{
                      id: 'select-month-input',
                    }}
                    onChange={handleMonthChange}
                  >
                    {monthOptions.map((v, i) => (
                      <MenuItem value={i + 1} key={`${v}-${i}`}>
                        {v}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </Box>
          </Box>

          <Box>
            {fetchDashboardData.current.state === STATE_ENUM.LOADING ? (
              <LoadingIcon setCenter={false} />
            ) : (
              <>
                <Box sx={{ mt: 5 }}>
                  <Box sx={{ py: 3, px: 2, boxShadow: 2 }}>
                    <SummaryData data={fetchDashboardData.current.data} />
                  </Box>
                </Box>
                <Box sx={{ mt: 1, paddingTop: 5 }}>
                  <Box sx={{ py: 3, px: 2, boxShadow: 2 }}>
                    <DashBoardCostView data={fetchDashboardData.current.data} view={view} />
                  </Box>
                </Box>
                <Box sx={{ mt: 1, paddingTop: 5 }}>
                  <Box sx={{ py: 3, px: 2, boxShadow: 2 }}>
                    <DashBoardOrderView data={fetchDashboardData.current.data} view={view} />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Dashboard;

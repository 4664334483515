import React, { ChangeEvent, Dispatch, FocusEvent, SetStateAction, useEffect, useState } from 'react';
import { Alert, AutocompleteChangeReason, Backdrop, Box, Button, Divider, Fade, Modal, Stack, TextField, Typography } from '@mui/material';
import { AddonAutocomplete, GroupAutocomplete, MenuItemAutocomplete } from '../autocomplete';
import { useMenuContextV2, useOrdersContextV2, useSnackbarContext } from '@/src/services';
import { Addon, GroupWithOptionAddon, MenuList, OrderCreateBody, RestaurantAddOnsSchema, RestaurantMenuSchema, RestaurantOptionAddonSchema } from '@/src/types';
import { validateTextField } from '@/utils/validation/validateTextField';
import { MAX_PURCHASE_LIMIT } from '@/src/app.constants';

const modalStyle = {
  position: 'fixed',
  margin: 'auto',
  top: '60px',
  width: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

interface ModalCreateOrderProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  openSnackbar: Dispatch<SetStateAction<boolean>>;
  onUpdate: () => void;
  bookingId: string;
  restaurantId: string | undefined;
}

export default function ModalCreateOrder(props: ModalCreateOrderProps) {
  const { setOpenModal, openModal, bookingId, openSnackbar, onUpdate, restaurantId } = props;
  // context consumers
  const { orderCreate } = useOrdersContextV2();
  const { menuListForOrder } = useMenuContextV2();
  const { setResponseStatus } = useSnackbarContext();
  // user input states
  const [staffName, setStaffName] = useState<string>('');
  const [remark, setRemark] = useState<string>('');
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
  const [selectedAddons, setSelectedAddons] = useState<RestaurantAddOnsSchema[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<RestaurantOptionAddonSchema[]>([]);

  // list states
  const [groupsList, setGroupsList] = useState<GroupWithOptionAddon[]>([]);
  const [addonsList, setAddonsList] = useState<Addon[]>([]);
  // price states
  const [menuItemPrice, setMenuItemPrice] = useState<number>(0);
  const [addOnsPrice, setAddOnsPrice] = useState<number>(0);
  const [optionsPrice, setOptionsPrice] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  // other states
  const [staffNameHasError, setStaffNameHasError] = useState<boolean>(false);
  const [staffNameHelperText, setStaffNameHelperText] = useState<string>('');
  const [remarkHasError, setRemarkHasError] = useState<boolean>(false);
  const [remarkHelperText, setRemarkHelperText] = useState<string>('');
  const [isRequireValue, setIsRequireValue] = useState<boolean>(false);

  const clearInputStates = () => {
    setStaffName('');
    setMenuItemPrice(0);
    setAddOnsPrice(0);
    setOptionsPrice(0);
    setSelectedMenuItem('');
    setAddonsList([]);
    setRemark('');
    setSelectedAddons([]);
    setSelectedOptions([]);
    setRemarkHelperText('');
    setRemarkHasError(false);
    setStaffNameHelperText('');
    setStaffNameHasError(false);
    setIsRequireValue(false);
  };

  /**
   * EFFECTS
   */

  // watch for successful Save
  useEffect(() => {
    if (orderCreate.current.state === 'ready') {
      setResponseStatus({
        status: 'success',
        message: orderCreate.current.status?.message ?? '',
      });
      clearInputStates();
      orderCreate.setState({
        state: 'initial',
        data: undefined,
      });
      onUpdate();
      openSnackbar(true);
    } else if (orderCreate.current.state === 'error') {
      setResponseStatus({
        status: 'error',
        message: orderCreate.current.status?.message ?? '',
      });
      orderCreate.setState({
        state: 'initial',
        data: undefined,
      });
    }

    if (groupsList.length <= 0) {
      setIsRequireValue(true);
    }
  }, [orderCreate.current, groupsList]);

  // watch price changes and calculate total
  useEffect(() => {
    const sum = menuItemPrice + addOnsPrice + optionsPrice;
    setTotalPrice(sum);
  }, [menuItemPrice, addOnsPrice, optionsPrice]);

  /**
   * HANDLERS
   */

  const handleCloseModal = () => {
    clearInputStates();
    setStaffName('');
    setOpenModal(false);
  };

  const handleSave = () => {
    // TODO: set/check type for payload

    const combinedAddons: any[] = [];
    const orderFor = `staffName: (For ${staffName.trim()})`;

    selectedAddons.forEach((addon) => {
      combinedAddons.push({
        id: addon.id,
        type: 'addon',
        groupId: '',
      });
    });

    selectedOptions.forEach((optionAddon) => {
      combinedAddons.push({
        id: optionAddon.id,
        type: 'optionAddon',
        groupId: groupsList.find((i) => i.addon.find((j) => j.id === optionAddon.id))?.id,
      });
    });

    const payload = {
      menuId: selectedMenuItem,
      remark,
      custom: orderFor,
      addonId: combinedAddons,
    } as OrderCreateBody;
    if (restaurantId) payload.restaurantId = restaurantId;

    orderCreate.requestAct(bookingId, payload);
    handleCloseModal();
  };

  const handleStaffNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, error, msg } = validateTextField(event.target.value, 100, 'Who is this order for?', true);
    setStaffName(value);
    setStaffNameHasError(error);
    setStaffNameHelperText(msg);
  };

  const handleStaffNameOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    const trimmed = event.target.value.trim();
    setStaffName(trimmed);
  };

  const handleRemarkChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, error, msg } = validateTextField(event.target.value, 200);
    setRemark(value);
    setRemarkHasError(error);
    setRemarkHelperText(msg);
  };

  const handleRemarkOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    const trimmed = event.target.value.trim();
    setRemark(trimmed);
  };

  const handleMenuItemSelect = (event: React.SyntheticEvent, value: RestaurantMenuSchema, reason: string) => {
    // clear prices and selected lists ONLY
    if (!staffName) {
      setStaffNameHasError(true);
      setStaffNameHelperText('Who is this order for? is required.');
    }
    setMenuItemPrice(0);
    setAddOnsPrice(0);
    setOptionsPrice(0);
    setSelectedMenuItem('');
    setSelectedAddons([]);
    setSelectedOptions([]);

    if (reason !== 'clear') {
      const menuListWithAddOns = menuListForOrder.current.data?.menuAddonList;
      // filter data based on selected menuId
      const foundGroups: MenuList = menuListWithAddOns?.find((menuItem) => menuItem.id === value.id);
      const foundAddons: MenuList = menuListWithAddOns?.find((menuItem) => menuItem.id === value.id);

      if (foundGroups.group) {
        setGroupsList(foundGroups.group);
      }
      if (foundAddons.addon) {
        setAddonsList(foundAddons.addon);
      }
      setSelectedMenuItem(value.id);
      setMenuItemPrice(value.price);
    }
  };
  const handleSelectedAddOns = (addons: RestaurantAddOnsSchema[], reason: string) => {
    // create 1D arrays for ids and prices
    const addonPriceArray = addons.map((addon) => addon.price);
    // calculate total price
    const addonPriceTotal = addonPriceArray.reduce((p, a) => p + a, 0);
    setSelectedAddons(addons);
    setAddOnsPrice(addonPriceTotal);
  };

  const handleSelectGroupOptions = (event: any, value: any, reason: AutocompleteChangeReason, details: any, multiple: boolean, remove?: any) => {
    let selected: any;
    let tempSelectedOptions = selectedOptions;

    if (multiple && reason !== 'clear') {
      selected = details.option;
    } else {
      selected = value;
    }
    if (remove) {
      tempSelectedOptions = selectedOptions.filter((option) => {
        return option.id !== remove.id;
      });
    }
    if (reason === 'selectOption') {
      const updatedOptions = tempSelectedOptions;
      updatedOptions.push(selected);
      const updatedPrice = updatedOptions.reduce((a, b) => a + b.price, 0);
      setOptionsPrice(updatedPrice);
      setSelectedOptions(updatedOptions);
    } else if (reason === 'removeOption') {
      const updatedOptions = tempSelectedOptions.filter((option) => {
        return option.id !== selected.id;
      });
      const updatedPrice = updatedOptions.reduce((a, b) => a + b.price, 0);
      setOptionsPrice(updatedPrice);
      setSelectedOptions(updatedOptions);
    } else if (reason === 'clear') {
      setOptionsPrice(0);
      setSelectedOptions([]);
    }
  };

  const handleLabelValueChange = (hasValue: boolean) => {
    setIsRequireValue(hasValue);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <Fade in={openModal} style={{ maxHeight: '80%', overflowY: 'auto' }}>
        <Box sx={modalStyle}>
          <Typography id="transition-modal-title" variant="h5" component="h2">
            Add new order
          </Typography>
          <Divider />
          <Typography id="transition-modal-description" sx={{ mt: 2, mb: 2 }} style={{ color: '#212121' }}>
            Note: Total amount must not exceed {MAX_PURCHASE_LIMIT} Baht
          </Typography>

          {/* Name Input + Menu Item Select */}
          <Stack spacing={4}>
            <TextField
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              size={'small'}
              error={staffNameHasError}
              sx={{ width: '100%' }}
              id="order-name"
              label={staffNameHasError ? '' : 'Who is this order for?'}
              value={staffName}
              onChange={handleStaffNameChange}
              onBlur={handleStaffNameOnBlur}
              helperText={staffNameHelperText}
            />
            <MenuItemAutocomplete callback={handleMenuItemSelect} />
            {/* Display Addons if: Menu Item has been selected  (assumes they exist, may need to update this) */}
            {selectedMenuItem !== '' ? (
              <>
                {addonsList.length > 0 ? (
                  <AddonAutocomplete
                    clearId="clear-addno"
                    sx={{ pl: '21px' }}
                    addonsList={addonsList}
                    selectedAddons={selectedAddons}
                    callback={handleSelectedAddOns}
                  />
                ) : (
                  <></>
                )}
                {groupsList.length > 0 ? (
                  groupsList.map((group, index) => (
                    <GroupAutocomplete
                      clearIconId="clear-option"
                      key={group.id}
                      id={group.id}
                      sx={{ pl: '21px' }}
                      name={group.enName}
                      multiple={!group.groupRadio}
                      options={group.addon}
                      callback={handleSelectGroupOptions}
                      order={index}
                      onLabelValueChange={handleLabelValueChange}
                    />
                  ))
                ) : (
                  <></>
                )}
                <TextField
                  label="Remark"
                  helperText={remarkHelperText}
                  error={remarkHasError}
                  size={'small'}
                  id="remark"
                  value={remark}
                  onChange={handleRemarkChange}
                  onBlur={handleRemarkOnBlur}
                />
                <Typography
                  id="price-text"
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    mr: 2,
                    mt: 4,
                    fontSize: 18,
                    color: totalPrice <= MAX_PURCHASE_LIMIT ? '#212121' : '#F44336',
                  }}
                >
                  Total price: {totalPrice} Baht
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Stack>
          {totalPrice > MAX_PURCHASE_LIMIT ? (
            <Alert severity={'error'} id="error-budget-limit">
              The order exceeds the budget limit.
            </Alert>
          ) : (
            <></>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '30px' }}>
            <Button
              id={'btn-modal-create-order-add'}
              disabled={totalPrice > MAX_PURCHASE_LIMIT || staffNameHasError || remarkHasError || !selectedMenuItem || !isRequireValue}
              color={'primary'}
              variant={'contained'}
              onClick={handleSave}
            >
              ADD
            </Button>
            <Button id={'btn-modal-create-order-cancel'} color={'success'} variant={'outlined'} sx={{ ml: 2 }} onClick={handleCloseModal}>
              CANCEL
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

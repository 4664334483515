import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Tab, Tabs, Rating, Button } from '@mui/material';
import { Link, useLocation, useParams, Outlet, matchPath, useNavigate } from 'react-router-dom';
import { useAddOnContextV2, useGroupContextV2, useMenuContextV2, useOptionAddonContext, useRestaurantContext } from '@/services/index';
import RestaurantBreadcrumbs from '@/src/components/ui/breadcrumbs/restaurantBreadCrumbs';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import StarIcon from '@mui/icons-material/Star';
import { apiPathV1 } from '@/src/app.constants';
import pageNotFound from '@/src/images/ADMIN-ERROR 1.png';
import { LoadingIcon } from '@/src/components';

export function RestaurantDetail(props) {
  const location = useLocation();
  const { groupId, id } = useParams();
  const { restaurantDetail, setRestaurantDetail } = useRestaurantContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (id !== undefined) {
      setRestaurantDetail(apiPathV1.restaurantDetail, id);
    }
  }, [id]);

  const [restaurantNameEn, setRestaurantNameEn] = useState<string | undefined>();
  const [addonGroupNameEn, setAddonGroupNameEn] = useState<string | undefined>();
  const [tab, setTab] = useState<'menu' | 'addons' | 'group'>('menu');

  const { menuList } = useMenuContextV2();
  const { restaurantAddonList } = useAddOnContextV2();
  const { restaurantGroupList } = useGroupContextV2();
  const { restaurantOptionAddonList } = useOptionAddonContext();

  // Get current tab by path name after refresh
  const currentPath = matchPath('/restaurants/:id/:subpath', location.pathname);
  const currentSubPath = currentPath?.params?.subpath as 'menu' | 'addons' | 'group' | undefined;
  useEffect(() => {
    if (!groupId) {
      if (currentSubPath) setTab(currentSubPath);
    }
  }, [groupId, location.pathname]);

  // Initialize restaurant name
  useEffect(() => {
    if (location.state?.restaurantName && location.state?.restaurantName !== restaurantNameEn) {
      setRestaurantNameEn(location.state?.restaurantName);
    } else if (!restaurantNameEn && currentSubPath === 'menu' && menuList.current.state === STATE_ENUM.READY) {
      setRestaurantNameEn(menuList.current.data?.restaurant.enName);
    } else if (!restaurantNameEn && currentSubPath === 'addons' && restaurantAddonList.current.state === STATE_ENUM.READY) {
      setRestaurantNameEn(restaurantAddonList.current.data?.restaurant.enName);
    } else if (!restaurantNameEn && currentSubPath === 'group' && restaurantGroupList.current.state === STATE_ENUM.READY) {
      setRestaurantNameEn(restaurantGroupList.current.data?.restaurant?.enName);
    }
  }, [currentSubPath, location.state, menuList.current, restaurantAddonList, restaurantGroupList]);

  // Initialize option addons group name
  useEffect(() => {
    if (groupId) {
      if (location.state?.optionName && location.state?.optionName !== addonGroupNameEn) {
        setAddonGroupNameEn(location.state?.optionName);
      } else if (!addonGroupNameEn && restaurantOptionAddonList.state === STATE_ENUM.READY && restaurantOptionAddonList.data?.id === groupId) {
        setAddonGroupNameEn(restaurantOptionAddonList.data?.enName);
        setRestaurantNameEn(restaurantOptionAddonList.enName);
      }
    }
  }, [groupId, location.state, restaurantOptionAddonList]);

  const clickToLandingPage = () => {
    navigate('/orders');
  };

  if (restaurantDetail.state === STATE_ENUM.INITIAL || restaurantDetail.state === STATE_ENUM.LOADING) {
    return <LoadingIcon setCenter={false}></LoadingIcon>;
  }

  return (
    <Container maxWidth="xl">
      {restaurantDetail.status?.code !== 200 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Box textAlign="center">
            <img src={pageNotFound} alt="page-not-found" />
            <Typography variant="h1" pb={3}>
              Page not found
            </Typography>
            <Box>
              <Button variant="contained" onClick={clickToLandingPage}>
                GO HOME
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <RestaurantBreadcrumbs restaurantName={restaurantNameEn || ''} optionName={groupId ? addonGroupNameEn : undefined} />
          <Box className="pt-8 flex items-start break-words">
            <Typography className="w-full" variant="h1">
              {restaurantDetail.data?.enName}
            </Typography>
          </Box>
          <Box
            sx={{
              width: 'full',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Rating
              name="rating"
              value={restaurantDetail.data?.ratingAvg || 0}
              precision={0.1}
              readOnly
              emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
            />
            <Typography sx={{ mx: 2 }}>{restaurantDetail.data?.ratingAvg === 0 ? '-' : restaurantDetail.data?.ratingAvg?.toFixed(1) || '-'}</Typography>
            <Typography>
              (
              {restaurantDetail.data?.ratingCount === 0
                ? 'No ratings'
                : restaurantDetail.data?.ratingCount === 1
                ? `${restaurantDetail.data?.ratingCount} rating`
                : `${restaurantDetail.data?.ratingCount} ratings`}
              )
            </Typography>
          </Box>
          {!groupId && (
            <Box sx={{ borderBottom: 1, borderColor: '#E0E0E0', '.normal-case:not(.Mui-selected)': { color: '#212121' } }} className="mb-6">
              <Tabs value={tab} onChange={(_e, tab) => setTab(tab)}>
                <Tab component={Link} value="menu" to="menu" label="Menu items" className="normal-case text-base" id="tab-menu" />
                <Tab component={Link} value="addons" to="addons" label="Add-ons" className="normal-case text-base" id="tab-addons" />
                <Tab component={Link} value="group" to="group" label="Option groups" className="normal-case text-base" id="tab-group" />
              </Tabs>
            </Box>
          )}
          <Outlet {...props} />
        </>
      )}
    </Container>
  );
}

import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Modal, Backdrop, Fade, Box, Typography, Button } from '@mui/material';
import { printDisplayDate } from '@/utils/index';
import { DescriptionForCantDelete } from '../sharedUIComponents';
import { initialMenuState } from '@/services/index';
import { MenuTypes } from '@/store/menu.v2.store';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

interface ModalMenuProps {
  openModal: boolean;
  type: string;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  restaurantId: string;
  restaurantMenuId: string;
  setRestaurantMenuDelete: MenuTypes.RequestAct.MenuDelete;
  restaurantMenuDeleteData: MenuTypes.MenuDelete;
  setRestaurantMenuDeleteData: Dispatch<SetStateAction<MenuTypes.MenuDelete>>;
  setType: Dispatch<SetStateAction<string>>;
}

export default function ModalComponent(props: ModalMenuProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonCancelText, setButtonCancelText] = useState('');
  const [temp, setTemp] = useState('');
  const [color, setColor] = useState('');
  const {
    openModal,
    type,
    setOpenModal,
    restaurantId,
    restaurantMenuId,
    setRestaurantMenuDelete,
    restaurantMenuDeleteData,
    setRestaurantMenuDeleteData,
    setType,
  } = props;
  const [dateArray, setDateArray] = useState<{ date: string; count: number }[]>([]);

  useEffect(() => {
    switch (type) {
      case 'DELETE':
        setTitle('Are you sure you want to delete this menu item?');
        setDescription('This action will permanently delete the menu item and cannot be undone.');
        setButtonText('DELETE');
        setButtonCancelText('CANCEL');
        setTemp('#F44336');
        setColor('#ffffff');
        break;

      case 'CANTDELETE':
        setTitle("The action can't be completed.");
        setButtonText('OK');
        setTemp('#F44336');
        setColor('#ffffff');
        break;
      default:
        break;
    }
  }, [type, temp, color]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleClickDelete = () => {
    setOpenModal(false);
    setRestaurantMenuDelete(restaurantId, restaurantMenuId);
  };

  useEffect(() => {
    if (restaurantMenuDeleteData.state === 'error') {
      setType('CANTDELETE');
      setOpenModal(true);
      setRestaurantMenuDeleteData(initialMenuState.menuDelete.current);

      const displayDateList =
        restaurantMenuDeleteData.error?.data?.map((ordersCount) => {
          return { date: printDisplayDate(ordersCount.date), count: ordersCount.count };
        }) || [];
      setDateArray(displayDateList.sort());
    }
  }, [restaurantMenuDeleteData]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      slots={{
        backdrop: Backdrop,
      }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h5" component="h2">
            {title}
          </Typography>
          {type === 'CANTDELETE' ? (
            <DescriptionForCantDelete modalType="menu" dateArray={dateArray} />
          ) : (
            <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{ color: '#212121' }}>
              {description}
            </Typography>
          )}
          {type === 'DELETE' ? (
            <Button id={'btn-modal-menu-' + buttonText} style={{ backgroundColor: temp, color }} sx={{ mt: 2 }} onClick={handleClickDelete}>
              {buttonText}
            </Button>
          ) : (
            <Button id={'btn-modal-menu-' + buttonText} style={{ backgroundColor: temp, color }} sx={{ mt: 2 }} onClick={handleClose}>
              {buttonText}
            </Button>
          )}
          {type !== 'CANTDELETE' ? (
            <Button
              id={'btn-modal-menu-' + buttonCancelText}
              style={{ backgroundColor: '#ffff', color: temp, border: '1px solid ' + temp }}
              sx={{ mt: 2, ml: 2 }}
              onClick={handleClose}
            >
              {buttonCancelText}
            </Button>
          ) : (
            <></>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import { FormControl, Box, Container, Paper, Table, TableContainer, Typography, TableCell, TableHead, TableRow } from '@mui/material';
import { EnhancedTableBodySchedule, LoadingIcon, BasicMonthPicker, BasicYearPicker, PopupSnackbar } from '@/components/index';
import { useOrdersContextV2, useRestaurantBookingContext, useRestaurantContext } from '@/services/index';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { apiPathV2 } from '@/app.constants/apiCallPaths/api.path.v2';

const PAGE_TITLE = 'Schedule';

export function Schedule(props: any) {
  const { year, month, getRestaurantBookingList, restaurantBookingList } = useRestaurantBookingContext();
  const { restaurantListForSchedule, setRestaurantListForSchedule, restaurantListFallBackMeal, setRestaurantListFallBackMeal } = useRestaurantContext();
  const { changedOrderRestaurants } = useOrdersContextV2();

  const [load, setLoad] = useState<boolean>(true);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const onSetYear = (year: string) => {
    getRestaurantBookingList(apiPathV1.restaurantBookingList, year, month);
  };

  const onSetMonth = (month: string) => {
    getRestaurantBookingList(apiPathV1.restaurantBookingList, year, month);
  };

  useEffect(() => {
    if (restaurantBookingList.state === STATE_ENUM.INITIAL) {
      getRestaurantBookingList(apiPathV1.restaurantBookingList, year, month);
    }
    // getRestaurantBookingList(apiPathV1.restaurantBookingList, year, month);
    if (restaurantListForSchedule.state === STATE_ENUM.INITIAL) {
      setRestaurantListForSchedule(apiPathV1.restaurantList);
    }
    if (restaurantListFallBackMeal.state === STATE_ENUM.INITIAL) {
      setRestaurantListFallBackMeal(apiPathV2.restaurantList);
    }
  }, []);

  useEffect(() => {
    switch (changedOrderRestaurants.current.state) {
      case STATE_ENUM.READY: {
        getRestaurantBookingList(apiPathV1.restaurantBookingList, year, month);
        changedOrderRestaurants.setState({ state: STATE_ENUM.INITIAL });
        break;
      }
      case STATE_ENUM.ERROR:
        console.log(changedOrderRestaurants.current.error);
        break;
    }
  }, [changedOrderRestaurants]);

  const isLoading = useMemo(() => {
    return (
      restaurantBookingList.state === STATE_ENUM.LOADING ||
      restaurantListForSchedule.state === STATE_ENUM.LOADING ||
      restaurantListFallBackMeal.state === STATE_ENUM.LOADING ||
      changedOrderRestaurants.current.state === STATE_ENUM.LOADING
    );
  }, [restaurantBookingList, restaurantListForSchedule, changedOrderRestaurants, restaurantListFallBackMeal]);

  return (
    <Container maxWidth="xl">
      <Box className="pt-8 flex flex-row justify-between items-start">
        <Typography variant="h1">{PAGE_TITLE}</Typography>
      </Box>
      <Box
        py={1}
        component="form"
        sx={{
          '& > :not(style)': { m: '16px', ml: '0px' },
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '25ch',
          }}
        >
          <BasicMonthPicker Month={month} setMonth={onSetMonth} Year={year}></BasicMonthPicker>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          color="secondary"
          sx={{
            width: '25ch',
          }}
        >
          <BasicYearPicker Year={year} setYear={onSetYear}></BasicYearPicker>
        </FormControl>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
        {!isLoading && load ? (
          <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                sx={{
                  minWidth: 750,
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width="260px" id="header-date">
                      Date
                    </TableCell>
                    <TableCell align="left" sx={{ borderLeft: '1px solid #E0E0E0', pl: '60px' }} width="400px" id="header-name">
                      Restaurant name
                    </TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <EnhancedTableBodySchedule Month={month} Year={year} setLoad={setLoad} setOpenPopup={setOpenPopup} />
              </Table>
            </TableContainer>
            <PopupSnackbar open={openPopup} setOpen={setOpenPopup}></PopupSnackbar>
          </Paper>
        ) : (
          <LoadingIcon setCenter={false}></LoadingIcon>
        )}
      </Box>
    </Container>
  );
}

import React from 'react';
import { Box, Container, Typography } from '@mui/material';

import accessDeniedImage from '@/src/images/403-Error-Forbidden.png';
// const PAGE_TITLE = 'Access Denied';

export function AccessDeniedPage(props: any) {
  return (
    <Container maxWidth="xl">
      <Box className="pt-8 flex flex-row justify-between items-start">
        <Typography variant="h1"></Typography>
      </Box>
      <Box className="flex flex-col justify-center items-center" sx={{ textAlign: 'center', paddingTop: 10 }}>
        <img
          src={accessDeniedImage}
          alt="Access Denied"
          style={{
            width: '30vw', // Take up full width of container
            // maxWidth: '1000px', // Max width the image can be
            height: 'auto', // Maintain aspect ratio
            marginBottom: 2,
          }}
        />
        <Box className="pt-8 flex flex-row justify-between items-start">
          <Typography variant="h5">Oops!, You do not have permission to access this page.</Typography>
        </Box>
        <Box className="pt-4 flex flex-row justify-between items-start">
          <Typography variant="h6">Please verify your credentials or contact the system administrator.</Typography>
        </Box>
      </Box>
    </Container>
  );
}

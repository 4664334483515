// TODO:  Should probably use moment or dayjs, not both
import moment from 'moment';
import dayjs from 'dayjs';
import { AnonymProps, CookieName, Deffer, ReactType, RemoteConfig } from '@/types/index';
import Cookies, { CookieSetOptions } from 'universal-cookie';
import { AuthLogout } from '../services';
import { BrowserHistory } from 'history';
import { apiPathV1 } from '../app.constants/apiCallPaths/api.path.v1';
import { AxiosError } from 'axios';
import { DEFAULT_DISPLAY_CONFIG, ENVS, LIMIT_TIME_ORDER } from '../app.constants';
import { fetchDateFormat } from '../services/http/user.v1';
import queryString from 'query-string';

export const paramsQueryString = (prefix: string, params: {}, serialized: string = ''): string => {
  const _serialized = serialized || queryString.stringify(params, { arrayFormat: 'separator', arrayFormatSeparator: ',' });
  return prefix + '?' + _serialized;
};

export const niceUrl = (base: string, prefix: string): string => {
  try {
    return new URL(prefix, base).toString();
  } catch (err: any) {
    console.error('[niceUrl]', err.toString());
  }
  return undefined as any;
};

/**
 * @returns array of dates from today and next 5 days in advance.
 */
export const getDateList = (): string[] => {
  process.env.TZ = 'Asia/Bangkok';
  const today = new Date();

  if (today.getDay() === 0) {
    // if today is Sunday
    today.setDate(today.getDate() + 1);
  } else if (today.getDay() === 6) {
    // if today is Saturday
    today.setDate(today.getDate() + 2);
  } else {
    if (today.getHours() >= 17) {
      // if the time is more than 5pm.
      if (today.getDay() === 5) {
        // if today is Friday
        today.setDate(today.getDate() + 3);
      } else {
        today.setDate(today.getDate() + 1);
      }
    }
  }

  const todayFormat = dayjs(today).format('YYYY-MM-DD');
  const dayList = [todayFormat];
  /** check length = 5 to return 5 day */
  for (let i = 0; i < 5; i++) {
    if (today.getDay() === 5) {
      today.setDate(today.getDate() + 3);
    } else {
      today.setDate(today.getDate() + 1);
    }
    dayList.push(dayjs(today).format('YYYY-MM-DD'));
  }

  return dayList;
};

/**
 * @returns boolean a available day (Selected day) to order
 * # No weekend
 * # After 6 pm (the same as next lunch mobile)
 */
export const checkSelectedDayAvailable = (day: string): boolean => {
  process.env.TZ = 'Asia/Bangkok';

  const selectedDay = new Date(day);

  if (selectedDay.getDay() === 0) {
    // if today is Sunday
    selectedDay.setDate(selectedDay.getDate() + 1);
  } else if (selectedDay.getDay() === 6) {
    // if today is Saturday
    selectedDay.setDate(selectedDay.getDate() + 2);
  } else {
    if (selectedDay.getHours() >= LIMIT_TIME_ORDER) {
      // if the time is more than 6pm.
      if (selectedDay.getDay() === 5) {
        // if today is Friday
        selectedDay.setDate(selectedDay.getDate() + 3);
      } else {
        selectedDay.setDate(selectedDay.getDate() + 1);
      }
    }
  }
  const selectedFormat = dayjs(selectedDay).format('YYYY-MM-DD');

  return selectedFormat === day;
};

/**
 * @description Remove /slashes/ from start and end, except for between
 * @example  /abc/def/ghi/ >> abc/def/ghi
 */
export const noSlash = (str = '') => {
  let _str = str;
  if (_str.startsWith('/')) _str = _str.substring(1, _str.length);
  if (_str.endsWith('/')) _str = _str.substring(0, _str.length - 1);
  return _str;
};

export const defer = (): Deffer => {
  const deferred: Deffer = {} as any;
  const promise = new Promise(function (resolve, reject) {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  deferred.promise = promise;
  return deferred;
};

/** options use for react-cookie and universal-cookie */
export const cookieOptions = (expireDate?: Date): CookieSetOptions => {
  if (!expireDate) {
    expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 1);
  }
  // NOTE {sameSite} may fix incognito issue, source: https://github.com/firebase/firebase-js-sdk/issues/3004
  const opts: CookieSetOptions = { path: '/', expires: expireDate, sameSite: true };
  return opts as CookieSetOptions;
};

export const cookieOptionsDaily = (): CookieSetOptions => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return cookieOptions(date);
};

/** extract cookie value by name */
export const getCookies = (cookieName: CookieName, cookies: any): string => {
  if (!cookies) return '';
  else return cookies[cookieName] as string;
};

/** extract cookie value by name */
export const getCookieByName = (cookieName: CookieName, cookieSetOption?: CookieSetOptions): string => {
  const cookies = new Cookies();
  return cookies.get(cookieName, (cookieSetOption ?? cookieOptions()) as any);
};

/**
 * Set universal cookies not on react set state
 */
export const setCookies = (cookieName: CookieName, value: any, cookieSetOption?: CookieSetOptions): void => {
  const cookies = new Cookies();
  // NOTE cookies are persistent so need to be first removed before they can be replaced!
  try {
    cookies.remove(cookieName, (cookieSetOption ?? cookieOptions()) as any);
  } catch (err) {}

  cookies.set(cookieName, value, cookieSetOption ?? cookieOptions());
};

export const getDisplayConfig = (() => {
  let isFetchingData = false;
  const fetchDataInBackground = async () => {
    try {
      const data = await fetchDateFormat();
      setCookies('displayConfig', JSON.stringify(data), cookieOptionsDaily());
    } catch (err) {
      console.log(err);
    } finally {
      isFetchingData = false;
    }
  };

  const startFetchData = () => {
    isFetchingData = true;
    setTimeout(async () => await fetchDataInBackground(), 0);
  };

  return () => {
    const config = getCookieByName('displayConfig', cookieOptionsDaily());

    if (!config && !isFetchingData) {
      startFetchData();
    }
    return (config as any as RemoteConfig) ?? DEFAULT_DISPLAY_CONFIG;
  };
})();

export const printDisplayDate = (stringDate: string, customFormat?: string): string => {
  const dateData = moment(stringDate);

  // Handle case Last Order field is null or undefined
  if (!stringDate) {
    return '-';
  }
  if (stringDate === '0000-00-00T00:00:00.000Z') {
    return '-';
  }
  // Handle case Invalid date data
  if (!dateData.isValid()) {
    return 'Invalid Date';
  }

  const dateFormat = getDisplayConfig();
  const formatted = dateData.format(customFormat ?? dateFormat?.dateFormat);

  return formatted;
};

/**
 * Remove universal cookies not on react update state
 */
export const removeCookies = (cookieName: CookieName): void => {
  const cookies = new Cookies();
  // NOTE cookies are persistent so need to be first removed before they can be replaced!
  try {
    cookies.remove(cookieName, cookieOptions() as any);
  } catch (err) {}
};

/** assign props when passing children
 * we are deleting props.children from props to avoid circular issue
 */
export const childrenWithProps = (React: ReactType, children: React.ReactChildren, props: AnonymProps) => {
  if (props.children) delete props.children;
  return React.Children.map(children, (child, index) => {
    return React.cloneElement(child as any, {
      // merge with latest props
      ...props,
    });
  });
};

/** not using history on navigation */
export const windowHardRedirectLogout = (href: string): void => {
  window.location.href = new URL('?logout=1', href).toString();
};

export const canCreateOrSupportCookies = (): boolean => {
  const isCookieEnabled = navigator.cookieEnabled;
  const cookies = new Cookies();
  // test cookie
  cookies.remove('cookie-support', { path: '/' });
  cookies.set('cookie-support', '1', { path: '/' });
  // test browser session
  window.sessionStorage.removeItem('session-support');
  window.sessionStorage.setItem('session-support', '1');

  return isCookieEnabled && !!cookies.get('cookie-support') && !!window.sessionStorage.getItem('session-support');
};

/** returns uniq array by give key */
export const uniqByKey = (array: [], keyValue: any): any[] => {
  return [...new Map(array.map((item) => [item[keyValue], item])).values()];
};

/** delay something */
export const delay = (time: number = 0): Promise<boolean> => {
  const isNum = typeof time === 'number' && time >= 0; // must provide number
  if (!isNum) return Promise.resolve(true); // or resolve
  // @ts-ignore
  return new Promise((resolve, reject) => {
    const t = setTimeout(() => {
      clearTimeout(t);
      resolve(true);
    }, time);
  });
};

/**
 * create browser uid using current date time stamp
 * browser uid is set one time at App init

 */
export const setBrowserUid = () => {
  (window as any).uid = new Date().getTime();
};

/**
 * Returns browser uid timestamp
 */
export const getBrowserUid = (): number => {
  return (window as any).uid;
};

/**
 * set/update browser session by uid
 * - sets new {sessionName} to > {uid}:{value}
 */
export const setSessionStorage = (sessionName: string, value: string) => {
  window.sessionStorage.setItem(sessionName, `${getBrowserUid()}:${value}`);
};

/**
 *  sets new {sessionName} to > {uid}:{value}
 */
export const getSessionStorage = (sessionName: string): boolean => {
  const val: string = window.sessionStorage.setItem(sessionName, 'true') as any;
  if (!val) return false;
  return val.indexOf(getBrowserUid().toString()) !== -1;
};

/**
 * Perform clean logout, remove all sessions, call AuthLogout controller
 */
export const fullSessionLogout = async (history: BrowserHistory, fromError = false) => {
  // console.log('[fullSessionLogout]', '1');
  const firebaseIdToken = getCookieByName('firebaseIdToken');
  const token = getCookieByName('jwtToken');

  const clearAndRedirect = () => {
    // NOTE we need logout to handle it in redirect, in case of errors during logout phase

    history.push({
      pathname: fromError ? ENVS.BASE_URL : history.location.pathname, // nextLocation,
      search: '?logout=1',
    });
    removeCookies('firebaseIdToken');
    removeCookies('accessToken');
    removeCookies('jwtToken');

    window.sessionStorage.setItem('mlo_ms_login_pending', 'true');
    window.sessionStorage.removeItem('redirect-number');

    delay(100).then(() => {
      windowHardRedirectLogout(window.location.href);
    });
  };

  if (firebaseIdToken && token) {
    console.log('[fullSessionLogout]', '2');
    await AuthLogout(apiPathV1.authLogout /* , { firebaseIdToken: firebaseIdToken, token: token }*/)
      .then(clearAndRedirect)
      .catch((err: AxiosError) => {
        console.error('[AuthLogout][fullSessionLogout]', 'your session is too old, or connection error!', err.toString());
        clearAndRedirect();
        console.log('[fullSessionLogout]', '3/error');
      });
  } else {
    console.error('[fullSessionLogout][AuthLogout]: firebaseIdToken or token not set!');
    clearAndRedirect();
  }
  return Promise.resolve(true);
};

// helpers for detecting iOS devices (for image export feature)
export const isIphone = () => {
  // eslint-disable-next-line deprecation/deprecation
  return /^iPhone/.test(navigator.userAgent) || /^iPhone/.test(navigator.platform);
};
export const isIpad = () => {
  return (
    // eslint-disable-next-line deprecation/deprecation
    /^iPad/.test(navigator.userAgent) || /^iPad/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS  */
  );
};

export * from './axios';

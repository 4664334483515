import React from 'react';

// import {
//   Container, Header, Main, Footer, Logo, Cards
// } from "components";
// import Pokemon from "components/examples/pokemon";
// import Counter from "components/examples/counter";

// import { ThemeProvider } from '@mui/material/styles';
// import { globalTheme } from '../../styles/globalTheme';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export const Home: React.FC<any> = () => {
  return (
    // <ThemeProvider theme={globalTheme}>
    <Container maxWidth="xl">
      <Typography className="py-6" variant="h1">
        Welcome Page
      </Typography>
      {/* <Logo /> */}
      {/* <Header /> */}
      {/* <Main /> */}
      {/* <Counter></Counter> */}
      {/* <Pokemon></Pokemon> */}
      {/* <Cards /> */}
      {/* <Footer /> */}
    </Container>
    // </ThemeProvider>
  );
};

export default Home;

import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { RestaurantBookingContext } from './initial.store';
import {
  RestaurantBookingCreate,
  RestaurantBookingCreateList,
  RestaurantBookingListGet,
  RestaurantBookingStatusUpdate,
  RestaurantBookingUpdate,
  RestaurantBookingRemove,
  RestaurantBookingStatusChecking,
} from '@/services/http/';
import {
  GetRestaurantBookingList,
  HttpStatus,
  PostRestaurantBooking,
  PostRestaurantBookingList,
  PutRestaurantBooking,
  PutRestaurantBookingStatus,
  RemoveRestaurantBooking,
  RestaurantBookingStatus,
} from '@/types/index';
import {
  createRestaurantBookingSchema,
  updateRestaurantBookingStatusSchema,
  RestaurantBookingSchema,
  updateRestaurantBookingSchema,
  statusRestaurantType,
} from '@/types/schema/index';

export function useRestaurantBookingContext() {
  return useContext(RestaurantBookingContext);
}
const RestaurantBookingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const now = moment();

  const [year, setYear] = useState(now.format('YYYY'));

  const [month, setMonth] = useState(now.format('MMMM'));

  const [restaurantBookingList, setRestaurantBookingList] = useState<GetRestaurantBookingList>({
    data: [],
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantBookingCreateData, setRestaurantBookingCreateData] = useState<PostRestaurantBooking>({
    data: undefined,
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantBookingCreateListData, setRestaurantBookingCreateListData] = useState<PostRestaurantBookingList>({
    data: [],
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantBookingUpdateData, setRestaurantBookingUpdateData] = useState<PutRestaurantBooking>({
    data: undefined,
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantBookingStatusUpdateData, setRestaurantBookingStatusUpdateData] = useState<PutRestaurantBookingStatus>({
    data: undefined,
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantBookingStatusCheckingData, setRestaurantBookingStatusCheckingData] = useState<RestaurantBookingStatus>({
    data: undefined,
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  const [restaurantBookingRemoveData, setRestaurantBookingRemoveData] = useState<RemoveRestaurantBooking>({
    data: undefined,
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });
  // action for restaurantBookingList
  const getRestaurantBookingList = (prefix: string, year: string, month: string) => {
    setRestaurantBookingList({
      data: [],
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    setYear(year);
    setMonth(month);

    const today = moment();
    const m = moment(`${year} ${month}`, 'YYYY MMMM');
    const fromDate = m.startOf('month') < today ? today.format('yyyy-MM-DD') : m.startOf('month').format('yyyy-MM-DD');
    const toDate = m.endOf('month').format('yyyy-MM-DD');

    const params = {
      fromDate,
      toDate,
    };

    if (fromDate <= toDate) {
      RestaurantBookingListGet(prefix, params)
        .then((d) => {
          setRestaurantBookingList({
            data: d.data,
            state: 'ready',
            status: d.status,
            pagination: d.pagination,
          });
        })
        .catch((error) => {
          setRestaurantBookingList({ data: [], error: error?.response, state: 'error', status: error?.response?.data?.status });
        });
    }
  };
  // action for restaurantBooking create
  const setRestaurantBookingCreate = async (prefix: string, payload: createRestaurantBookingSchema, callBackFunc?: () => void) => {
    setRestaurantBookingCreateData({
      data: undefined,
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    try {
      const data = await RestaurantBookingCreate(prefix, payload);
      setRestaurantBookingCreateData({
        data: data.data,
        state: 'ready',
        status: data.status,
      });
      if (callBackFunc) {
        callBackFunc();
      }
    } catch (e) {
      const error: any = e;
      setRestaurantBookingCreateData({ data: undefined, error: error?.response, state: 'error', status: error?.response?.data?.status });
    }
  };
  // action for restaurantBooking create
  const setRestaurantBookingCreateList = async (prefix: string, payload: createRestaurantBookingSchema[]) => {
    setRestaurantBookingCreateListData({
      data: [],
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    try {
      const data = await RestaurantBookingCreateList(prefix, payload);
      setRestaurantBookingCreateListData({
        data: data.data,
        state: 'ready',
        status: data.status,
      });
    } catch (e) {
      const error: any = e;
      setRestaurantBookingCreateListData({ data: [], error: error?.response, state: 'error', status: error?.response?.data?.status });
    }
  };
  // action for change restaurant booking detail after create
  const getRestaurantBookingCreateList = (restaurantBookings: RestaurantBookingSchema[]) => {
    const restaurantBookingCreateList = restaurantBookingList.data;
    restaurantBookingCreateList.push(...restaurantBookings);

    setRestaurantBookingList({
      data: restaurantBookingCreateList.sort((a, b) => {
        return a.date > b.date ? 1 : -1;
      }),
      state: 'ready',
    });
  };
  // action for change restaurant booking detail after create 1 booking
  const getRestaurantBookingCreate = (restaurantBookings: RestaurantBookingSchema) => {
    const restaurantBookingCreateList = restaurantBookingList.data;
    restaurantBookingCreateList.push(restaurantBookings);

    setRestaurantBookingList({
      data: restaurantBookingCreateList.sort((a, b) => {
        return a.date > b.date ? 1 : -1;
      }),
      state: 'ready',
    });
  };
  // action for restaurantBooking update
  const setRestaurantBookingUpdate = (prefix: string, payload: updateRestaurantBookingSchema, bookingId: string, callBackFunc?: () => void) => {
    setRestaurantBookingUpdateData({
      data: undefined,
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantBookingUpdate(prefix, payload, bookingId)
      .then((data) => {
        setRestaurantBookingUpdateData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
        if (callBackFunc) {
          callBackFunc();
        }
      })
      .catch((error) => {
        setRestaurantBookingUpdateData({ data: undefined, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };
  // action for change restaurant booking detail after update
  const getRestaurantBookingUpdateList = (date: string, restaurantId: string, status: statusRestaurantType, thName: string, enName: string) => {
    const startArray = restaurantBookingList;
    const restaurantBookingUpdateList = startArray.data.map((restaurantBooking: RestaurantBookingSchema) => {
      if (restaurantBooking.date === date) {
        return {
          ...restaurantBooking,
          date,
          id: date,
          restaurantId,
          status,
          thName,
          enName,
        } as RestaurantBookingSchema;
      } else {
        return {
          ...restaurantBooking,
        };
      }
    });

    setRestaurantBookingList({
      data: restaurantBookingUpdateList,
      state: 'ready',
    });
  };
  // action for restaurantBookingRemove
  const setRestaurantBookingRemove = (prefix: string, bookingId: string) => {
    setRestaurantBookingRemoveData({
      data: undefined,
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantBookingRemove(prefix, bookingId)
      .then((res) => {
        setRestaurantBookingRemoveData({
          data: undefined,
          state: 'ready',
          status: res.status,
        });

        const restaurantBookingUpdateStateList = restaurantBookingList.data.map((restaurantBooking: RestaurantBookingSchema) => {
          if (restaurantBooking.id === bookingId && restaurantBooking.date === bookingId) {
            return {
              enName: '',
            } as RestaurantBookingSchema;
          } else {
            return {
              ...restaurantBooking,
            };
          }
        });

        setRestaurantBookingList({
          data: restaurantBookingUpdateStateList,
          state: 'ready',
        });
      })
      .catch((error) => {
        setRestaurantBookingRemoveData({ data: error?.response?.data, error: error?.response, state: 'error', status: error?.response?.data?.status });
      });
  };
  // action for restaurantBookingStatus update
  const setRestaurantBookingStatusUpdate = (prefix: string, payload: updateRestaurantBookingStatusSchema, bookingId: string, callBackFunc?: () => void) => {
    setRestaurantBookingStatusUpdateData({
      data: undefined,
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantBookingStatusUpdate(prefix, payload, bookingId)
      .then((data) => {
        setRestaurantBookingStatusUpdateData({
          data: { date: bookingId, status: payload.status },
          state: 'ready',
          status: data.status,
        });
        if (callBackFunc) {
          callBackFunc();
        }
      })
      .catch((error) => {
        setRestaurantBookingStatusUpdateData({ error: error?.response, state: 'error', data: undefined, status: error?.response?.data?.status });
      });
  };
  const setRestaurantBookingStatusChecking = (prefix: string, bookingId: string, callBackFunc?: () => void) => {
    setRestaurantBookingStatusCheckingData({
      data: undefined,
      state: 'loading',
      status: {
        code: 0,
        message: '',
      },
    });
    RestaurantBookingStatusChecking(prefix, bookingId)
      .then((data) => {
        setRestaurantBookingStatusCheckingData({
          data: data.data,
          state: 'ready',
          status: data.status,
        });
        if (callBackFunc) {
          callBackFunc();
        }
      })
      .catch((error) => {
        setRestaurantBookingStatusCheckingData({
          error: error?.response,
          state: 'ready',
          data: { bookingId, status: 'Open', haveFallbackMeal: false },
          status: { code: 200, message: '' } as HttpStatus,
        });
      });
  };
  // action for change restaurant booking detail after update
  const getRestaurantBookingUpdateStatusList = (date: string, status: statusRestaurantType) => {
    const startArray = restaurantBookingList;
    const restaurantBookingUpdateStateList = startArray.data.map((restaurantBooking: RestaurantBookingSchema) => {
      if (restaurantBooking.date === date) {
        return {
          date,
          id: date,
          status,
        } as RestaurantBookingSchema;
      } else {
        return {
          ...restaurantBooking,
        };
      }
    });

    setRestaurantBookingList({
      data: restaurantBookingUpdateStateList,
      state: 'ready',
    });
  };
  const restaurantBookingStore = {
    year,
    month,
    restaurantBookingList,
    getRestaurantBookingList,

    restaurantBookingCreateData,
    setRestaurantBookingCreate,
    setRestaurantBookingCreateData,
    getRestaurantBookingCreate,

    restaurantBookingCreateListData,
    setRestaurantBookingCreateList,
    setRestaurantBookingCreateListData,
    getRestaurantBookingCreateList,

    restaurantBookingUpdateData,
    setRestaurantBookingUpdate,
    setRestaurantBookingUpdateData,

    getRestaurantBookingUpdateList,

    restaurantBookingStatusUpdateData,
    setRestaurantBookingStatusUpdate,
    setRestaurantBookingStatusUpdateData,

    restaurantBookingRemoveData,
    setRestaurantBookingRemove,
    setRestaurantBookingRemoveData,

    getRestaurantBookingUpdateStatusList,

    restaurantBookingStatusCheckingData,
    setRestaurantBookingStatusCheckingData,
    setRestaurantBookingStatusChecking,
  };

  useEffect(() => {
    return () => {
      /*
       NOTE: cancel all subscriptions and asynchronous tasks to cleanup function and fix Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
      */
      setRestaurantBookingUpdateData({ state: 'initial', data: undefined });
      setRestaurantBookingRemoveData({ state: 'initial', data: undefined });
      setRestaurantBookingStatusUpdateData({ state: 'initial', data: undefined });
      setRestaurantBookingList({ state: 'initial', data: [] as RestaurantBookingSchema[] });
      setRestaurantBookingCreateData({ state: 'initial', data: undefined });
      setRestaurantBookingCreateListData({ state: 'initial', data: [] });
      setRestaurantBookingStatusCheckingData({ state: 'initial', data: undefined });
    };
  }, []);
  return <RestaurantBookingContext.Provider value={restaurantBookingStore}>{children}</RestaurantBookingContext.Provider>;
};

export { RestaurantBookingContextProvider };

import { HeadCell } from '@/types/enhancedTable.d';

export const headCellsRestaurants: HeadCell[] = [
  {
    id: 'thName',
    label: 'Thai name',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 2 / 10,
  },
  {
    id: 'enName',
    label: 'English name',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 2 / 10,
  },
  {
    id: 'ratingAvg',
    label: 'Rating',
    numeric: true,
    disablePadding: false,
    sortAble: true,
    width: 1 / 10,
  },
  {
    id: 'favoriteCount',
    label: 'Favorite',
    numeric: true,
    disablePadding: false,
    sortAble: true,
    width: 1 / 10,
  },
  {
    id: 'menuItems',
    label: 'Menu items',
    numeric: true,
    disablePadding: false,
    sortAble: true,
    width: 1 / 10,
  },
  {
    id: 'orderCount',
    label: 'Times ordered',
    numeric: true,
    disablePadding: false,
    sortAble: true,
    width: 1 / 10,
  },
  {
    id: 'orderTotal',
    label: 'Total orders',
    numeric: true,
    disablePadding: false,
    sortAble: true,
    width: 1 / 10,
  },
  {
    id: 'lastOrder',
    label: 'Last order',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 1 / 10,
  },
  {
    id: 'actions',
    label: '',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 1 / 10,
  },
];
export const headCellsUsers: HeadCell[] = [
  {
    id: 'firstName',
    label: 'Name',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 2 / 10,
  },
  {
    id: 'role.name',
    label: 'Role',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 1 / 10,
  },
  {
    id: 'lastedLogedInAt',
    label: 'Last login',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 1 / 10,
  },
  {
    id: 'suspendStatus',
    label: 'Suspended',
    numeric: true,
    disablePadding: false,
    sortAble: true,
    width: 1.5 / 10,
    align: 'center',
  },
  {
    id: 'suspendEndDate',
    label: 'Suspended until',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 2 / 10,
  },
  {
    id: 'lastOrder',
    label: 'Last order',
    numeric: false,
    disablePadding: false,
    sortAble: true,
    width: 1.5 / 10,
  },
  {
    id: 'editRole',
    label: '',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 1 / 10,
  },
  {
    id: 'orderHistory',
    label: 'Order history',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 2 / 10,
  },
  {
    id: 'ActiveUser',
    label: 'Active user',
    numeric: false,
    disablePadding: false,
    sortAble: false,
    width: 2 / 10,
  },
];

export const PURCHASE_LIMIT = `Limit exceeded.`;

import React, { useState, useEffect, useMemo } from 'react';
import { Box, Container, Paper, Table, TableContainer, Typography } from '@mui/material';
import { AnonymProps, HiddenActionType, order, QueryParamsGetList, restaurantOrderByType } from '@/types/index';
import {
  EnhancedTableHead,
  EnhancedTableBody,
  EnhancedTablePaginationV2,
  PopupSnackbar,
  AddRestaurant,
  SearchRestaurant,
  LoadingIcon,
} from '@/components/index';
import { DEFAULT_ROW_PER_PAGE, LIMIT_EN_CHAR, LIMIT_TH_CHAR } from '@/app.constants/index';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { headCellsRestaurants } from '@/static/index'; // config for render dynamic table head
import { useRestaurantContext } from '@/services/index';
import { BrowserHistory } from 'history';
import { useCookies } from 'react-cookie';
import { cookieOptions } from '@/src/utils';
import TableBodyNodata from '../../components/ui/tableBodyNoData';
import noOne from '@/images/noOne.png';
import searchNotFound from '@/images/search-not-found.png';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';

const PAGE_TITLE = 'Restaurants';
interface RedirectProps extends AnonymProps {
  history: BrowserHistory;
}

const Restaurants: React.FC<any> = (props: RedirectProps) => {
  const { restaurantList, setRestaurantList, setRestaurantListData } = useRestaurantContext();
  const [order, setOrder] = useState<order | null>('asc');
  const [orderBy, setOrderBy] = useState<restaurantOrderByType | null>('thName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROW_PER_PAGE);
  const [paginationParams, setPaginationParams] = useState<QueryParamsGetList<restaurantOrderByType>>({ page, itemPerPage: rowsPerPage });
  const [open, setOpen] = useState(false);
  const [isInitialGetData, setIsInitialGetData] = useState(true);
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [isDirectUpdateData, setIsDirectUpdateData] = useState(false);
  const [cookies, setCookie] = useCookies(['sortingRestaurants']);
  const [hiddenActionInSearchMode, setHiddenActionInSearchMode] = useState<HiddenActionType>();
  const searchText = paginationParams.search;
  const languageSearch = useMemo(() => {
    if (!searchText) return null;

    const isSearchThai = searchText.match(LIMIT_TH_CHAR);
    const isSearchEng = searchText.match(LIMIT_EN_CHAR);

    if (isSearchThai && isSearchEng) return 'EnTh';
    else if (!isSearchThai && !isSearchEng) return null;
    else return isSearchThai ? 'Th' : 'En';
  }, [searchText]);

  useEffect(() => {
    return () => {
      setRestaurantListData({ data: [], state: STATE_ENUM.INITIAL });
    };
  }, []);

  // Call API for get data from Firestore
  useEffect(() => {
    switch (restaurantList.state) {
      case STATE_ENUM.INITIAL:
        {
          const newParamsGetList = {
            page,
            itemPerPage: rowsPerPage,
            orderBy: orderBy || undefined,
            sort: order || undefined,
            search: paginationParams.search || undefined,
          } as QueryParamsGetList<restaurantOrderByType>;

          if (cookies?.sortingRestaurants !== undefined) {
            newParamsGetList.orderBy = cookies.sortingRestaurants.orderBy;
            newParamsGetList.sort = cookies.sortingRestaurants.sort;
          }
          setOrderBy(newParamsGetList.orderBy || null);
          setOrder(newParamsGetList.sort || null);
          setIsInitialGetData(false);
          setRestaurantList(apiPathV1.restaurantList, newParamsGetList);
        }
        break;
      case STATE_ENUM.READY:
        setTotal(restaurantList.pagination?.total || 0);
        break;
    }
  }, [restaurantList]);

  useEffect(() => {
    if (restaurantList.state !== STATE_ENUM.INITIAL && !isInitialGetData) {
      const newParamsGetList = { ...paginationParams } as QueryParamsGetList<restaurantOrderByType>;
      let setNewCookie = true;
      if (paginationParams?.orderBy === undefined) {
        setNewCookie = false;
        if (!paginationParams.search && cookies?.sortingRestaurants?.orderBy !== undefined) {
          newParamsGetList.orderBy = cookies.sortingRestaurants?.orderBy;
        }
      }
      if (paginationParams?.sort === undefined) {
        setNewCookie = false;
        if (!paginationParams.search && cookies.sortingRestaurants?.sort !== undefined) {
          newParamsGetList.sort = cookies.sortingRestaurants?.sort;
        }
      }
      setOrderBy(newParamsGetList.orderBy || null);
      setOrder(newParamsGetList.sort || null);
      setPage(newParamsGetList.page);
      if (setNewCookie) {
        setCookie('sortingRestaurants' as any, JSON.stringify({ orderBy: newParamsGetList.orderBy, sort: newParamsGetList.sort }), cookieOptions());
      }
      setIsDirectUpdateData(false);
      setRestaurantList(apiPathV1.restaurantList, newParamsGetList);
    }
  }, [paginationParams]);

  return (
    <Container maxWidth="xl">
      <Typography className="pt-8" variant="h1">
        {PAGE_TITLE}
      </Typography>
      <Box className="flex flex-row justify-between items-center">
        {/* ADDING RESTAURANT */}
        <AddRestaurant
          setOpen={setOpen}
          setOrderBy={setOrderBy}
          setOrder={setOrder}
          setPage={setPage}
          setTotal={setTotal}
          setIsChange={setIsDirectUpdateData}
          total={total}
          search={searchText}
          languageSearch={languageSearch}
          setHiddenActionInSearchMode={setHiddenActionInSearchMode}
        />

        {/* SEARCH RESTAURANT */}
        <SearchRestaurant setPaginationParams={setPaginationParams} />
      </Box>

      {/* TABLE */}
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
        {restaurantList.state === STATE_ENUM.READY ? (
          <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                sx={{
                  minWidth: 750,
                }}
              >
                <EnhancedTableHead
                  disabledSorting={restaurantList.data.length === 0}
                  headCells={headCellsRestaurants}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  setPaginationParams={setPaginationParams}
                  respectSortAble
                />
                {restaurantList.data.length === 0 ? (
                  searchText ? (
                    <TableBodyNodata
                      page="restaurant"
                      src={searchNotFound}
                      customWidth={200}
                      customHeight={200}
                      title="No results found."
                      description="Try different keywords?"
                    />
                  ) : (
                    <TableBodyNodata page="restaurant" src={noOne} title="No results found." />
                  )
                ) : (
                  <EnhancedTableBody
                    paginationParams={paginationParams}
                    order={order}
                    orderBy={orderBy}
                    setOpen={setOpen}
                    setOrderBy={setOrderBy}
                    setOrder={setOrder}
                    setPage={setPage}
                    setTotal={setTotal}
                    setIsChange={setIsDirectUpdateData}
                    total={total}
                    languageSearch={languageSearch}
                    hiddenActionInSearchMode={hiddenActionInSearchMode}
                    setHiddenActionInSearchMode={setHiddenActionInSearchMode}
                  />
                )}
              </Table>
              <EnhancedTablePaginationV2
                disabledPagination={restaurantList.data.length === 0}
                total={total}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setPaginationParams={setPaginationParams}
                addRowsPerPageOption={[
                  { value: 60, label: '60' },
                  { value: -1, label: 'All' },
                ]}
                countCurrentRows={restaurantList.data.length}
              />
            </TableContainer>
            <PopupSnackbar open={open} setOpen={setOpen}></PopupSnackbar>
          </Paper>
        ) : (
          <LoadingIcon setCenter={false}></LoadingIcon>
        )}
      </Box>
    </Container>
  );
};

export default Restaurants;

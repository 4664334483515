/** declare only http service related requests */
/**
 * correct way of parsing data https://masteringjs.io/tutorials/express/body
 */
import { AxiosService } from '@/utils/axios';
import { getCookieByName } from '@/utils/index';
import { AxiosResponse } from 'axios';
import { AccessMenuList, GetAuthCheck, PostAuthLogin } from '@/types/crud';
import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';

/**
 * @description return status confirmation, or rejections
 * Auth check will fail if you already logout
 * @param prefix only partial url, base url already prefixed
 * // @param tokenData we need to use more recent {tokenData.firebaseIdToken} since it frequently updates on client side (around every 2 hours) so decoded token data for firebaseIdToken will no longer work, just so you know!
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 *
 */
const AuthCheck = async (prefix: string, hardRejection = true): Promise<GetAuthCheck> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 20000 }, true, hardRejection);
  const firebaseIdToken = getCookieByName('firebaseIdToken');
  if (!firebaseIdToken) return Promise.reject(new Error('[AuthCheck] firebaseIdToken is not set, browser error'));
  const config = {
    ...instance.config,
  };
  config.headers['x-firebaseidtoken'] = firebaseIdToken;

  return instance.client
    .get(prefix, config)
    .then((d: AxiosResponse<GetAuthCheck>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetAuthCheck>;
};

/**
 * @description return login confirmation, or rejections
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 *
 */
const AuthLogin = (prefix: string, hardRejection = true): Promise<PostAuthLogin> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);
  const config = instance.config;
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

  const firebaseIdToken = getCookieByName('firebaseIdToken');
  const accessToken = getCookieByName('accessToken');
  if (!accessToken) return Promise.reject(new Error('[AuthLogin] accessToken is not set, browser error'));
  if (!firebaseIdToken) return Promise.reject(new Error('[AuthLogin] firebaseIdToken is not set, browser error'));

  return instance.client
    .post(prefix, `firebaseIdToken=${firebaseIdToken}&accessToken=${accessToken}`, config)
    .then((d: AxiosResponse<PostAuthLogin>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostAuthLogin>;
};

/**
 * @description return logout status confirmation, or rejections, this will log you out of firebase also! Also return expired token in case you want to match it or update to expired token instead!
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 *
 */
const AuthLogout = (prefix: string, hardRejection = true): Promise<PostAuthLogin> => {
  const firebaseIdToken = getCookieByName('firebaseIdToken');
  const jwtToken = getCookieByName('jwtToken');
  if (!firebaseIdToken) return Promise.reject(new Error('[AuthLogout] firebaseIdToken is not set, browser error'));
  if (!jwtToken) return Promise.reject(new Error('[AuthLogout] jwtToken is not set, browser error'));
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = instance.config;
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';

  return instance.client
    .post(prefix, `firebaseIdToken=${firebaseIdToken}`, config)
    .then((d: AxiosResponse<PostAuthLogin>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostAuthLogin>;
};

const AuthVerifyAccess = (prefix: string, menuId: string, hardRejection = true): Promise<GetAuthCheck> => {
  const firebaseIdToken = getCookieByName('firebaseIdToken');
  const jwtToken = getCookieByName('jwtToken');
  if (!firebaseIdToken) return Promise.reject(new Error('[AuthVerifyAccess] firebaseIdToken is not set, browser error'));
  if (!jwtToken) return Promise.reject(new Error('[AuthVerifyAccess] jwtToken is not set, browser error'));
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = { ...instance.config };
  const body = {
    menuId,
  };
  return instance.client
    .post(prefix, JSON.stringify(body), config)
    .then((d: AxiosResponse<GetAuthCheck>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<GetAuthCheck>;
};
const AuthPermissions = (prefix: string, hardRejection = true): Promise<AccessMenuList> => {
  const firebaseIdToken = getCookieByName('firebaseIdToken');
  const jwtToken = getCookieByName('jwtToken');
  if (!firebaseIdToken) return Promise.reject(new Error('[AuthPermissions] firebaseIdToken is not set, browser error'));
  if (!jwtToken) return Promise.reject(new Error('[AuthPermissions] jwtToken is not set, browser error'));
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = { ...instance.config };
  return instance.client
    .get(prefix, config)
    .then((d: AxiosResponse<AccessMenuList>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<AccessMenuList>;
};
// /auth/permissions
export { AuthLogin, AuthCheck, AuthLogout, AuthVerifyAccess, AuthPermissions };

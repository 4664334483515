import { Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  getSignInWithMicrosoftResult,
  firebaseAuth,
  signInRedirectWithMicrosoft,
  signOutWithMicrosoft,
  useAppContext,
  signInPopupWithMicrosoft,
} from '@/services/index';
import { AuthLogin } from '@/services/http';
import { canCreateOrSupportCookies, cookieOptions, getCookies, removeCookies } from '@/utils/index';
import { useNavigate, useLocation } from 'react-router-dom';
import { AnonymProps } from '../../types/common.d';
import { BrowserHistory } from 'history';
import { PostAuthLogin } from '@/types/index';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { AxiosError } from 'axios';

interface RedirectProps extends AnonymProps {
  history: BrowserHistory;
}

const Redirect: React.FC<RedirectProps> = (props): any => {
  const search = useLocation().search;
  const logoutParam = parseInt(new URLSearchParams(search).get('logout') || '');
  const loginParam = parseInt(new URLSearchParams(search).get('login') || '');
  const { history } = props;
  const navigate = useNavigate();
  const [user, loading, authError] = useAuthState(firebaseAuth);
  const [apiReady, setApiError] = useState<'initial' | 'ready' | 'error'>('initial');
  const [apiStatusCode, setApiStatusCode] = useState<number>(500);
  const [onLoginSuccess, setLoginSuccess] = useState<boolean | null>(null);
  const [mount, setMount] = useState(0);
  const [firebaseStage, setFirebaseStage] = useState<number>(0);

  const { currentUser } = useAppContext();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['accessToken', 'jwtToken']);
  const isCookieEnabled = canCreateOrSupportCookies();

  const lastLocation =
    history.location.pathname.indexOf('/redirect') === -1 ? (history.location.pathname.includes('/error') ? '/' : history.location.pathname) : '/';
  const waitForTimeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const login = async () => {
    const firebaseToken: string = (await currentUser.firebaseToken.promise) as any;
    if (!firebaseToken) return 400;

    const tryLogin = async (runCount: number) => {
      try {
        console.log(`[login][accessToken][runCount: ${runCount}] `);
        const resp = await AuthLogin(apiPathV1.authLogin);

        removeCookies('jwtToken');
        setCookie('jwtToken' as any, resp.data.token, cookieOptions());

        return resp;
      } catch (err) {
        console.error(`[error][login][accessToken][runCount: ${runCount}]`);
        console.error(`[error][login][accessToken][runCount: 1] `);

        return Promise.reject(err);
      }
    };

    try {
      // we have accessToken issue due to MicrosoftProvider from firebase can generate multiples, once from {getRedirectResult} and the other from User.accessToken, we will test for login twice if fails the first time, and finally reject when fails the last time.

      let loginData: PostAuthLogin;
      let runCount: number = 1;
      do {
        loginData = await tryLogin(runCount);
        if (loginData.status.code !== 200 && loginData.status.code !== 403 && runCount < 3) {
          runCount++;
          await waitForTimeout(1000);
        }
      } while (loginData.status.code !== 200 && loginData.status.code !== 403 && runCount < 3);

      if (loginData.status.code === 200) {
        console.log('[stage][three]', 'auth/login');
      }

      return loginData.status.code;
    } catch (err) {
      const error = err as AxiosError;
      removeCookies('jwtToken');
      removeCookies('accessToken');
      window.sessionStorage.setItem('mlo_ms_login_pending', 'false');
      console.error('remove cookies?', err);
      return error?.response?.status || 400;
    }
  };

  useEffect(() => {
    // detect incognito mode
    // if (firebaseStage === 1 && !logoutParam) {
    //   console.log('[firebaseStage][1][a]', 'loading');
    //   return;
    // }

    if (!isCookieEnabled) {
      console.error('No cookie support in your browser!');
      return;
    }

    if (authError || apiReady === 'error') {
      // NOTE FIREBASE/AUTH ERROR
      navigate(`/error?code=${apiStatusCode === 403 ? apiStatusCode : 501}`);
      return;
    }
    // if (logout) return;
    if (loading) return;
    /**
     * !important
     * NOTE: if user fail to login in 1 time (like in incognito mode), then we will try re-signing again.
     * `signInCount` will use to re-signing count check.
     * 1st and 2nd re-signing will signIn by Redirect method.
     * 3rd and 4th re-signing will signIn by Popup method.
     * after 4th attempt re-signing failed will navigate to Error Page.
     */
    const signInCount = Number(window.sessionStorage.getItem('redirect-number')) || 0;
    if ((!loading && !user) /* && !window.sessionStorage.getItem('mlo_ms_login_pending')*/ || Number(loginParam) === 0) {
      history.push({ pathname: lastLocation, search: `?login=${signInCount}` });
      window.sessionStorage.setItem('mlo_ms_login_pending', 'true');

      // NOTE: if 4th attempt of re-signing failed, then navigate to the error page.
      if (signInCount > 4 || loginParam > 4) {
        console.error('Try too many redirects already');
        window.sessionStorage.removeItem('redirect-number');
        navigate('/error?code=425');
        return;
      }

      // NOTE: try re-signing with redirect 2 times, if 2nd attempt failed then try re-signing with the popup.
      if ((signInCount > 2 || loginParam > 2) && onLoginSuccess === null) {
        signInPopupWithMicrosoft(setCookie).then(setLoginSuccess).catch(setLoginSuccess);
      } else {
        signInRedirectWithMicrosoft();
      }

      window.sessionStorage.setItem('redirect-number', `${signInCount + 1}`); // NOTE: accumulate signInCount
      return;
    }

    // NOTE: the condition check to get response if the signInRedirectWithMicrosoft being call in 1st and 2nd attempt re-signing
    const canReceiveRedirectResults = onLoginSuccess === null && !loading && user && window.sessionStorage.getItem('mlo_ms_login_pending') === 'true';
    if (canReceiveRedirectResults && (signInCount <= 2 || loginParam <= 2)) {
      getSignInWithMicrosoftResult(setCookie).then(setLoginSuccess).catch(setLoginSuccess);
    }
  }, [
    user,
    loading,
    authError,
    apiReady,
    setApiError,
    apiReady,
    logoutParam,
    signInRedirectWithMicrosoft,
    getSignInWithMicrosoftResult,
    setFirebaseStage,
    firebaseStage,
  ]);

  useEffect(() => {
    // f (mount === 1) setMount(mount + 1);
    const azureAccessToken = currentUser.azureAccessToken() || getCookies('accessToken', cookies);
    const hasUserNoJwtTokenAndAccessToken = !loading && currentUser?.user && !getCookies('jwtToken', cookies) && azureAccessToken && apiReady === 'initial';

    if (!isCookieEnabled) return;

    if (!azureAccessToken && !loading) {
      window.indexedDB.deleteDatabase('firebaseLocalStorageDb');
      // console.log('[login][logout][loading]', azureAccessToken);
      return;
    }

    if (logoutParam && !getCookies('jwtToken', cookies) && !getCookies('accessToken', cookies)) {
      signOutWithMicrosoft();
      console.error('[logout][no jwtToken][no accessToken] ', 'doing signInRedirectWithMicrosoft');
      return;
    }

    if (mount || logoutParam) {
      // console.log('[login][loading]');
      return;
    }

    if (hasUserNoJwtTokenAndAccessToken) {
      login().then((status) => {
        setApiStatusCode(status);
        console.log(`status : ${status}`);
        /* if (status === 403) {
          setApiError('error');
          navigate(`/error?code=${status}`);
        } else*/
        if (status === 200) {
          console.log('[login][success] ', ' navigate');
          setApiError('ready');
          navigate(lastLocation);
        } else {
          console.error('[Azure][login][no access] ', 'try log in again, or check internet access ?');
          setApiError('error');
          navigate(`/error?code=${status}`);
        }
      });
      setMount(1);
    } else if (currentUser?.user && !hasUserNoJwtTokenAndAccessToken) navigate(lastLocation);
    else {
      console.log('[checking]... ');
    }
  }, [currentUser, mount, setMount, login, logoutParam, setFirebaseStage, firebaseStage]);

  return (
    <div className="App">
      <Container className="flex min-h-screen">
        <div className="text-center py-4 m-auto">
          <Typography variant="h2" className="text-black font-bold tracking-wider mb-5">
            Redirect...
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export { Redirect };

import React from 'react';
import { MenuContextV2Provider } from '../menu.v2.store';
import { OrdersContextV2Provider } from '../order.v2.store';
import { RestaurantContextProvider } from '../restaurant.store';
import { RestaurantBookingContextProvider } from '../restaurantBooking.store';
import { SnackBarContextProvider } from '../snackbar.store';
import { OptionAddonContextProvider } from '../optionAddon.store';
import { AddOnContextV2Provider } from '../addon.v2.store';
import { RestaurantOptionAddonContextV2Provider } from '../optionAddon.v2.store';
import { GroupContextV2Provider } from '../group.v2.store';
export const CombineContextProviders = (props) => {
  // TODO: remove order context v1.
  return (
    <RestaurantContextProvider>
      <RestaurantBookingContextProvider>
        <OrdersContextV2Provider>
          <SnackBarContextProvider>{props.children}</SnackBarContextProvider>
        </OrdersContextV2Provider>
      </RestaurantBookingContextProvider>
    </RestaurantContextProvider>
  );
};

export const MenuAddOnContextProviders = (props) => {
  return (
    <AddOnContextV2Provider>
      <MenuContextV2Provider>{props.children}</MenuContextV2Provider>
    </AddOnContextV2Provider>
  );
};

export const MenuAddOnGroupContextProviders = (props) => {
  return (
    <GroupContextV2Provider>
      <MenuAddOnContextProviders>
        <RestaurantOptionAddonContextV2Provider>
          <OptionAddonContextProvider>{props.children}</OptionAddonContextProvider>
        </RestaurantOptionAddonContextV2Provider>
      </MenuAddOnContextProviders>
    </GroupContextV2Provider>
  );
};

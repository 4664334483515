import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import { formatValue } from 'react-currency-input-field';

export function EnhancedTableTotalPriceOrderV2(props: any) {
  const { totalPrice } = props;

  // function subtotal(items: readonly StaffOrderSchema[]) {
  //   return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
  // }
  // const invoiceSubtotal = subtotal(rows);

  return (
    <TableBody id="tbody-total-price-order" className="export-omit" sx={{ backgroundColor: '#F1F8E9' }}>
      <TableRow id="tr-total-price-order">
        <TableCell id="td-total-price-order-col1" colSpan={6}>
          <Box className="flex flex-row justify-end items-baseline">
            <Typography id="span-total-price-order-word" sx={{ marginRight: '26px' }}>
              ราคารวมทั้งหมด
            </Typography>
            <Typography variant="h3" id="span-total-price">
              {formatValue({ value: `${totalPrice || '0'}` })}
            </Typography>
          </Box>
        </TableCell>
        {/* <TableCell id="td-total-price-order-col2" colSpan={1}>
          <Box sx={{ backgroundColor: '#F1F8E9' }}></Box>
        </TableCell> */}
      </TableRow>
    </TableBody>
  );
}

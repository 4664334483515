import { AxiosService, paramsQueryString } from '@/src/utils';
import { apiMethodV1 } from '@/src/app.constants/apiCallMethods';
import { FallbackMeal } from '@/src/types/crud/fallbackMeal/fallbackMeal';
import { AxiosResponse } from 'axios';
import { createFallbackMealSchema } from '@/src/types/schema/createFallbackMeal';
import { orderOrderByType, PostDefaultMenu, QueryParamsGetList } from '@/src/types';

export interface ChangedOrderRestaurant {
  restaurantId: string;
}

const fallbackMealCreate = (prefix: string, body: createFallbackMealSchema, hardRejection = true): Promise<PostDefaultMenu> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);
  const config = {
    ...instance.config,
  };

  return instance.client
    .post(prefix, JSON.stringify(body), config)
    .then((res: AxiosResponse<PostDefaultMenu>) => {
      return res.data;
    })
    .catch(instance.defaultHandleError) as Promise<PostDefaultMenu>;
};

const fallbackMealUpdate = <T>(prefix: string, body: FallbackMeal, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);
  const config = {
    ...instance.config,
  };

  return instance.client
    .put(prefix, JSON.stringify(body), config)
    .then((res: AxiosResponse<T>) => {
      return res.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const fallbackMealGetOrders = <T>(
  prefix: string,
  bookingId: string,
  restaurantId: string,
  params: QueryParamsGetList<orderOrderByType>,
  hardRejection = true
): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  const config = {
    ...instance.config,
  };
  const queryUri = paramsQueryString(`${prefix}/${bookingId}/${restaurantId}`, params);
  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const fallbackMealChangedOrderRestaurants = <T>(prefix: string, bookingId: string, body: ChangedOrderRestaurant, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);
  const config = {
    ...instance.config,
  };

  const queryUri = `${prefix}/${bookingId}`;

  return instance.client
    .post(queryUri, JSON.stringify(body), config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

export { fallbackMealCreate, fallbackMealUpdate, fallbackMealGetOrders, fallbackMealChangedOrderRestaurants };

import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { DEFAULT_ROW_PER_PAGE, apiPathV1, apiPathV2 } from '../../../app.constants';
import { ActionUnit2, commonSetError, initialApiContext2, initialStateContext } from '../common';
import { OrdersSummaryListGet, OrderDelete, OrderCreate, OrderDeleteAll, OrderCountGet, ScheduleOrderListGet } from '../../http';
import { QueryParamsGetList, orderOrderByType, OrderCreateBody, CommonCrud, PaginatedCrud, GetFallbackMealOrderData } from '../../../types';
import { OrdersListResponse } from '@/src/types/crud/order/order.response';
import { ChangedOrderRestaurant, fallbackMealChangedOrderRestaurants, fallbackMealGetOrders } from '../../http/fallbackMeal.v1';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { OptOutEnum } from '@/src/types/schema/enum/optOut.Enum';

declare namespace Types {
  export type SelectedOrderDate = Date | null;
  export type OrdersSummaryList = PaginatedCrud<OrdersListResponse>;
  export type OrderCreate = CommonCrud<{ id: string; createdAt: string /** string date */ } | undefined>;
  export type OrderDelete = CommonCrud<{ deleted: string } | undefined>;
  export type OrdersDeleteAll = CommonCrud<{ deleted: string } | undefined>;
  export type OrdersCount = CommonCrud<{ amount: number }>;
  export type FallbackMealGetOrder = PaginatedCrud<GetFallbackMealOrderData | undefined>;
  export type FallbackMealChangeOrderRestaurants = CommonCrud<{ bookingId: string; restaurantId: string }>;
  export type ScheduleOrderList = PaginatedCrud<OrdersListResponse>;

  export namespace RequestAct {
    export type OrdersSummaryList = (bookingId: string, params?: QueryParamsGetList<orderOrderByType>) => void;
    export type OrderCreate = (bookingId: string, body: OrderCreateBody) => void;
    export type OrderDelete = (bookingId: string, orderId: string) => void;
    export type OrdersDeleteAll = (bookingId: string) => void;
    export type OrdersCount = (bookingId: string, callBackFunc?: (amount: number) => void) => void;
    export type FallbackMealGetOrder = (bookingId: string, restaurantId: string, params?: QueryParamsGetList<orderOrderByType>) => void;
    export type FallbackMealChangeOrderRestaurants = (bookingId: string, restaurantId: string) => void;
    export type ScheduleOrderList = (bookingId: string, params?: QueryParamsGetList<orderOrderByType>) => void;
  }
}

interface IOrderContextV2 {
  selectedOrderDate: ActionUnit2<Types.SelectedOrderDate>;
  ordersSummaryList: ActionUnit2<Types.OrdersSummaryList> & { requestAct: Types.RequestAct.OrdersSummaryList };
  ordersSummaryFallBackList: ActionUnit2<Types.OrdersSummaryList> & { requestAct: Types.RequestAct.OrdersSummaryList };
  orderCreate: ActionUnit2<Types.OrderCreate> & { requestAct: Types.RequestAct.OrderCreate };
  orderDelete: ActionUnit2<Types.OrderDelete> & { requestAct: Types.RequestAct.OrderDelete };
  ordersDeleteAll: ActionUnit2<Types.OrdersDeleteAll> & { requestAct: Types.RequestAct.OrdersDeleteAll };
  ordersCount: ActionUnit2<Types.OrdersCount> & { requestAct: Types.RequestAct.OrdersCount };
  orders: ActionUnit2<Types.FallbackMealGetOrder> & { requestAct: Types.RequestAct.FallbackMealGetOrder };
  changedOrderRestaurants: ActionUnit2<Types.FallbackMealChangeOrderRestaurants> & { requestAct: Types.RequestAct.FallbackMealChangeOrderRestaurants };
  scheduleOrderList: ActionUnit2<Types.ScheduleOrderList> & { requestAct: Types.RequestAct.ScheduleOrderList };
}

const initialState: IOrderContextV2 = {
  selectedOrderDate: { ...initialStateContext<Types.SelectedOrderDate>() },
  ordersSummaryList: { ...initialApiContext2<Types.OrdersSummaryList, Types.RequestAct.OrdersSummaryList>({ bookingStatus: '' }) },
  ordersSummaryFallBackList: { ...initialApiContext2<Types.OrdersSummaryList, Types.RequestAct.OrdersSummaryList>({ bookingStatus: '' }) },
  orderCreate: { ...initialApiContext2<Types.OrderCreate, Types.RequestAct.OrderCreate>() },
  orderDelete: { ...initialApiContext2<Types.OrderDelete, Types.RequestAct.OrderDelete>() },
  ordersDeleteAll: { ...initialApiContext2<Types.OrdersDeleteAll, Types.RequestAct.OrdersDeleteAll>() },
  ordersCount: { ...initialApiContext2<Types.OrdersCount, Types.RequestAct.OrdersCount>() },
  orders: { ...initialApiContext2<Types.FallbackMealGetOrder, Types.RequestAct.FallbackMealGetOrder>() },
  changedOrderRestaurants: { ...initialApiContext2<Types.FallbackMealChangeOrderRestaurants, Types.RequestAct.FallbackMealChangeOrderRestaurants>() },
  scheduleOrderList: { ...initialApiContext2<Types.ScheduleOrderList, Types.RequestAct.ScheduleOrderList>({ bookingStatus: '' }) },
};

const OrderContextV2: React.Context<IOrderContextV2> = createContext(initialState);

const OrdersContextV2Provider = ({ children }: { children: React.ReactNode }) => {
  const now = moment().toDate();

  const [selectedOrderDate, setSelectedOrderDate] = useState<Types.SelectedOrderDate>(now);
  const [ordersSummaryList, setOrdersSummaryList] = useState<Types.OrdersSummaryList>(initialState.ordersSummaryList.current);
  const [ordersSummaryFallBackList, setOrdersSummaryFallBackList] = useState<Types.OrdersSummaryList>(initialState.ordersSummaryFallBackList.current);
  const [orderCreateCRUD, setOrderCreate] = useState<Types.OrderCreate>(initialState.orderCreate.current);
  const [orderDeleteCRUD, setOrderDelete] = useState<Types.OrderDelete>(initialState.orderDelete.current);
  const [ordersDeleteAllCRUD, setOrderDeleteAll] = useState<Types.OrdersDeleteAll>(initialState.ordersDeleteAll.current);
  const [orderCount, setOrderCount] = useState<Types.OrdersCount>(initialState.ordersCount.current);
  const [orders, setOrders] = useState<Types.FallbackMealGetOrder>(initialState.orders.current);
  const [changedRestaurants, setChangedRestaurants] = useState<Types.FallbackMealChangeOrderRestaurants>(initialState.changedOrderRestaurants.current);
  const [scheduleOrderList, setScheduleOrderList] = useState<Types.ScheduleOrderList>(initialState.scheduleOrderList.current);

  const getOrdersSummaryList: Types.RequestAct.OrdersSummaryList = (bookingId, params = { page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE }) => {
    setOrdersSummaryList({ ...initialState.ordersSummaryList.current, state: 'loading' });
    OrdersSummaryListGet<Types.OrdersSummaryList>(apiPathV2.ordersList, bookingId, params)
      .then(({ status, data, pagination }) => setOrdersSummaryList({ state: 'ready', status, data, pagination }))
      .catch((error) => commonSetError(error, setOrdersSummaryList));
  };

  const getOrdersSummaryFallBackList: Types.RequestAct.OrdersSummaryList = (bookingId, params = { page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE }) => {
    setOrdersSummaryFallBackList({ ...initialState.ordersSummaryList.current, state: 'loading' });
    OrdersSummaryListGet<Types.OrdersSummaryList>(apiPathV2.ordersList, bookingId, params)
      .then(({ status, data, pagination }) => setOrdersSummaryFallBackList({ state: 'ready', status, data, pagination }))
      .catch((error) => commonSetError(error, setOrdersSummaryFallBackList));
  };

  const createOrder: Types.RequestAct.OrderCreate = (bookingId, body) => {
    setOrderCreate({ ...initialState.orderCreate.current, state: 'loading' });
    OrderCreate<Types.OrderCreate>(apiPathV1.orderCreate, bookingId, body)
      .then(({ status, data }) => setOrderCreate({ state: 'ready', status, data }))
      .catch((error) => commonSetError(error, setOrderCreate));
  };

  const deleteOrder: Types.RequestAct.OrderDelete = (bookingId, orderId) => {
    setOrderDelete({ ...initialState.orderDelete.current, state: 'loading' });
    OrderDelete<Types.OrderDelete>(apiPathV1.orderDelete, bookingId, orderId)
      .then(({ status, data }) => setOrderDelete({ state: 'ready', status, data }))
      .catch((error) => commonSetError(error, setOrderDelete));
  };

  const deleteOrderAll: Types.RequestAct.OrdersDeleteAll = (bookingId) => {
    setOrderDeleteAll({ ...initialState.ordersDeleteAll.current, state: 'loading' });
    OrderDeleteAll<Types.OrdersDeleteAll>(apiPathV1.orderAllDelete, bookingId)
      .then(({ status, data }) => setOrderDeleteAll({ state: 'ready', status, data }))
      .catch((error) => commonSetError(error, setOrderDeleteAll));
  };

  const getOrdersCount: Types.RequestAct.OrdersCount = (bookingId, callBackFunc) => {
    setOrderCount({ ...initialState.ordersCount.current, state: 'loading' });
    OrderCountGet<Types.OrdersCount>(apiPathV1.orderCount, bookingId)
      .then((crud) => {
        setOrderCount({ state: 'ready', status: crud.status, data: crud.data });
        callBackFunc && callBackFunc(crud.data?.amount || 0);
      })
      .catch((error) => commonSetError(error, setOrderCount));
  };

  const getOrders: Types.RequestAct.FallbackMealGetOrder = (
    bookingId,
    restaurantId,
    params = { page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE, optOut: OptOutEnum.ALL }
  ) => {
    if (bookingId && restaurantId) {
      setOrders({ ...initialState.orders.current, state: 'loading' });
      fallbackMealGetOrders<Types.FallbackMealGetOrder>(apiPathV2.fallbackMealAdmin, bookingId, restaurantId, params)
        .then(({ status, data, pagination }) => {
          setOrders({ state: 'ready', status, data, pagination });
        })
        .catch((error) => {
          commonSetError(error, setOrders);
        });
    }
  };

  const changedOrderRestaurants: Types.RequestAct.FallbackMealGetOrder = (bookingId, restaurantId) => {
    if (bookingId && restaurantId) {
      const data: ChangedOrderRestaurant = {
        restaurantId,
      };
      setChangedRestaurants({ ...initialState.changedOrderRestaurants.current, state: 'loading' });
      fallbackMealChangedOrderRestaurants<Types.FallbackMealGetOrder>(apiPathV2.fallbackMealAdmin, bookingId, data)
        .then(({ status, data, pagination }) => {
          setChangedRestaurants({ state: STATE_ENUM.READY, status });
          setOrders({ state: STATE_ENUM.READY, status, data, pagination });
        })
        .catch((error) => {
          commonSetError(error, setChangedRestaurants);
        });
    }
  };

  const getScheduleOrderList: Types.RequestAct.ScheduleOrderList = (bookingId, params = { page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE }) => {
    setScheduleOrderList({ ...initialState.scheduleOrderList.current, state: 'loading' });
    ScheduleOrderListGet<Types.ScheduleOrderList>(apiPathV2.ordersList, bookingId, params)
      .then(({ status, data, pagination }) => setScheduleOrderList({ state: 'ready', status, data, pagination }))
      .catch((error) => commonSetError(error, setScheduleOrderList));
  };

  const orderStore: IOrderContextV2 = {
    selectedOrderDate: {
      current: selectedOrderDate,
      setState: setSelectedOrderDate,
    },
    ordersSummaryList: {
      current: ordersSummaryList,
      setState: setOrdersSummaryList,
      requestAct: getOrdersSummaryList,
    },
    ordersSummaryFallBackList: {
      current: ordersSummaryFallBackList,
      setState: setOrdersSummaryFallBackList,
      requestAct: getOrdersSummaryFallBackList,
    },
    orderCreate: {
      current: orderCreateCRUD,
      setState: setOrderCreate,
      requestAct: createOrder,
    },
    orderDelete: {
      current: orderDeleteCRUD,
      setState: setOrderDelete,
      requestAct: deleteOrder,
    },
    ordersDeleteAll: {
      current: ordersDeleteAllCRUD,
      setState: setOrderDeleteAll,
      requestAct: deleteOrderAll,
    },
    ordersCount: {
      current: orderCount,
      setState: setOrderCount,
      requestAct: getOrdersCount,
    },
    orders: {
      current: orders,
      setState: setOrders,
      requestAct: getOrders,
    },
    changedOrderRestaurants: {
      current: changedRestaurants,
      setState: setChangedRestaurants,
      requestAct: changedOrderRestaurants,
    },
    scheduleOrderList: {
      current: scheduleOrderList,
      setState: setScheduleOrderList,
      requestAct: getScheduleOrderList,
    },
  };

  useEffect(() => {
    return () => {
      // NOTE: cancel all subscriptions and asynchronous tasks to cleanup function, prevent React state update on an unmounted component, cause memory leak
      setOrdersSummaryList(initialState.ordersSummaryList.current);
      setOrdersSummaryFallBackList(initialState.ordersSummaryFallBackList.current);
      setOrderCreate(initialState.orderCreate.current);
      setOrderDelete(initialState.orderDelete.current);
      setOrderDeleteAll(initialState.ordersDeleteAll.current);
      setOrderCount(initialState.ordersCount.current);
      setOrders(initialState.orders.current);
      setChangedRestaurants(initialState.changedOrderRestaurants.current);
      setScheduleOrderList(initialState.scheduleOrderList.current);
    };
  }, []);

  return <OrderContextV2.Provider value={orderStore}>{children}</OrderContextV2.Provider>;
};

function useOrdersContextV2() {
  return useContext(OrderContextV2);
}

export { useOrdersContextV2, OrdersContextV2Provider, initialState as OrderInitialState };
export type { Types as OrderTypes };

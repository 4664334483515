import { CommonCrud, PaginatedCrud, QueryParamsGetList } from '@/types/index';
import { GetNotification, GetNotificationList, NotificationRequest } from '@/src/types/crud/notifications/notifications';
import { NotificationResponse } from '@/src/types/crud/notifications/notifications.response';
import { ActionUnit2, commonSetError, initialApiContext2, initialStateContext } from '../common';
import { useContext, createContext, useState, useEffect } from 'react';
import { notificationOrderByType } from '@/src/types/crud/notifications/notifications.queries';
import { DEFAULT_ROW_PER_PAGE } from '@/src/app.constants/common';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { NotificationCreate, NotificationDelete, NotificationListGet, NotificationMultiDelete, NotificationUpdate } from '@/src/services/http/notification.v2';
import { apiPathV1 } from '@/src/app.constants/apiCallPaths/api.path.v1';
import { NotificationStatus } from '@/src/types/schema/enum/notification.enum';

declare namespace Types {
  export type NotificationList = PaginatedCrud<GetNotificationList>;
  export type NotificationCreate = CommonCrud<NotificationResponse>;
  export type NotificationUpdate = CommonCrud<NotificationResponse>;
  export type NotificationDelete = CommonCrud<{ id: string } | undefined>;
  export type NotificationMultiDelete = CommonCrud<{ ids: string[] } | undefined>;
  export namespace RequestAct {
    export type NotificationList = (params: QueryParamsGetList<notificationOrderByType>) => void;
    export type NotificationCreate = (payload: NotificationRequest, preUploadImage: string, filter: string | NotificationStatus) => void;
    export type NotificationUpdate = (payload: NotificationRequest, preUploadImage: string, filter: string | NotificationStatus) => void;
    export type NotificationDelete = (id: string) => void;
    export type NotificationMultiDelete = (ids: string[]) => Promise<void>;
  }
}

interface INotificationsContextV2 {
  notificationList: ActionUnit2<Types.NotificationList> & { requestAct: Types.RequestAct.NotificationList };
  notificationResponseList: ActionUnit2<Types.NotificationList>;
  notificationCreate: ActionUnit2<Types.NotificationCreate> & { requestAct: Types.RequestAct.NotificationCreate };
  notificationUpdate: ActionUnit2<Types.NotificationUpdate> & { requestAct: Types.RequestAct.NotificationUpdate };
  notificationDelete: ActionUnit2<Types.NotificationDelete> & { requestAct: Types.RequestAct.NotificationDelete };
  notificationMultiDelete: ActionUnit2<Types.NotificationMultiDelete> & { requestAct: Types.RequestAct.NotificationMultiDelete };
}

const initialState: INotificationsContextV2 = {
  notificationList: { ...initialApiContext2<Types.NotificationList, Types.RequestAct.NotificationList>() },
  notificationResponseList: { ...initialStateContext<Types.NotificationList>() },
  notificationCreate: { ...initialApiContext2<Types.NotificationCreate, Types.RequestAct.NotificationCreate>() },
  notificationUpdate: { ...initialApiContext2<Types.NotificationUpdate, Types.RequestAct.NotificationUpdate>() },
  notificationDelete: { ...initialApiContext2<Types.NotificationDelete, Types.RequestAct.NotificationDelete>() },
  notificationMultiDelete: { ...initialApiContext2<Types.NotificationMultiDelete, Types.RequestAct.NotificationMultiDelete>() },
};

const NotificationsContextV2: React.Context<INotificationsContextV2> = createContext(initialState);

function useNotificationsContextV2() {
  return useContext(NotificationsContextV2);
}

const NotificationsContextV2Provider = ({ children }: { children: React.ReactNode }) => {
  const apiUrl = apiPathV1.adminNotification;

  const [notificationList, setNotificationList] = useState<Types.NotificationList>(initialState.notificationList.current);
  const [notificationResponseList, setNotificationResponseList] = useState<Types.NotificationList>(initialState.notificationResponseList.current);

  const [notificationCreate, setNotificationCreate] = useState<Types.NotificationCreate>(initialState.notificationCreate.current);
  const [notificationUpdate, setNotificationUpdate] = useState<Types.NotificationUpdate>(initialState.notificationUpdate.current);
  const [notificationDelete, setNotificationDelete] = useState<Types.NotificationDelete>(initialState.notificationDelete.current);
  const [notificationMultiDelete, setNotificationMultiDelete] = useState<Types.NotificationMultiDelete>(initialState.notificationMultiDelete.current);

  // action for notificationList
  const fetchNotificationList: Types.RequestAct.NotificationList = (params = { page: 0, itemPerPage: DEFAULT_ROW_PER_PAGE }) => {
    setNotificationList({ ...initialState.notificationList.current, state: STATE_ENUM.LOADING });
    NotificationListGet<Types.NotificationList>(params)
      .then(({ status, data, pagination }) => {
        const val = { state: STATE_ENUM.READY, data, status, pagination };
        setNotificationList(val);
        setNotificationResponseList(val);
      })
      .catch((error) => commonSetError(error, setNotificationList));
  };

  // action for create notification
  const createNotification: Types.RequestAct.NotificationCreate = (payload, preUploadImage, filter) => {
    setNotificationCreate({ ...initialState.notificationCreate.current, state: STATE_ENUM.LOADING });
    NotificationCreate<Types.NotificationCreate>(apiUrl, payload)
      .then(({ status, data }) => {
        setNotificationCreate({ state: STATE_ENUM.READY, data, status });
        // Use for mock notification image in notificationsList.
        const updateNotificationResponseList = {
          ...payload,
          id: data?.id,
          createdDate: data?.actionDate,
          scheduledDate: data?.scheduledDate,
          createdBy: data?.createdBy,
          imageUrl: data?.imageUrl,
        } as GetNotification;

        const updateNotificationsList = { ...updateNotificationResponseList, imageUrl: preUploadImage } as GetNotification;
        const notifications = [...notificationList.data!];
        // If current list is not less than row per page, then remove last item.
        notifications.length >= notificationList?.pagination?.itemPerPage! && notifications.pop();
        if (filter === data?.status) {
          setNotificationResponseList({
            data: [updateNotificationResponseList].concat(notifications),
            state: STATE_ENUM.READY,
            status,
          });
          setNotificationList({
            data: [updateNotificationsList].concat(notifications),
            state: STATE_ENUM.READY,
            status,
            pagination: notificationList?.pagination,
          });
        } else if (filter === 2) {
          setNotificationResponseList({
            data: [updateNotificationResponseList].concat(notifications),
            state: STATE_ENUM.READY,
            status,
          });
          setNotificationList({
            data: [updateNotificationsList].concat(notifications),
            state: STATE_ENUM.READY,
            status,
            pagination: notificationList?.pagination,
          });
        } else {
          setNotificationResponseList({
            data: [updateNotificationResponseList].concat(notifications),
            state: STATE_ENUM.READY,
            status,
          });
        }
      })
      .catch((error) => commonSetError(error, setNotificationCreate));
  };

  // action for update notification
  const updateNotification: Types.RequestAct.NotificationUpdate = (payload, preUploadImage, filter) => {
    setNotificationUpdate({ ...initialState.notificationUpdate.current, state: STATE_ENUM.LOADING });
    NotificationUpdate<Types.NotificationUpdate>(apiUrl, payload)
      .then(({ status, data }) => {
        setNotificationUpdate({ state: STATE_ENUM.READY, data, status });
        const updateNotification = notificationList.data?.map((notification) =>
          notification.id === payload.id
            ? {
                ...notification,
                ...payload,
                scheduledDate: data?.scheduledDate,
                imageUrl: preUploadImage,
              }
            : notification
        ) as GetNotificationList;
        const updateNotificationResponse = notificationList.data?.map((notification) =>
          notification.id === payload.id
            ? {
                ...notification,
                ...payload,
                scheduledDate: data?.scheduledDate,
                imageUrl: data?.imageUrl,
              }
            : notification
        ) as GetNotificationList;
        if (filter === data?.status) {
          setNotificationList({
            data: updateNotification,
            state: STATE_ENUM.READY,
            status,
            pagination: notificationList?.pagination,
          });
          setNotificationResponseList({
            data: updateNotificationResponse,
            state: STATE_ENUM.READY,
            status,
          });
        } else if (filter === 2) {
          const updateNotification = notificationList.data?.map((notification) =>
            notification.id === payload.id
              ? {
                  ...notification,
                  ...payload,
                  scheduledDate: data?.scheduledDate,
                  imageUrl: preUploadImage,
                  status: payload.status,
                }
              : notification
          ) as GetNotificationList;
          setNotificationList({
            data: updateNotification,
            state: STATE_ENUM.READY,
            status,
            pagination: notificationList?.pagination,
          });
          setNotificationResponseList({
            data: updateNotificationResponse,
            state: STATE_ENUM.READY,
            status,
          });
        } else {
          const updateNotification = notificationList.data?.filter((notification) => notification.id !== payload.id);
          setNotificationList({
            data: updateNotification,
            state: STATE_ENUM.READY,
            status,
            pagination: notificationList?.pagination,
          });
          setNotificationResponseList({
            data: updateNotificationResponse,
            state: STATE_ENUM.READY,
            status,
          });
        }
      })
      .catch((error) => commonSetError(error, setNotificationUpdate));
  };

  // action for delete notification
  const deleteNotification: Types.RequestAct.NotificationDelete = (id) => {
    const notificationDeleteList = [...(notificationList.data || [])];
    setNotificationDelete({ ...initialState.notificationDelete.current, state: STATE_ENUM.LOADING });
    NotificationDelete<Types.NotificationDelete>(id)
      .then(({ status, data }) => {
        const currentNotificationList = notificationDeleteList?.filter((e) => e.id !== data?.id);
        const currentNotificationResponseList = [...currentNotificationList];
        setNotificationResponseList({
          data: currentNotificationResponseList,
          state: STATE_ENUM.READY,
        });
        setNotificationDelete({ state: STATE_ENUM.READY, data, status });
      })
      .catch((error) => {
        setNotificationList({
          data: notificationDeleteList,
          state: STATE_ENUM.READY,
          status: notificationList.status,
          pagination: notificationList?.pagination,
        });
        commonSetError(error, setNotificationDelete);
      });
  };

  // action for multi delete notification
  const deleteMultiNotification: Types.RequestAct.NotificationMultiDelete = async (ids) => {
    const notificationDeleteList = [...(notificationList.data || [])];

    setNotificationMultiDelete({ ...initialState.notificationMultiDelete.current, state: STATE_ENUM.LOADING });
    return NotificationMultiDelete<Types.NotificationMultiDelete>(ids)
      .then(({ status, data }) => {
        setNotificationMultiDelete({ state: STATE_ENUM.READY, data, status });
      })
      .catch((error) => {
        setNotificationList({
          data: notificationDeleteList,
          state: STATE_ENUM.READY,
          status: notificationList.status,
          pagination: notificationList?.pagination,
        });
        commonSetError(error, setNotificationDelete);
      });
  };

  const notificationStore: INotificationsContextV2 = {
    notificationList: {
      current: notificationList,
      setState: setNotificationList,
      requestAct: fetchNotificationList,
    },
    notificationResponseList: {
      current: notificationResponseList,
      setState: setNotificationResponseList,
    },
    notificationCreate: {
      current: notificationCreate,
      setState: setNotificationCreate,
      requestAct: createNotification,
    },
    notificationUpdate: {
      current: notificationUpdate,
      setState: setNotificationUpdate,
      requestAct: updateNotification,
    },
    notificationDelete: {
      current: notificationDelete,
      setState: setNotificationDelete,
      requestAct: deleteNotification,
    },
    notificationMultiDelete: {
      current: notificationMultiDelete,
      setState: setNotificationMultiDelete,
      requestAct: deleteMultiNotification,
    },
  };

  useEffect(() => {
    return () => {
      setNotificationList(initialState.notificationList.current);
      setNotificationCreate(initialState.notificationCreate.current);
      setNotificationUpdate(initialState.notificationUpdate.current);
      setNotificationDelete(initialState.notificationDelete.current);
      setNotificationMultiDelete(initialState.notificationMultiDelete.current);
    };
  }, []);

  return <NotificationsContextV2.Provider value={notificationStore}>{children}</NotificationsContextV2.Provider>;
};

export { useNotificationsContextV2, NotificationsContextV2Provider };
export type { Types as NotificationTypes };

/** declare only http service related requests */

import { AxiosService } from '@/utils/axios';
import { AxiosResponse } from 'axios';

import { QueryParamsGetList, orderOrderByType } from '@/types/crud';
import { apiMethodV1 } from '@/app.constants/apiCallMethods/index';
import { OrderCreateBody } from '@/types/index';
import { paramsQueryString } from '@/src/utils';

/**
 * @private This api is protected and can only be accessed as role=ADMIN
 * @param prefix only partial url, base url already prefixed
 * @param {boolean?} hardRejection when soft error is enabled it will return response from .then, and not catch it again, just so you know
 */

const OrdersSummaryListGet = <T>(prefix: string, bookingId: string, params: QueryParamsGetList<orderOrderByType>, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  const queryUri = paramsQueryString(prefix + bookingId, params);

  const config = {
    ...instance.config,
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const OrderDelete = <T>(prefix: string, bookingId: string, orderId: string, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.delete, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };
  return instance.client
    .delete(prefix + bookingId + '/' + orderId, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const OrderCreate = <T>(prefix: string, bookingId: string, body: OrderCreateBody, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .post(prefix + bookingId, JSON.stringify(body), config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const OrderDeleteAll = <T>(prefix: string, bookingId: string, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.delete, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .delete(prefix + bookingId, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const OrderCountGet = <T>(prefix: string, bookingId: string, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);

  const config = {
    ...instance.config,
  };

  return instance.client
    .get(prefix + bookingId, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

const ScheduleOrderListGet = <T>(prefix: string, bookingId: string, params: QueryParamsGetList<orderOrderByType>, hardRejection = true): Promise<T> => {
  const instance = new AxiosService({ method: apiMethodV1.get, timeout: 12000 }, true, hardRejection);
  const queryUri = paramsQueryString(prefix + bookingId, params);

  const config = {
    ...instance.config,
  };

  return instance.client
    .get(queryUri, config)
    .then((d: AxiosResponse<T>) => {
      return d.data;
    })
    .catch(instance.defaultHandleError) as Promise<T>;
};

export { OrdersSummaryListGet, OrderDelete, OrderCreate, OrderDeleteAll, OrderCountGet, ScheduleOrderListGet };

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import TableBody from '@mui/material/TableBody';
import moment from 'moment';
import { TableRowMenuSettingInlineEdit } from '../tableRowMenuSettingInlineEdit';
import { useOrdersContextV2, useRestaurantBookingContext, useSnackbarContext } from '@/services/index';
import { RestaurantBookingSchema, RestaurantSchema, statusRestaurantType } from '@/src/types/schema';
import { TableRowMenuSettingInlineEditProps } from '@/types/ui/TableRowMenuSettingInlineEditProps';
import { BOOKING_STATUS_ENUM, STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import ModalForChangeRestaurantOnSchedulePage from '../modal/modalChangeRestaurantSchedule';
import { apiPathV1 } from '@/src/app.constants';
import { QueryParamsGetList, orderOrderByType } from '@/src/types';
import ModalConfirmChangeRestaurants from '../modal/modalConfirmChangeRestaurants';

interface EnhancedTableBodyScheduleProps {
  Month: string;
  Year: string;
  setLoad: Dispatch<SetStateAction<boolean>>;
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
}

export function EnhancedTableBodySchedule(props: EnhancedTableBodyScheduleProps) {
  const { Month, Year, setLoad, setOpenPopup } = props;
  const daysInMonth = moment(`${Year}-${Month}-01`, 'YYYY-MMMM-DD').daysInMonth();
  const {
    getRestaurantBookingList,
    restaurantBookingList,
    restaurantBookingUpdateData,
    getRestaurantBookingUpdateList,
    setRestaurantBookingUpdateData,
    restaurantBookingStatusUpdateData,
    setRestaurantBookingStatusUpdateData,
    getRestaurantBookingUpdateStatusList,
    restaurantBookingCreateData,
    setRestaurantBookingCreateData,
    getRestaurantBookingCreate,
    restaurantBookingRemoveData,
    setRestaurantBookingRemoveData,
    setRestaurantBookingUpdate,
    setRestaurantBookingCreate,
    restaurantBookingStatusCheckingData,
    setRestaurantBookingStatusChecking,
  } = useRestaurantBookingContext();

  const { changedOrderRestaurants } = useOrdersContextV2();
  const { setResponseStatus } = useSnackbarContext();
  const [dayArray, setDayArray] = useState<TableRowMenuSettingInlineEditProps[]>([]);
  const [editRow, setEditRow] = useState(-1);
  const [checkLoad, setCheckLoad] = useState<boolean>(false);
  const [paginationParams] = useState<QueryParamsGetList<orderOrderByType>>({ page: 0, itemPerPage: 60 });
  const [openChangeRestaurant, setOpenChangeRestaurant] = useState(false);
  const [onResetChangedOrderRestaurants, setResetChangedOrderRestaurants] = useState<boolean>(false);
  const [newRestaurantId, setNewRestaurantId] = React.useState<undefined | string>(undefined);
  const [newRestaurant, setNewRestaurant] = React.useState<RestaurantSchema | undefined>(undefined);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState('');
  const [openConfirmChangedModal, setOpenConfirmChangedModal] = useState<boolean>(false);
  const { ordersSummaryList, scheduleOrderList, ordersCount } = useOrdersContextV2();
  const [removeButtonDisableStatus, setRemoveButtonDisableStatus] = useState<boolean>(false);
  const [openAllowAnAdminToChangeRestaurantModal, setOpenAllowAnAdminToChangeRestaurantModal] = useState<boolean>(false);
  const [updateRestaurantBookingPayload, setUpdateRestaurantBookingPayload] = useState<{
    restaurantId: string;
    status: statusRestaurantType;
  }>({
    restaurantId: '',
    status: 'Open',
  });

  const onEditRow = (rowNo: number, enable: boolean) => {
    if (enable) {
      setEditRow(rowNo);
    } else {
      setEditRow(-1);
    }
  };

  const processUpdateRestaurants = (booking: RestaurantBookingSchema | undefined, restaurantId: string, bookingId: string, rowIndex: number): void => {
    if (booking?.id && booking?.status !== 'Removed') {
      // if status is holiday then only update data
      if (booking.status === BOOKING_STATUS_ENUM.HOLIDAY) {
        setRestaurantBookingUpdate(
          apiPathV1.restaurantBookingUpdate,
          {
            restaurantId,
            status: BOOKING_STATUS_ENUM.OPEN,
          },
          bookingId,
          () => {
            setRemoveButtonDisableStatus(false);
            onEditRow(rowIndex, false);
          }
        );
      } else {
        ordersCount.requestAct(booking.id, (amount: number) => {
          if (amount > 0) {
            setOpenAllowAnAdminToChangeRestaurantModal(true);
          } else {
            setRestaurantBookingUpdate(
              apiPathV1.restaurantBookingUpdate,
              {
                restaurantId,
                status: BOOKING_STATUS_ENUM.OPEN,
              },
              bookingId,
              () => {
                setRemoveButtonDisableStatus(false);
                onEditRow(rowIndex, false);
              }
            );
          }
        });
      }
    } else {
      setRestaurantBookingCreate(
        apiPathV1.restaurantBookingUpdate,
        {
          date: bookingId,
          restaurantId,
        },
        () => {
          setRemoveButtonDisableStatus(false);
          onEditRow(rowIndex, false);
        }
      );
    }
  };

  // update restaurantBookingList after restaurant selected
  useEffect(() => {
    if (restaurantBookingUpdateData.state === STATE_ENUM.READY) {
      setResponseStatus({
        status: 'success',
        message: restaurantBookingUpdateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      getRestaurantBookingUpdateList(
        restaurantBookingUpdateData.data?.date ?? '',
        restaurantBookingUpdateData.data?.restaurantId ?? '',
        restaurantBookingUpdateData.data?.status ?? 'Open',
        restaurantBookingUpdateData.data?.thName ?? '',
        restaurantBookingUpdateData.data?.enName ?? ''
      );

      setRestaurantBookingUpdateData({
        state: STATE_ENUM.INITIAL,
        data: undefined,
      });
    } else if (restaurantBookingUpdateData.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: restaurantBookingUpdateData.state,
        message: restaurantBookingUpdateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      setRestaurantBookingUpdateData({
        state: STATE_ENUM.INITIAL,
        data: undefined,
      });
    }
  }, [restaurantBookingUpdateData]);

  // update restaurantBookingList after remove restaurant
  useEffect(() => {
    if (restaurantBookingRemoveData.state === STATE_ENUM.READY) {
      setResponseStatus({
        status: 'success',
        message: 'Success. Your changes have been saved.',
      });
      setOpenPopup(true);
      setRestaurantBookingUpdateData({
        state: STATE_ENUM.INITIAL,
        data: undefined,
      });
      setRestaurantBookingStatusUpdateData({ state: STATE_ENUM.INITIAL, data: undefined });
      setRestaurantBookingRemoveData({ state: STATE_ENUM.INITIAL, data: undefined });
    } else if (restaurantBookingRemoveData.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: restaurantBookingRemoveData.state,
        message: 'Request fail. Try again later',
      });
      setOpenPopup(true);
      setRestaurantBookingUpdateData({ state: STATE_ENUM.INITIAL, data: undefined });
      setRestaurantBookingStatusUpdateData({ state: STATE_ENUM.INITIAL, data: undefined });
      setRestaurantBookingRemoveData({ state: STATE_ENUM.INITIAL, data: undefined });
    }
  }, [restaurantBookingRemoveData]);

  // update restaurantBookingList after public holiday selected
  useEffect(() => {
    if (restaurantBookingStatusUpdateData.state === STATE_ENUM.READY) {
      setResponseStatus({
        status: 'success',
        message: restaurantBookingStatusUpdateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      getRestaurantBookingUpdateStatusList(restaurantBookingStatusUpdateData.data?.date ?? '', restaurantBookingStatusUpdateData.data?.status ?? 'Holiday');

      setRestaurantBookingStatusUpdateData({ state: STATE_ENUM.INITIAL, data: undefined });
    } else if (restaurantBookingStatusUpdateData.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: restaurantBookingStatusUpdateData.state,
        message: restaurantBookingStatusUpdateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      setRestaurantBookingStatusUpdateData({ state: STATE_ENUM.INITIAL, data: undefined });
    }
  }, [restaurantBookingStatusUpdateData]);

  // update restaurantBookingList after create a new restaurantBooking
  useEffect(() => {
    if (restaurantBookingCreateData.state === STATE_ENUM.READY) {
      setResponseStatus({
        status: 'success',
        message: restaurantBookingCreateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      if (restaurantBookingCreateData.data) getRestaurantBookingCreate(restaurantBookingCreateData.data);

      setRestaurantBookingCreateData({
        state: STATE_ENUM.INITIAL,
        data: undefined,
      });
    } else if (restaurantBookingCreateData.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: restaurantBookingCreateData.state,
        message: restaurantBookingCreateData?.status?.message ?? '',
      });
      setOpenPopup(true);

      setRestaurantBookingCreateData({
        state: STATE_ENUM.INITIAL,
        data: undefined,
      });
    }
  }, [restaurantBookingCreateData]);

  useEffect(() => {
    const state = changedOrderRestaurants?.current?.state;

    if (state === STATE_ENUM.READY) {
      setResponseStatus({
        status: 'success',
        message: changedOrderRestaurants.current?.status?.message ?? '',
      });
      setOpenPopup(true);
    } else if (state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: state,
        message: changedOrderRestaurants.current?.error ?? 'Something went wrong. Please try again.',
      });
      changedOrderRestaurants.setState({ state: STATE_ENUM.INITIAL });
      setOpenPopup(true);
    }
  }, [changedOrderRestaurants?.current?.state]);

  useEffect(() => {
    const newDayArray = [] as TableRowMenuSettingInlineEditProps[];
    const today = moment();
    const m = moment(`${Year} ${Month}`, 'YYYY MMMM');
    const startDate = m.startOf('month') < today ? today.date() : 1;
    for (let date = startDate; date <= daysInMonth; date++) {
      const curDay = moment(Year + '-' + Month + '-' + date, 'YYYY-MMMM-DD');
      if (curDay.isoWeekday() === 6 || curDay.isoWeekday() === 7) continue;
      const remainingDaysInWeek = 7 - curDay.isoWeekday() - 1;
      const booking = restaurantBookingList?.data?.find((x: RestaurantBookingSchema) => x.date === curDay.format('YYYY-MM-DD'));

      newDayArray.push({
        date,
        booking,
        curDay,
        remainingDaysInWeek,
        edit: editRow === date,
      } as TableRowMenuSettingInlineEditProps);
    }

    setDayArray(newDayArray);
  }, [restaurantBookingList]);

  useEffect(() => {
    if (
      restaurantBookingList.state === STATE_ENUM.LOADING ||
      restaurantBookingUpdateData.state === STATE_ENUM.LOADING ||
      restaurantBookingStatusUpdateData.state === STATE_ENUM.LOADING ||
      restaurantBookingCreateData.state === STATE_ENUM.LOADING ||
      restaurantBookingRemoveData.state === STATE_ENUM.LOADING
    ) {
      setCheckLoad(true);
    } else {
      setCheckLoad(false);
    }
  }, [restaurantBookingUpdateData, restaurantBookingStatusUpdateData, restaurantBookingCreateData, restaurantBookingRemoveData, restaurantBookingList]);

  useEffect(() => {
    const bookingId = restaurantBookingStatusCheckingData.data?.bookingId;
    const restaurantId = updateRestaurantBookingPayload.restaurantId;
    if (bookingId && restaurantId) {
      switch (restaurantBookingStatusCheckingData.state) {
        case STATE_ENUM.READY:
          {
            const data = dayArray.find((t) => t.booking?.date === restaurantBookingStatusCheckingData.data?.bookingId);
            const booking = data?.booking;
            const rowIndex = data ? dayArray.indexOf(data) : -1;

            if (restaurantBookingStatusCheckingData.data?.haveFallbackMeal) {
              scheduleOrderList.requestAct(bookingId, paginationParams);
              ordersSummaryList.requestAct(bookingId, paginationParams);
              setResetChangedOrderRestaurants(true);
              setOpenChangeRestaurant(true);
              onEditRow(rowIndex, false);
            } else {
              processUpdateRestaurants(booking, restaurantId, bookingId, rowIndex);
            }
          }
          break;
        case STATE_ENUM.ERROR:
          {
            const data = dayArray.find((t) => t.booking?.date === restaurantBookingStatusCheckingData.data?.bookingId);
            const booking = data?.booking;
            const rowIndex = data ? dayArray.indexOf(data) : -1;
            processUpdateRestaurants(booking, restaurantId, bookingId, rowIndex);
          }
          break;
      }
    }
  }, [restaurantBookingStatusCheckingData]);

  const handleCloseModalChangeRestaurant = () => {
    getRestaurantBookingList(apiPathV1.restaurantBookingList, Year, Month);
    setCheckLoad(false);
    setOpenChangeRestaurant(false);
    setResetChangedOrderRestaurants(false);
    setRemoveButtonDisableStatus(false);
  };

  const handleSaveChangeRestaurant = () => {
    setOpenChangeRestaurant(false);
    setOpenConfirmChangedModal(true);
  };

  const SaveChangeRestaurant = () => {
    setOpenConfirmChangedModal(false);
    if (scheduleOrderList.current.data?.date && newRestaurantId) {
      changedOrderRestaurants.requestAct(scheduleOrderList.current.data?.date, newRestaurantId);
      setOpenChangeRestaurant(false);
      setResetChangedOrderRestaurants(false);
    }
  };

  const handleChangeRestaurant = (restaurantId: string, bookingId: string) => {
    setCheckLoad(true);
    setRemoveButtonDisableStatus(true);
    setUpdateRestaurantBookingPayload({
      restaurantId,
      status: 'Open',
    });
    setRestaurantBookingStatusChecking(apiPathV1.restaurantBookingStatusChecking, bookingId);
  };

  return (
    <>
      <TableBody id="tbody-schedule">
        {dayArray.map((data) => (
          <TableRowMenuSettingInlineEdit
            date={data.date}
            booking={data.booking}
            curDay={data.curDay}
            remainingDaysInWeek={data.remainingDaysInWeek}
            key={data.date}
            edit={editRow === data.date}
            onEdit={(e: boolean) => onEditRow(data.date, e)}
            setLoad={setLoad}
            checkLoad={checkLoad}
            setCheckLoad={setCheckLoad}
            onChangedRestaurants={function (restaurantId: string): void {
              handleChangeRestaurant(restaurantId, data?.curDay.format('YYYY-MM-DD') || '');
              setSelectedRestaurantId(restaurantId);
            }}
            disableRemoveButton={removeButtonDisableStatus}
            allowAnAdminToChangeRestaurantModal={openAllowAnAdminToChangeRestaurantModal}
            updateBookingPayload={updateRestaurantBookingPayload}
          ></TableRowMenuSettingInlineEdit>
        ))}
      </TableBody>
      <ModalForChangeRestaurantOnSchedulePage
        open={openChangeRestaurant}
        restaurantId={scheduleOrderList?.current.data?.restaurant?.restaurantId}
        thName={scheduleOrderList?.current.data?.restaurant?.restaurantThName}
        enName={scheduleOrderList?.current.data?.restaurant?.restaurantEnName}
        date={scheduleOrderList?.current.data?.date}
        status={scheduleOrderList?.current.data?.bookingStatus}
        onClose={handleCloseModalChangeRestaurant}
        onConfirm={handleSaveChangeRestaurant}
        onChange={setNewRestaurantId}
        isResetChanged={onResetChangedOrderRestaurants}
        setSelectionRestaurant={setNewRestaurant}
        selectedRestaurantId={selectedRestaurantId}
      />
      <ModalConfirmChangeRestaurants
        open={openConfirmChangedModal}
        newRestaurant={newRestaurant}
        oldRestaurant={ordersSummaryList?.current?.data?.restaurant}
        onConfirm={() => {
          SaveChangeRestaurant();
        }}
        onCancel={() => {
          setOpenChangeRestaurant(true);
          setOpenConfirmChangedModal(false);
        }}
      ></ModalConfirmChangeRestaurants>
    </>
  );
}

import React from 'react';
import { Backdrop, Box, Button, Typography } from '@mui/material';

const modalStyle = {
  position: 'fixed',
  margin: 'auto',
  top: '30%',
  left: '50%',
  width: 495,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: 'grid',
  alignContent: 'stretch',
  justifyContent: 'space - around',
  transform: 'translate(-50%, -50%)',
  alignItems: 'start',
  justifyItems: 'center',
  'z-index': 1000,
};

interface ModalConfirmInactiveUserProps {
  open: boolean;
  isActiveUser: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}
export default function ModalConfirmInactiveUser(props: ModalConfirmInactiveUserProps) {
  const { open, isActiveUser, onConfirm, onCancel } = props;

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <Box
        sx={{
          ...modalStyle,
          maxWidth: '500px',
          padding: '20px',
          textAlign: 'center',
        }}
        className="mt-10"
        role="dialog"
      >
        <Typography
          id="transition-modal-description"
          variant="caption"
          sx={{
            color: '#000',
            fontSize: '16px',
            whiteSpace: 'normal',
            textAlign: 'center',
          }}
        >
          {isActiveUser ? (
            'Are you sure you want to activate this user?'
          ) : (
            <>
              Are you sure you want to permanently remove this user?
              <br />
              (However, this user can join and onboard again if needed)
            </>
          )}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <Button id={'btn-modal-cancel-all-order-yes'} variant="contained" onClick={onConfirm} sx={{ marginRight: '10px' }}>
            YES
          </Button>
          <Button id={'btn-modal-cancel-all-order-no'} color={'error'} variant="outlined" onClick={onCancel}>
            NO
          </Button>
        </Box>
      </Box>
    </Backdrop>
  );
}

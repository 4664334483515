import React, { useEffect, useRef, useState } from 'react';
// mui
import { Autocomplete, TextField, FormControl, Checkbox, Theme, SxProps, AutocompleteChangeReason } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ClearIcon from '@mui/icons-material/Clear';

// types + constants
import { RestaurantAddOnsSchema } from '@/types/schema/restaurantAddOns';
import { styles } from '@/styles/theme';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface GroupAutocompleteProps {
  clearIconId: string;
  key: string;
  id: string;
  sx: SxProps<Theme>;
  name: string;
  multiple: boolean;
  options: any[];
  callback: (event: any, value: any, reason: any, details: any, multiple: boolean, remove?: any) => void;
  order: number;
  onLabelValueChange?: (hasValue: boolean) => void;
  defaultValue?: any;
}

export const GroupAutocomplete = (props: GroupAutocompleteProps) => {
  const { multiple, name, options, callback, sx, clearIconId, order, defaultValue, onLabelValueChange } = props;
  const classes = styles();
  const selectedRef = useRef<any>(null);

  useEffect(() => {
    selectedRef.current = defaultValue && defaultValue.length > 0 ? defaultValue[0] : null;
  }, []);

  const [pickOneHasError, setPickOneHasError] = useState<boolean>(false);
  const [pickOneHelperText, setPickOneHelperText] = useState<string>('');
  return (
    <FormControl sx={{ ...sx }}>
      {multiple ? (
        <Autocomplete
          clearIcon={<ClearIcon id={clearIconId} />}
          multiple
          id={'autocomplete-select-multi-group'}
          componentsProps={{
            popupIndicator: {
              id: `btn-arrow-select-multi-group-${order}`,
            },
            clearIndicator: {
              id: `btn-clear-select-multi-group-${order}`,
            },
          }}
          defaultValue={defaultValue || []}
          disableCloseOnSelect
          options={options}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option: RestaurantAddOnsSchema) => `${option.thName} [+${option.price}]`}
          popupIcon={<KeyboardArrowDownIcon />}
          renderInput={(params) => <TextField {...params} size={'small'} label={`Select ${name} (Multiple)`} />}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} id={'group-' + options.indexOf(option)} />
              <div className={classes.wrap_text}>
                {`${option.thName} [+${option.price}]`} {options.indexOf(option)}
              </div>
            </li>
          )}
          onChange={(event: React.SyntheticEvent, values: RestaurantAddOnsSchema[], reason: AutocompleteChangeReason, details: any) => {
            callback(event, values, reason, details, multiple);
          }}
        />
      ) : (
        <Autocomplete
          clearIcon={<ClearIcon />}
          id={`autocomplete-pick-one-${order}`}
          componentsProps={{
            popupIndicator: {
              id: `btn-arrow-group-${order}`,
            },
            clearIndicator: {
              id: `btn-clear-group-${order}`,
            },
          }}
          defaultValue={defaultValue && defaultValue.length > 0 ? defaultValue[0] : null}
          options={options}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option: RestaurantAddOnsSchema) => `${option.thName} [+${option.price}]`}
          popupIcon={<KeyboardArrowDownIcon />}
          renderInput={(params) => {
            const hasValue = !!params.inputProps.value;
            if (onLabelValueChange) {
              onLabelValueChange(hasValue);
            }
            return (
              <TextField
                {...params}
                size={'small'}
                error={pickOneHasError}
                label={
                  pickOneHasError ? (
                    ''
                  ) : (
                    <>
                      {`Select ${name} (Pick 1) `}
                      {!hasValue && <span style={{ color: 'red' }}> * require</span>}
                    </>
                  )
                }
                helperText={pickOneHelperText}
              />
            );
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} id={'li-' + options.indexOf(option)}>
              <div id={'pick-' + options.indexOf(option)}>{`${option.thName} [+${option.price}]`}</div>
            </li>
          )}
          onChange={(event: any, newValue: RestaurantAddOnsSchema | null, reason: AutocompleteChangeReason, details: any) => {
            const selected = selectedRef.current;
            if (reason === 'selectOption' && selected === null) {
              setPickOneHasError(false);
              setPickOneHelperText('');
              selectedRef.current = newValue;
              callback(event, newValue, reason, details, multiple);
            } else if (reason === 'selectOption' && selected !== null) {
              setPickOneHasError(false);
              setPickOneHelperText('');
              const remove = selectedRef.current;
              selectedRef.current = newValue;
              callback(event, newValue, reason, details, multiple, remove);
            } else if (reason === 'clear') {
              setPickOneHasError(true);
              setPickOneHelperText(`Select ${name} (Pick 1) is required`);
              callback(event, selectedRef.current, 'removeOption', details, multiple);
              selectedRef.current = null;
            }
          }}
        />
      )}
    </FormControl>
  );
};

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Backdrop, Grid, Paper, Skeleton, Table, TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { printDisplayDate } from '@/src/utils';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { headCellsOrder } from '@/src/static/order';
import { EnhancedTableHead } from '../tableHead';
import { order, orderOrderByType, QueryParamsGetList, RestaurantSchema } from '@/src/types';
import { useOrdersContextV2 } from '@/store/order.v2.store';
import { ConditionalWrapper } from '../conditionalWrapper';
import TableBodyNodata from '../tableBodyNoData';
import { EnhancedTablePaginationV2 } from '../tablePaginationV2';
import { TableRowMenuSettingInlineEditProps } from '@/src/types/ui/TableRowMenuSettingInlineEditProps';
import EnhancedTableBodyFallbackMealOrder from '../tableFallbackMealOrder';
import { useRestaurantContext } from '@/store/restaurant.store';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { EnhancedTableTotalPriceOrderV2 } from '../tableTotalPriceOrderV2';
import moment from 'moment';
import { EnhancedTableBodyCurrentRestaurantOnSchedule } from '../tableChangeRestauRantSchedule';
import theAdminImg from '@/images/theAdmin.png';
import { useCookies } from 'react-cookie';
import { BOOKING_STATUS_ENUM, STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { SelectRestaurantFallbackMeal } from '@/src/components/ui/selectRestaurantExcludeEmptyMenu';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: '5px',
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  height: '100%',
}));

export default function ModalForChangeRestaurantOnSchedulePage(
  { restaurantId, thName, enName, status, date, open, onClose, onConfirm, onChange, isResetChanged, setSelectionRestaurant, selectedRestaurantId },
  props: TableRowMenuSettingInlineEditProps
) {
  const { edit, checkLoad } = props;
  const [order, setOrder] = useState<order | null>('asc');
  const [orderBy, setOrderBy] = useState<keyof any>('');
  const [orderFallback, setOrderFallback] = useState<order | null>('asc');
  const [orderByFallback, setOrderByFallback] = useState<keyof any>('');
  const [newRestaurant, setNewRestaurant] = React.useState<RestaurantSchema | undefined>(undefined);
  const [newRestaurantId, setNewRestaurantId] = React.useState<string>('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(60);
  const [pageFallback, setFallbackPage] = useState(0);
  const [rowsFallbackPerPage, setFallbackRowsPerPage] = useState(60);
  const { restaurantListForSchedule, setRestaurantListForSchedule } = useRestaurantContext();
  const { ordersSummaryFallBackList, orders } = useOrdersContextV2();
  const [paginationOldParams, setPaginationOldParams] = useState<QueryParamsGetList<orderOrderByType>>({ page, itemPerPage: rowsPerPage });
  const [paginationFallbackParams, setPaginationFallbackParams] = useState<QueryParamsGetList<orderOrderByType>>({
    page: pageFallback,
    itemPerPage: rowsFallbackPerPage,
  });
  const [cookies] = useCookies(['sortingOrders']);
  const sorting: any = cookies?.sortingOrders || {};
  const sortingFallBack: any = { ...(cookies?.sortingOrders || {}) };

  useEffect(() => {
    if (restaurantListForSchedule.state === STATE_ENUM.INITIAL) {
      setRestaurantListForSchedule(apiPathV1.restaurantList);
    }
  });

  useEffect(() => {
    if (date) {
      if (typeof paginationOldParams.orderBy !== 'undefined') sorting.orderBy = paginationOldParams.orderBy;
      if (typeof paginationOldParams.sort !== 'undefined') sorting.sort = paginationOldParams.sort || 'asc';
      setOrderBy(paginationOldParams.orderBy || sorting.orderBy);
      setOrder(paginationOldParams.sort || sorting.sort);
      paginationOldParams.orderBy = paginationOldParams.orderBy || sorting.orderBy;
      paginationOldParams.sort = paginationOldParams.sort || sorting.sort;
      ordersSummaryFallBackList.requestAct(moment(date).format('YYYY-MM-DD'), paginationOldParams);
    }
  }, [date]);

  useEffect(() => {
    if (typeof paginationOldParams.orderBy !== 'undefined') sorting.orderBy = paginationOldParams.orderBy;
    if (typeof paginationOldParams.sort !== 'undefined') sorting.sort = paginationOldParams.sort || 'asc';
    setOrderBy(paginationOldParams.orderBy || sorting.orderBy);
    setOrder(paginationOldParams.sort || sorting.sort);
    paginationOldParams.orderBy = paginationOldParams.orderBy || sorting.orderBy;
    paginationOldParams.sort = paginationOldParams.sort || sorting.sort;
    ordersSummaryFallBackList.requestAct(moment(date).format('YYYY-MM-DD'), paginationOldParams);
  }, [paginationOldParams]);

  useEffect(() => {
    if (orders.current.state !== STATE_ENUM.INITIAL && date && newRestaurantId) {
      if (typeof paginationFallbackParams.orderBy !== 'undefined') sortingFallBack.orderBy = paginationFallbackParams.orderBy;
      if (typeof paginationFallbackParams.sort !== 'undefined') sorting.sort = paginationFallbackParams.sort || 'asc';

      setOrderByFallback(paginationFallbackParams.orderBy || sortingFallBack.orderBy);
      setOrderFallback(paginationFallbackParams.sort || sortingFallBack.sort);

      paginationFallbackParams.orderBy = paginationFallbackParams.orderBy || sortingFallBack.orderBy;
      paginationFallbackParams.sort = paginationFallbackParams.sort || sortingFallBack.sort;
      orders.requestAct(moment(date).format('YYYY-MM-DD'), newRestaurantId, paginationFallbackParams);
    }
  }, [paginationFallbackParams]);

  useEffect(() => {
    if (selectedRestaurantId) {
      setNewRestaurantId(selectedRestaurantId);
      const res = restaurantListForSchedule.data.find((t) => t.id === selectedRestaurantId);
      setNewRestaurant(res);
      setSelectionRestaurant(res);
    }
  }, [isResetChanged, selectedRestaurantId]);

  const handleCancelChange = () => {
    setNewRestaurant(undefined);
    orders.current.data = undefined;
    setSelectionRestaurant(undefined);
    onClose();
  };

  const handleRestaurantChange = (id) => {
    const res = restaurantListForSchedule.data.find((t) => t.id === id);
    setNewRestaurantId(id);
    onChange(id);
    setNewRestaurant(res);
    setSelectionRestaurant(res);
  };

  useEffect(() => {
    if (selectedRestaurantId) {
      setNewRestaurantId(selectedRestaurantId);
      handleRestaurantChange(selectedRestaurantId);
    }
  }, [selectedRestaurantId]);

  useEffect(() => {
    if (date && newRestaurantId) {
      orders.requestAct(date, newRestaurantId, paginationFallbackParams);
    }
  }, [date, newRestaurantId]);

  useEffect(() => {
    if (orders.current.state === STATE_ENUM.READY && newRestaurantId) {
      orders.setState({ state: STATE_ENUM.INITIAL });
      orders.requestAct(date, newRestaurantId);
      setOrderByFallback(orderBy);
      setOrderFallback(order);
    }
  }, [orderByFallback, orderFallback]);

  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <Box sx={modalStyle}>
          <Box sx={{ mt: 3 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} columns={16}>
                <Grid item xs={8}>
                  <Item sx={{ boxShadow: 0 }}>
                    <Box>
                      <Box sx={{ color: '#4caf50' }}>
                        <Typography>Current Restaurant</Typography>
                      </Box>
                      <Box>
                        <Box sx={{ mt: 5, overflow: 'hidden', textOverflow: 'ellipsis', width: '13rem' }}>
                          {thName ? (
                            <Typography sx={{ fontWeight: 'bold' }} noWrap>
                              {thName || '\u00A0'}
                            </Typography>
                          ) : (
                            <Skeleton animation={'wave'} variant="text" height={24} />
                          )}
                        </Box>
                        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '13rem' }}>
                          {enName ? (
                            <Typography sx={{ fontWeight: 'bold' }} noWrap>
                              {enName ? `(${enName})` : '\u00A0'}
                            </Typography>
                          ) : (
                            <Skeleton animation={'wave'} variant="text" height={24} />
                          )}
                        </Box>

                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Typography sx={{ fontWeight: 'bold' }}>Order for </Typography>

                          {status ? (
                            <>
                              <Typography sx={{ fontWeight: 'bold' }}> {printDisplayDate(date || '')}</Typography>
                              <Box
                                key="{item.id2}"
                                className="export-omit"
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {status === BOOKING_STATUS_ENUM.OPEN ? (
                                  <CircleIcon sx={{ fontSize: 14 }} color="success" />
                                ) : status === BOOKING_STATUS_ENUM.ORDERED ? (
                                  <CheckCircle sx={{ fontSize: 14 }} color="success" />
                                ) : (
                                  <CircleIcon sx={{ fontSize: 14 }} color="disabled" />
                                )}
                              </Box>
                              <Typography sx={{ fontWeight: 'bold' }} className="export-omit" variant="h6">
                                Status: {status}
                              </Typography>
                            </>
                          ) : (
                            <Skeleton animation={'wave'} variant="text" width={109.8} height={24} />
                          )}
                        </Box>
                      </Box>

                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Paper elevation={0} sx={{ width: '100%', height: '100%', overflow: 'visible' }}>
                          <TableContainer sx={{ height: 500, minHeight: 500 }}>
                            <Table
                              stickyHeader
                              aria-labelledby="tableTitle"
                              size="small"
                              sx={{
                                minWidth: 750,
                              }}
                            >
                              <EnhancedTableHead
                                headCells={headCellsOrder}
                                order={order || 'asc'}
                                orderBy={orderBy || ''}
                                setOrder={setOrder}
                                setOrderBy={setOrderBy}
                                setPaginationParams={setPaginationOldParams}
                                disabledSorting
                                respectSortAble
                              />
                              <ConditionalWrapper
                                condition={ordersSummaryFallBackList.current.data?.order?.length !== 0}
                                alternativeNode={<TableBodyNodata page="order" src={theAdminImg} title="No one has placed an order yet." />}
                              >
                                <EnhancedTableBodyCurrentRestaurantOnSchedule
                                  rows={ordersSummaryFallBackList?.current?.data?.order}
                                  order={order}
                                  orderBy={orderBy}
                                  page={page}
                                  rowsPerPage={rowsPerPage}
                                  {...props}
                                  setOrderBy={setOrderBy}
                                  setOrder={setOrder}
                                  bookingId={moment(date).format('YYYY-MM-DD')}
                                />
                              </ConditionalWrapper>
                            </Table>
                          </TableContainer>
                          <TableContainer>
                            <Table>
                              <EnhancedTableTotalPriceOrderV2 className="export-omit" totalPrice={ordersSummaryFallBackList.current.data?.totalPrice} />
                            </Table>
                          </TableContainer>
                          <EnhancedTablePaginationV2
                            total={ordersSummaryFallBackList.current.pagination?.total || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                            setPaginationParams={setPaginationOldParams}
                            addRowsPerPageOption={[
                              { value: 60, label: '60' },
                              { value: -1, label: 'All' },
                            ]}
                            disabledPagination={ordersSummaryFallBackList?.current?.data?.order?.length === 0}
                          />
                        </Paper>
                      </Box>
                    </Box>
                  </Item>
                </Grid>
                <Grid item xs={8}>
                  <Item sx={{ boxShadow: 0 }}>
                    <Box sx={{ color: '#4caf50' }}>
                      <Typography>New Restaurant</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Box sx={{ mt: 5, overflow: 'hidden', textOverflow: 'ellipsis', width: '13rem' }}>
                          {newRestaurant?.thName ? (
                            <Typography sx={{ fontWeight: 'bold' }} noWrap>
                              {newRestaurant?.thName || '\u00A0'}
                            </Typography>
                          ) : (
                            <Skeleton animation={'wave'} variant="text" height={24} />
                          )}
                        </Box>
                        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '13rem' }}>
                          {newRestaurant?.enName ? (
                            <Typography sx={{ fontWeight: 'bold' }} noWrap>
                              {newRestaurant?.enName ? `(${newRestaurant.enName})` : '\u00A0'}
                            </Typography>
                          ) : (
                            <Skeleton animation={'wave'} variant="text" height={24} />
                          )}
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Typography sx={{ fontWeight: 'bold' }}>Order for {printDisplayDate(date || '')}</Typography>

                          {newRestaurant ? (
                            <>
                              <Box
                                key="{item.id2}"
                                className="export-omit"
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {status === BOOKING_STATUS_ENUM.OPEN ? (
                                  <CircleIcon sx={{ fontSize: 14 }} color="success" />
                                ) : status === BOOKING_STATUS_ENUM.ORDERED ? (
                                  <CheckCircle sx={{ fontSize: 14 }} color="success" />
                                ) : (
                                  <CircleIcon sx={{ fontSize: 14 }} color="disabled" />
                                )}
                              </Box>
                              <Typography sx={{ fontWeight: 'bold' }} className="export-omit" variant="h6">
                                Status: {status}
                              </Typography>
                            </>
                          ) : (
                            <Skeleton animation={'wave'} variant="text" width={109.8} height={24} />
                          )}
                        </Box>
                      </Box>

                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <SelectRestaurantFallbackMeal
                          restaurantId={newRestaurantId}
                          onChange={handleRestaurantChange}
                          checkLoad={checkLoad}
                          edit={edit}
                        ></SelectRestaurantFallbackMeal>
                      </Box>
                    </Box>

                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Paper elevation={0} sx={{ width: '100%', height: '100%', overflow: 'visible' }}>
                        <TableContainer sx={{ height: 500, minHeight: 500 }}>
                          <Table
                            stickyHeader
                            aria-labelledby="tableTitle"
                            size="small"
                            sx={{
                              minWidth: 750,
                            }}
                          >
                            <EnhancedTableHead
                              headCells={headCellsOrder}
                              order={orderFallback || 'asc'}
                              orderBy={orderByFallback || ''}
                              setOrder={setOrderFallback}
                              setOrderBy={setOrderByFallback}
                              setPaginationParams={setPaginationFallbackParams}
                              disabledSorting
                              respectSortAble
                            />
                            <ConditionalWrapper
                              condition={orders?.current?.data !== undefined}
                              alternativeNode={<TableBodyNodata page="order" src={theAdminImg} title="No one has placed an order yet." />}
                            >
                              <EnhancedTableBodyFallbackMealOrder
                                rows={orders?.current?.data?.order}
                                order={orderFallback}
                                orderBy={orderByFallback}
                                page={pageFallback}
                                rowsPerPage={rowsFallbackPerPage}
                                setOrderBy={setOrderByFallback}
                                setOrder={setOrderFallback}
                              />
                            </ConditionalWrapper>
                          </Table>
                        </TableContainer>
                        <TableContainer>
                          <Table>
                            <EnhancedTableTotalPriceOrderV2 className="export-omit" totalPrice={orders?.current?.data?.totalPrice} />
                          </Table>
                        </TableContainer>
                        <EnhancedTablePaginationV2
                          total={orders?.current?.pagination?.total || 0}
                          rowsPerPage={rowsFallbackPerPage}
                          page={pageFallback}
                          setPage={setFallbackPage}
                          setRowsPerPage={setFallbackRowsPerPage}
                          setPaginationParams={setPaginationFallbackParams}
                          addRowsPerPageOption={[
                            { value: 60, label: '60' },
                            { value: -1, label: 'All' },
                          ]}
                          disabledPagination={orders?.current?.data === undefined}
                        />
                      </Paper>
                    </Box>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
            <Button
              onClick={onConfirm}
              variant="contained"
              disabled={
                orders === undefined ||
                orders?.current === undefined ||
                orders?.current?.data === undefined ||
                orders?.current?.data?.order === undefined ||
                orders?.current?.data?.order?.length === 0 ||
                newRestaurantId === restaurantId
              }
              size="small"
              sx={{ mt: 1 }}
            >
              SAVE CHANGE
            </Button>
            <Button onClick={handleCancelChange} variant="contained" color="error" size="small" sx={{ mt: 1 }}>
              CANCEL
            </Button>
          </Box>
        </Box>
      </Backdrop>
    </div>
  );
}

import React, { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { Button, Stack, TableBody, TableCell, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { ModalRestaurant } from '@/components/index'; // need to integrate with requirement, state, and routing
import { DeleteRestaurant, ModalType, order, restaurantOrderByType, UpdateRestaurant, QueryParamsGetList, HiddenActionType } from '@/types/index';
import { RestaurantSchema } from '@/types/schema/restaurant';
import { printDisplayDate } from '@/utils/index';
import { useSnackbarContext, useRestaurantContext, useRestaurantBookingContext } from '@/services/index';
import { apiPathV1 } from '@/app.constants/apiCallPaths/api.path.v1';
import { useNavigate } from 'react-router-dom';
import { LIMIT_EN_CHAR, LIMIT_TH_CHAR, DEFAULT_ROW_PER_PAGE } from '@/app.constants/index';
import { updateRestaurantSchema } from '@/src/types/schema/updateRestaurant';
import { common, grey } from '@mui/material/colors';
import { STATE_ENUM } from '../../../types/schema/enum/common.enum';

interface EnhancedTableBodyProps {
  paginationParams: QueryParamsGetList<restaurantOrderByType>;
  order: order | null;
  orderBy: restaurantOrderByType | null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderBy: React.Dispatch<React.SetStateAction<restaurantOrderByType | null>>;
  setOrder: React.Dispatch<React.SetStateAction<order | null>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setTotal: Dispatch<SetStateAction<number>>;
  setIsChange: Dispatch<SetStateAction<boolean>>;
  total: number;
  languageSearch: 'EnTh' | 'Th' | 'En' | null;
  hiddenActionInSearchMode: HiddenActionType;
  setHiddenActionInSearchMode: Dispatch<SetStateAction<HiddenActionType>>;
}

export function EnhancedTableBody(props: EnhancedTableBodyProps) {
  const {
    paginationParams,
    order,
    orderBy,
    setOpen,
    setOrderBy,
    setOrder,
    setPage,
    setTotal,
    setIsChange,
    total,
    languageSearch,
    hiddenActionInSearchMode,
    setHiddenActionInSearchMode,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState<ModalType>('');
  const [thNameErrorText, setThNameErrorText] = React.useState('');
  const [enNameErrorText, setEnNameErrorText] = React.useState('');
  const [thaiText, setThaiText] = React.useState<string>('');
  const [engText, setEngText] = React.useState<string>('');
  const [prevRestaurant, setPrevRestaurant] = React.useState({
    prevThai: '',
    prevEng: '',
  });
  const [validate, setValidate] = React.useState(false);
  const [checkDup, setCheckDup] = useState(false);
  const [checkLoad, setCheckLoad] = useState(false);
  const countThaiText = useRef(0);
  const countEngText = useRef(0);
  const [editRowIndex, setEditRowIndex] = React.useState<undefined | number>(-1);
  const thaiChar = LIMIT_TH_CHAR;
  const engChar = LIMIT_EN_CHAR;
  const [restaurantId, setRestaurantId] = useState('');
  const searchText = paginationParams.search;
  const searchEngTextRefs = useRef<HTMLDivElement[]>([]);
  const searchThaiTextRefs = useRef<HTMLDivElement[]>([]);
  const [lastEditRowIndex, setLastEditRowIndex] = useState<undefined | number>();
  // use navigate
  const navigate = useNavigate();
  // use context
  const { setResponseStatus } = useSnackbarContext();
  const {
    restaurantList,
    restaurantCreateData,
    restaurantUpdateData,
    restaurantDeleteData,
    setRestaurantList,
    setRestaurantUpdate,
    setRestaurantUpdateData,
    getRestaurantIdUpdateList,
    setRestaurantDelete,
    getRestaurantIdDeleteList,
    setRestaurantDeleteData,
    setRestaurantListForSchedule,
  } = useRestaurantContext();

  const { year, month, getRestaurantBookingList } = useRestaurantBookingContext();

  const handleOpenDelete = (id: string) => {
    setType('DELETE');
    setOpenModal(true);
    setRestaurantId(id);
  };

  const handleClickSave = (id: string) => {
    const payload = {
      thName: thaiText,
      enName: engText,
    } as updateRestaurantSchema;
    setRestaurantId(id);
    setCheckLoad(true);
    setRestaurantUpdate(apiPathV1.restaurantUpdate, payload, id);
    setValidate(false);
  };

  const updateList = (currentTotal: number) => {
    // NOTE: update these list for other pages because some data has been deleted
    setRestaurantListForSchedule(apiPathV1.restaurantList);
    getRestaurantBookingList(apiPathV1.restaurantBookingList, year, month);

    const remainingRows = getRestaurantIdDeleteList(restaurantId);
    const maxRowsPage = (paginationParams.page + 1) * (paginationParams.itemPerPage || DEFAULT_ROW_PER_PAGE);

    // NOTE: case no remaining row after delete, then load previous page
    if (remainingRows === 0 && paginationParams.page !== 0) {
      setIsChange(false);
      setPage(paginationParams.page - 1);
      setRestaurantList(apiPathV1.restaurantList, { ...paginationParams, page: paginationParams.page - 1 });
    } else if (currentTotal === maxRowsPage) {
      // NOTE: case no next page after delete, then load current page (as last page)
      setIsChange(false);
      setRestaurantList(apiPathV1.restaurantList, paginationParams);
    }
  };

  function handleEditClick(index: number, thName: string, enName: string) {
    setThNameErrorText('');
    setEnNameErrorText('');
    setThaiText(thName);
    setEngText(enName);
    setEditRowIndex(index);
    setPrevRestaurant({
      prevThai: thName,
      prevEng: enName,
    });
    countEngText.current = enName.trim().length;
    countThaiText.current = thName.trim().length;

    /** Set state edit when user click edit button */
    setRestaurantUpdateData({
      state: STATE_ENUM.EDITING,
    } as UpdateRestaurant);
  }

  const handleThaiNameTextChange = (event: any, index: number) => {
    countThaiText.current = event.target.value.trim().length;
    setEditRowIndex(index);
    setThaiText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setThNameErrorText('Thai name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setThNameErrorText('Max 100 characters.');
    } else {
      if (enNameErrorText === '') {
        setValidate(true);
      }
      setThNameErrorText('');
    }
  };

  const handleEngNameTextChange = (event: any, index: number) => {
    // assign to editRowIndex
    countEngText.current = event.target.value.trim().length;
    setEditRowIndex(index);
    setEngText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(engChar)) {
      setEnNameErrorText('English name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setEnNameErrorText('English name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setEnNameErrorText('Max 100 characters.');
    } else {
      if (thNameErrorText === '') {
        setValidate(true);
      }
      setEnNameErrorText('');
    }
  };

  const handleOutOfFocusThaiName = () => {
    if (countThaiText.current === 0) {
      setThaiText('');
    }
  };

  const handleOutOfFocusEngName = () => {
    if (countEngText.current === 0) {
      setEngText('');
    }
  };

  const checkDuplicateThai = () => {
    const tempArray = restaurantList.data;
    return tempArray.find((item: RestaurantSchema) => item.thName === thaiText?.trim() && prevRestaurant.prevThai !== thaiText?.trim());
  };

  const checkDuplicateEng = () => {
    const tempArray = restaurantList.data;
    return tempArray.find(
      (item: RestaurantSchema) => item.enName.toUpperCase() === engText?.trim().toUpperCase() && prevRestaurant.prevEng !== engText?.trim()
    );
  };

  const handleClickRow = (restaurantId: string, restaurantName?: string, ratingAvg?: number, ratingCount?: number, ratingTotal?: number) => {
    if (editRowIndex === -1 && !checkLoad) {
      navigate(restaurantId + '/menu', { state: { restaurantName, ratingAvg, ratingCount, ratingTotal } });
    }
  };

  const replaceHighlight = (value: string) => {
    if (searchText === undefined) return value;
    const foundStartAt = value.toLowerCase().indexOf(searchText.toLowerCase());
    if (foundStartAt !== -1) {
      const foundEndAt = foundStartAt + searchText.length;
      return (
        value.substring(0, foundStartAt) +
        '<span class="text-[#4caf50]" >' +
        value.substring(foundStartAt, foundEndAt) +
        '</span >' +
        value.substring(foundEndAt)
      );
    } else return value;
  };

  useEffect(() => {
    if (restaurantUpdateData.state === 'ready') {
      let isHiddenAction = false;
      setCheckLoad(false);
      if (paginationParams.search && languageSearch) {
        // NOTE: only search mode can have hidden actions
        let isFoundSearchInTh = false;
        let isFoundSearchInEn = false;
        if (languageSearch.includes('Th')) {
          const foundStartAt = thaiText.toLowerCase().indexOf(paginationParams.search.toLowerCase());
          if (foundStartAt !== -1) isFoundSearchInTh = true;
        }
        if (languageSearch.includes('En')) {
          const foundStartAt = engText.toLowerCase().indexOf(paginationParams.search.toLowerCase());
          if (foundStartAt !== -1) isFoundSearchInEn = true;
        }

        if (!isFoundSearchInTh && !isFoundSearchInEn) {
          isHiddenAction = true;
        }
      }

      if (isHiddenAction) {
        setHiddenActionInSearchMode('updated');
        // NOTE: if there is update Hidden Action in search mode, it's equal to delete row
        setIsChange(true);
        const currentTotal = total - 1;
        setTotal(currentTotal);
        updateList(currentTotal);
      } else {
        getRestaurantIdUpdateList(restaurantId, thaiText?.trim(), engText?.trim());
      }

      setResponseStatus({
        status: 'success',
        message: restaurantUpdateData?.status?.message ?? '',
      });

      setRestaurantUpdateData({
        state: 'initial',
      } as UpdateRestaurant);

      setOpen(true);
      setEditRowIndex(-1);
      setOrderBy(orderBy);
      setOrder(order);

      // NOTE: update these list for other pages because some data has been deleted
      setRestaurantListForSchedule(apiPathV1.restaurantList);
      getRestaurantBookingList(apiPathV1.restaurantBookingList, year, month);
    } else if (restaurantUpdateData.state === 'error') {
      setResponseStatus({
        status: restaurantUpdateData.state,
        message: restaurantUpdateData?.status?.message ?? '',
      });

      setRestaurantUpdateData({
        state: 'initial',
      } as UpdateRestaurant);

      setOpen(true);
      setCheckLoad(false);
    }
  }, [restaurantUpdateData]);

  useEffect(() => {
    if (restaurantDeleteData.state === 'ready') {
      setIsChange(true);
      const currentTotal = total - 1;
      setTotal(currentTotal);

      setResponseStatus({
        status: 'success',
        message: restaurantDeleteData?.status?.message ?? '',
      });

      setRestaurantDeleteData({
        state: 'initial',
      } as DeleteRestaurant);

      setOpenModal(false);
      setOpen(true);
      setOrderBy(orderBy);
      setOrder(order);

      updateList(currentTotal);
    }
  }, [restaurantDeleteData]);

  useEffect(() => {
    if (prevRestaurant.prevThai === thaiText && prevRestaurant.prevEng === engText) {
      setCheckDup(false);
    } else if (checkDuplicateThai() === undefined && checkDuplicateEng() === undefined) {
      setCheckDup(true);
    } else {
      if (thaiText?.trim() === checkDuplicateThai()?.thName) {
        setThNameErrorText('This restaurant already exists.');
        setCheckDup(false);
      }
      if (engText?.trim().toUpperCase() === checkDuplicateEng()?.enName.toUpperCase()) {
        setEnNameErrorText('This restaurant already exists.');
        setCheckDup(false);
        if (checkDuplicateEng()?.enName === prevRestaurant.prevEng) {
          setEnNameErrorText('');
          setCheckDup(true);
        }
      }
    }
    if (countEngText.current !== 0 && countThaiText.current !== 0 && thNameErrorText === '' && enNameErrorText === '') {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [thaiText, engText]);

  useEffect(() => {
    const mapHighlightTH = () => {
      if (searchThaiTextRefs.current) {
        searchThaiTextRefs.current.forEach((thaiRef, index) => {
          thaiRef.innerHTML = restaurantList.data[index] ? replaceHighlight(restaurantList.data[index].thName) : '';
        });
      }
    };
    const mapHighlightEN = () => {
      if (searchEngTextRefs.current) {
        searchEngTextRefs.current.forEach((engRef, index) => {
          engRef.innerHTML = restaurantList.data[index] ? replaceHighlight(restaurantList.data[index].enName) : '';
        });
      }
    };
    if (!languageSearch) return;
    if (languageSearch.includes('Th')) mapHighlightTH();
    if (languageSearch.includes('En')) mapHighlightEN();
  }, [restaurantList.data, languageSearch]);

  useEffect(() => {
    /** Set state to INITIAL after cancel edit */
    if (editRowIndex === -1) {
      setRestaurantUpdateData({
        state: STATE_ENUM.INITIAL,
      } as UpdateRestaurant);
    }

    setLastEditRowIndex(editRowIndex);
    if (!languageSearch || lastEditRowIndex === undefined || lastEditRowIndex === -1) return;

    if (languageSearch.includes('Th') && searchThaiTextRefs.current && restaurantList.data[lastEditRowIndex]) {
      searchThaiTextRefs.current[lastEditRowIndex].innerHTML = replaceHighlight(restaurantList.data[lastEditRowIndex].thName);
    }
    if (languageSearch.includes('En') && searchEngTextRefs.current && restaurantList.data[lastEditRowIndex]) {
      searchEngTextRefs.current[lastEditRowIndex].innerHTML = replaceHighlight(restaurantList.data[lastEditRowIndex].enName);
    }
  }, [editRowIndex]);

  return (
    <TableBody id="tbody-restaurant">
      {restaurantList?.data?.map((row: RestaurantSchema, index: number) => {
        return (
          <TableRow
            id={'tr-restaurant-' + index}
            hover
            tabIndex={index}
            key={`${row.thName}+ ${index}`}
            sx={{ width: 20 }}
            className={`editTableData ${editRowIndex === index ? 'active' : null}`}
          >
            <TableCell
              align="left"
              className="editInput"
              onClick={() => handleClickRow(row.id, row.enName, row.ratingAvg, row.ratingCount, row.ratingTotal)}
              id={'td-edit-restaurant-col1-' + index}
            >
              {languageSearch && languageSearch.includes('Th') && editRowIndex !== index ? (
                <div
                  className={`px-[18px] py-3.5 top-[calc(50%-25px)] left-0 w-[calc(100%-36px)] absolute overflow-hidden whitespace-nowrap  ${
                    checkLoad ? 'text-gray-300' : 'text-ellipsis'
                  }`}
                  id={'highlight-search-text-restaurant-thai-name-' + index}
                  contentEditable
                  suppressContentEditableWarning
                  ref={(ref) => {
                    if (ref !== null) searchThaiTextRefs.current[index] = ref;
                  }}
                >
                  {row.thName}
                </div>
              ) : (
                <TextField
                  id={'edit-th-name-' + index}
                  className="w-full"
                  value={editRowIndex === index ? thaiText : row.thName}
                  error={editRowIndex === index && thNameErrorText !== ''}
                  onChange={(e) => {
                    handleThaiNameTextChange(e, index);
                  }}
                  onBlur={(e) => {
                    handleOutOfFocusThaiName();
                  }}
                  helperText={editRowIndex === index && thNameErrorText !== '' ? thNameErrorText : ''}
                  inputProps={{ maxLength: 101 }}
                  disabled={checkLoad}
                />
              )}
            </TableCell>

            <TableCell
              align="left"
              className="editInput"
              onClick={() => handleClickRow(row.id, row.enName, row.ratingAvg, row.ratingCount, row.ratingTotal)}
              id={'td-edit-restaurant-col2-' + index}
            >
              {languageSearch && languageSearch.includes('En') && editRowIndex !== index ? (
                <div
                  className={`px-[18px] py-3.5 top-[calc(50%-25px)] left-0 w-[calc(100%-36px)] absolute overflow-hidden whitespace-nowrap  ${
                    checkLoad ? 'text-gray-300' : 'text-ellipsis'
                  }`}
                  id={'highlight-search-text-restaurant-eng-name-' + index}
                  contentEditable
                  suppressContentEditableWarning
                  ref={(ref) => {
                    if (ref !== null) searchEngTextRefs.current[index] = ref;
                  }}
                >
                  {row.enName}
                </div>
              ) : (
                <TextField
                  id={'edit-en-name-' + index}
                  className="w-full"
                  value={editRowIndex === index ? engText : row.enName}
                  error={editRowIndex === index && enNameErrorText !== ''}
                  onChange={(e) => {
                    handleEngNameTextChange(e, index);
                  }}
                  onBlur={(e) => {
                    handleOutOfFocusEngName();
                  }}
                  helperText={editRowIndex === index && enNameErrorText !== '' ? enNameErrorText : ''}
                  inputProps={{ maxLength: 101 }}
                  disabled={checkLoad}
                />
              )}
            </TableCell>

            <TableCell
              align="right"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClickRow(row.id, row.enName, row.ratingAvg, row.ratingCount, row.ratingTotal)}
              id={'td-edit-restaurant-col3-' + index}
            >
              <Typography color={checkLoad ? grey[400] : common.black}>{row.ratingCount > 0 ? row.ratingAvg.toFixed(1) : '-'}</Typography>
            </TableCell>

            <TableCell
              align="right"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClickRow(row.id, row.enName, row.ratingAvg, row.ratingCount, row.ratingTotal)}
              id={'td-edit-restaurant-col3-' + index}
            >
              <Typography color={checkLoad ? grey[400] : common.black}>{row.favoriteCount}</Typography>
            </TableCell>

            <TableCell
              align="right"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClickRow(row.id, row.enName, row.ratingAvg, row.ratingCount, row.ratingTotal)}
              id={'td-edit-restaurant-col4-' + index}
            >
              <Typography color={checkLoad ? grey[400] : common.black}>{row.menuItems}</Typography>
            </TableCell>

            <TableCell
              align="right"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClickRow(row.id, row.enName, row.ratingAvg, row.ratingCount, row.ratingTotal)}
              id={'td-edit-restaurant-col5-' + index}
            >
              <Typography color={checkLoad ? grey[400] : common.black}>{row.orderCount}</Typography>
            </TableCell>

            <TableCell
              align="right"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClickRow(row.id, row.enName, row.ratingAvg, row.ratingCount, row.ratingTotal)}
              id={'td-edit-restaurant-col6-' + index}
            >
              <Typography color={checkLoad ? grey[400] : common.black}>{row.orderTotal}</Typography>
            </TableCell>

            <TableCell
              align="left"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClickRow(row.id, row.enName, row.ratingAvg, row.ratingCount, row.ratingTotal)}
              id={'td-edit-restaurant-col7-' + index}
            >
              <Typography color={checkLoad ? grey[400] : common.black}>{printDisplayDate(row.lastOrder)}</Typography>
            </TableCell>

            <TableCell align="center" className="btnActionArea" id={'td-edit-restaurant-col8-' + index}>
              <Stack direction="row" spacing={2} className="btnArea">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="w-20"
                  onClick={() => handleClickSave(row.id)}
                  disabled={!validate || !checkDup || checkLoad}
                  id="btn-save-restaurant-menu"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="w-20"
                  onClick={() => setEditRowIndex(-1)}
                  id={'btn-cancel-restaurant' + index}
                  disabled={checkLoad}
                >
                  Cancel
                </Button>
              </Stack>
              <Stack direction="row" spacing={2} className="btnAreaAction" justifyContent="flex-end">
                <Tooltip title="Edit info" placement="bottom-start">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className="whenHoverPointer"
                    onClick={() => handleEditClick(index, row.thName, row.enName)}
                    id={'btn-edit-restaurant' + index}
                    disabled={
                      restaurantCreateData.state === 'loading' ||
                      restaurantUpdateData.state === 'loading' ||
                      restaurantDeleteData.state === 'loading' ||
                      checkLoad
                    }
                  >
                    <EditIcon></EditIcon>
                  </Button>
                </Tooltip>
                <Tooltip title="Delete" placement="bottom-start">
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => handleOpenDelete(row.id)}
                    id={'btn-delete-restaurant' + index}
                    disabled={
                      restaurantCreateData.state === 'loading' ||
                      restaurantUpdateData.state === 'loading' ||
                      restaurantDeleteData.state === 'loading' ||
                      checkLoad
                    }
                  >
                    <DeleteIcon></DeleteIcon>
                  </Button>
                </Tooltip>
              </Stack>
            </TableCell>
          </TableRow>
        );
      })}

      {/* NOTE: this TableRow is hidden, just for allow table to place modal inside */}
      <TableRow>
        <TableCell sx={{ padding: '0px', border: 'none' }} id="td-modal-restaurant">
          <ModalRestaurant
            openModal={openModal}
            type={type}
            setOpenModal={setOpenModal}
            restaurantId={restaurantId}
            setRestaurantDelete={setRestaurantDelete}
            restaurantDeleteData={restaurantDeleteData}
            setRestaurantDeleteData={setRestaurantDeleteData}
            setType={setType}
            hiddenActionInSearchMode={hiddenActionInSearchMode}
            setHiddenActionInSearchMode={setHiddenActionInSearchMode}
          ></ModalRestaurant>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

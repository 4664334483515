import { AxiosService } from '@/src/utils';
import { apiMethodV1 } from '@/src/app.constants/apiCallMethods';
import { AxiosResponse } from 'axios';
import { ChangeUserResponse } from '@/src/types/crud/role/user.rol';
import { RoleListResponse } from '@/src/types/crud/role/role';

const getUserRoles = (prefix: string, userId: string, hardRejection = true): Promise<RoleListResponse> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);
  const config = {
    ...instance.config,
  };

  return instance.client
    .get(prefix + 'user' + '/' + userId, config)
    .then((res: AxiosResponse<RoleListResponse>) => {
      return res.data;
    })
    .catch(instance.defaultHandleError) as Promise<RoleListResponse>;
};

const getRoles = (prefix: string, hardRejection = true): Promise<RoleListResponse> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);
  const config = {
    ...instance.config,
  };

  return instance.client
    .get(prefix + 'all', config)
    .then((res: AxiosResponse<RoleListResponse>) => {
      return res.data;
    })
    .catch(instance.defaultHandleError) as Promise<RoleListResponse>;
};

const userRoleUpdate = (prefix: string, userId: string, roleId: string, hardRejection = true): Promise<ChangeUserResponse> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);
  const config = {
    ...instance.config,
  };

  return instance.client
    .post(prefix + 'user' + '/' + userId, JSON.stringify({ roleId }), config)
    .then((res: AxiosResponse<ChangeUserResponse>) => {
      return res.data;
    })
    .catch(instance.defaultHandleError) as Promise<ChangeUserResponse>;
};

const userRoleDelete = (prefix: string, userId: string, roleId: string, hardRejection = true): Promise<ChangeUserResponse> => {
  const instance = new AxiosService({ method: apiMethodV1.put, timeout: 12000 }, true, hardRejection);
  const config = {
    ...instance.config,
  };

  return instance.client
    .delete(prefix + 'user' + '/' + userId + '/' + roleId, config)
    .then((res: AxiosResponse<ChangeUserResponse>) => {
      return res.data;
    })
    .catch(instance.defaultHandleError) as Promise<ChangeUserResponse>;
};

export { userRoleUpdate, userRoleDelete, getRoles, getUserRoles };

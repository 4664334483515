import { useContext, useEffect, useState } from 'react';
import { AccessMenuList } from '@/types/index';
import { AccessMenuContext } from './initial.store';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { AuthPermissions } from '../../http';

export function useAccessMenuContext() {
  return useContext(AccessMenuContext);
}

const AccessMenuContextProvider = ({ children }: { children: React.ReactNode }) => {
  // eslint-disable-next-line no-unused-vars
  const [accessMenusData, setAccessMenuData] = useState<AccessMenuList>({
    data: [],
    state: 'initial',
    status: {
      code: 0,
      message: '',
    },
  });

  const getAccessMenu = (prefix: string) => {
    setAccessMenuData({ data: [], state: STATE_ENUM.LOADING });
    AuthPermissions(prefix)
      .then((d) => {
        setAccessMenuData({
          data: d.data,
          state: STATE_ENUM.READY,
          status: d.status,
        });
      })
      .catch((error) => {
        setAccessMenuData({ data: [], error: error?.response, state: STATE_ENUM.ERROR, status: error?.response?.data?.status });
      });
  };
  const accessMenuStore = {
    setAccessMenuData,
    accessMenusData,
    getAccessMenu,
  };

  useEffect(() => {
    return () => {
      setAccessMenuData({ state: 'initial', data: [] });
    };
  }, []);

  return <AccessMenuContext.Provider value={accessMenuStore}>{children}</AccessMenuContext.Provider>;
};

export { AccessMenuContextProvider };

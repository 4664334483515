import React from 'react';
// mui
import { Checkbox, Autocomplete, TextField, FormControl } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styles } from '@/src/styles/theme';
import ClearIcon from '@mui/icons-material/Clear';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AddonAutocomplete = (props: any) => {
  const classes = styles();
  const { callback, sx, selectedAddons, addonsList, clearId } = props;
  return (
    <>
      <FormControl sx={{ ...sx }}>
        <Autocomplete
          multiple
          defaultValue={selectedAddons}
          id="autocomplete-multiple-addons"
          disableCloseOnSelect
          options={addonsList}
          clearIcon={<ClearIcon id={clearId} />}
          componentsProps={{
            popupIndicator: {
              id: `btn-arrow-autocomplete-multiple-addons`,
            },
            clearIndicator: {
              id: `btn-clear-autocomplete-multiple-addons`,
            },
          }}
          getOptionLabel={(option) => `${option.thName} [+${option.price}]`}
          popupIcon={<KeyboardArrowDownIcon />}
          renderInput={(params) => <TextField {...params} size={'small'} label="Select Add-ons (Optional)" />}
          renderOption={(props, option, { selected }) => (
            <li {...props} id={'li-' + addonsList.indexOf(option)}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} id={'add-on-' + addonsList.indexOf(option)} />
              <div className={classes.wrap_text}>{`${option.thName} [+${option.price}]`}</div>
            </li>
          )}
          onChange={(event, values: any, reason: string) => {
            callback(values, reason);
          }}
        />
      </FormControl>
    </>
  );
};

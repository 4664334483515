export const apiPathV2 = {
  // Order API paths
  ordersList: '/v2/orders/',
  // Not implemented yet.
  // orderCreate: '/v2/orders/',
  // orderDelete: '/v2/orders/',
  // orderAllDelete: '/v2/orders/',
  // orderCount: '/v2/orders/amount/',

  // addon api paths
  addonList: '/v2/addons/',
  // Order History paths
  ordersHistory: '/v2/order-history/',

  // menu api paths
  menuList: '/v2/menu-items/', // TODO: recheck to deprecated no-used any where on web? use on mobile?
  menuListWithAddon: '/v2/menu-items/addons/',
  menuListWithAddonNoOptionAddon: '/v2/menu-items/addons/overview/',

  // group api paths
  groupList: '/v2/groups/',

  // Option Addon paths
  optionAddon: '/v2/option-addons/',
  // suspend
  suspendUser: '/v2/users/suspends/',
  // user
  users: '/v2/users',
  dateFormate: '/v2/remote-config/display',

  // user order history
  userOrderHistory: 'v2/users/order-history/',

  // dashboard
  dashboard: '/v2/dashboard',

  // fallback meal
  fallbackMealAdmin: '/v2/fallback-meal/admin',
  restaurantList: '/v2/restaurants',

  // user status
  userStatus: '/v2/users/{userId}/user-status',
};

import { createContext, useCallback, useContext, useState } from 'react';
import { ActionUnit2, commonSetError, initialApiContext2 } from '../common';
import { CommonCrud } from '@/src/types';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { apiPathV1 } from '@/src/app.constants';
import { getRoles } from '../../http/role.v1';

declare namespace ActionTypes {
  export type ChangeUserRole = CommonCrud<any>;
  export type RemoveUserRole = CommonCrud<any>;
  export namespace RequestAct {
    export type FetchRole = () => Promise<any>;
  }
}

interface IRoleStoreContextV1 {
  roles: any[];
  loading: boolean;
  fetchRoles: ActionUnit2<CommonCrud<any>> & { requestAct: ActionTypes.RequestAct.FetchRole };
}

const initialState: IRoleStoreContextV1 = {
  roles: [],
  loading: true,
  fetchRoles: { ...initialApiContext2<CommonCrud<any>, ActionTypes.RequestAct.FetchRole>() },
};

const RoleStoreContext: React.Context<IRoleStoreContextV1> = createContext(initialState);

const RoleStoreContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [roles, setRoles] = useState<CommonCrud<any>>(initialState.fetchRoles.current);

  const getAllRoles = useCallback<ActionTypes.RequestAct.FetchRole>(async () => {
    setRoles({ ...initialState.fetchRoles.current, state: STATE_ENUM.LOADING });
    try {
      const { status, data } = await getRoles(apiPathV1.role);
      setRoles({ state: STATE_ENUM.READY, status, data });
    } catch (error) {
      commonSetError(error, setRoles);
    }
  }, []);

  return (
    <RoleStoreContext.Provider
      value={{
        loading: roles.state === STATE_ENUM.LOADING,
        roles: roles?.data || [],
        fetchRoles: {
          current: roles,
          setState: setRoles,
          requestAct: getAllRoles,
        },
      }}
    >
      {children}
    </RoleStoreContext.Provider>
  );
};

const withRoleStoreContext = (Component: any) => {
  return function UserStoreComponent(props: any) {
    return <RoleStoreContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</RoleStoreContext.Consumer>;
  };
};

const useRoleStoreContext = () => {
  return useContext(RoleStoreContext);
};

export { RoleStoreContextProvider, withRoleStoreContext, useRoleStoreContext };

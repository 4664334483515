import { Dispatch, SetStateAction, createContext } from 'react';
import { AccessMenuList } from '@/types/index';
const AccessMenuContext: React.Context<{
  setAccessMenuData: Dispatch<SetStateAction<AccessMenuList>>;
  accessMenusData: AccessMenuList;
  getAccessMenu: (prefix: string) => void;
}> = createContext({
  accessMenusData: {} as AccessMenuList,
  setAccessMenuData: (val: SetStateAction<AccessMenuList>) => {},
  getAccessMenu: (prefix: string) => {},
});

export { AccessMenuContext };

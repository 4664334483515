import './_presets/debug'; // logging information for intellisense
import './_presets/production'; // for production to remove all standard logs
import './styles/global.scss';
import './i18n';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { BrowserRouter as Router, Routes, Route, useLocation, createRoutesFromChildren, matchRoutes, useNavigationType } from 'react-router-dom'; // HistoryRouterProps
import { createBrowserHistory } from 'history';
import { AdminGuardRoute as AdminGuard, Layout, LoadingIcon } from '@/components/index';
import {
  Orders,
  Schedule,
  Restaurants,
  OrderHistory,
  Error,
  Redirect as RedirectPage,
  RestaurantPage,
  Menu,
  AddOns,
  Groups,
  RestaurantDetail,
  Notification,
  UserPage,
  AccessDeniedPage,
} from '@/pages/index';
import { StyledEngineProvider } from '@mui/material';
import { theme } from './styles/theme/index';
import { ThemeProvider } from '@mui/material/styles';
import {
  firebaseAuth,
  useAppContext,
  OrderHistoryContextV2Provider,
  CombineContextProviders,
  MenuAddOnContextProviders,
  MenuAddOnGroupContextProviders,
  UserStoreContextProvider,
  SnackBarContextProvider,
  AccessMenuContextProvider,
} from '@/services/index';
import { DispatchEventDetail } from '@/types/index';
import { HistoryHoc } from '@/components/hocs/withHistory';
import { OptionAddon } from '@/pages/restaurants/restaurantMenus/groups/optionAddon';
import { ModalSessionExpired } from './components/ui/modal/modalSessionexpired';
import { fullSessionLogout, getDisplayConfig, setBrowserUid } from './utils';
import { NotificationsContextV2Provider } from '@/store/notifications.v2.store';
import * as Sentry from '@sentry/react';
import { ENVS } from './app.constants';
import { BrowserTracing } from '@sentry/react';
import UserOrderHistory from './pages/userOrderHistory';
import { UserOrderHistoryContextProvider } from '@/store/userOderHistory.store';
import DashboardPage from './pages/dashboard';
import { DashboardContextProvider } from '@/store/dashboard.store';
import { useCookies } from 'react-cookie';
import PermissionWrapper from './utils/permissionWrapper';
import { RoleStoreContextProvider } from '@/store/role.store';
const isEnableSentry = ENVS.ENVIRONMENT === 'PROD';
if (isEnableSentry) {
  Sentry.init({
    dsn: ENVS.SENTRY_DSN,
    autoSessionTracking: true,
    enabled: true,
    integrations: [
      // eslint-disable-next-line deprecation/deprecation
      new BrowserTracing({
        // eslint-disable-next-line deprecation/deprecation
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(React.useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
      }),
    ],
    tracesSampleRate: 1.0,
    environment: `web-${ENVS.ENVIRONMENT.toLowerCase()}`,
  });
}

// test
const UserSessionExpired = (props: any) => {
  // const navigate = useNavigate();
  const [useListener, setListener] = useState<boolean>((window as any).EVENT_USER_SESSION_EXPIRED || false);

  useEffect(() => {
    if (!useListener) {
      // NOTE  we are watching for event fire from axios 401 error response
      document.addEventListener('EVENT_USER_SESSION_EXPIRED', function (e: any) {
        const event: Event & DispatchEventDetail = e;
        if (event?.detail?.code === 401 || event?.detail?.code === 406) {
          console.error('[EVENT_USER_SESSION_EXPIRED]', event.detail);
          (window as any).EVENT_USER_SESSION_EXPIRED = true;
          setListener((window as any).EVENT_USER_SESSION_EXPIRED);
        }
      });
    }
  }, [useListener, setListener]);

  return useListener ? (
    <ModalSessionExpired
      forceCloseModal={true}
      logoutCallback={() => {
        fullSessionLogout(props.history, true);
      }}
    />
  ) : (
    <></>
  );
};

// eslint-disable-next-line no-undef, deprecation/deprecation
function App(): JSX.Element {
  // NOTE set browser uid to identify multiple browsers open bu their id
  if (!(window as any).uid) setBrowserUid();

  const history = createBrowserHistory();
  // adds history to all
  const AdminGuardRoute2 = HistoryHoc(AdminGuard, { history });
  const [user, loading, error] = useAuthState(firebaseAuth);
  const { setCurrentUser } = useAppContext();
  const [cookies] = useCookies(['jwtToken', 'accessToken']);

  // NOTE: for developer > log only when cookies/user is changed
  useEffect(() => console.log('[app][cookies] ', cookies), [cookies]);
  useEffect(() => console.log('[app][user] ', user), [user]);
  //
  useEffect(() => {
    if (!loading && user && cookies) {
      setCurrentUser(user);
      getDisplayConfig();

      if (isEnableSentry) {
        // eslint-disable-next-line deprecation/deprecation
        Sentry.configureScope((scope) => {
          scope.setUser({ id: user.uid, email: user.email || 'anonymouse' });
        });
      }
    }
  }, [user, loading, error, isEnableSentry]);

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const allRoutes = (
    <>
      <Route
        path="/"
        element={
          <AdminGuardRoute2>
            <Layout history={history} />
          </AdminGuardRoute2>
        }
      >
        <Route
          index
          element={
            <PermissionWrapper currentPage="/dashboard">
              <DashboardContextProvider>
                <DashboardPage />
              </DashboardContextProvider>
            </PermissionWrapper>
          }
        ></Route>
        <Route
          path="dashboard"
          element={
            <PermissionWrapper currentPage="/dashboard">
              <DashboardContextProvider>
                <DashboardPage />
              </DashboardContextProvider>
            </PermissionWrapper>
          }
        ></Route>
        <Route
          path="orders"
          element={
            <PermissionWrapper currentPage="/orders">
              <MenuAddOnContextProviders>
                <Orders />
              </MenuAddOnContextProviders>
            </PermissionWrapper>
          }
        />
        <Route
          path="orderhistory"
          element={
            <PermissionWrapper currentPage="/orderhistory">
              <OrderHistoryContextV2Provider>
                <OrderHistory />
              </OrderHistoryContextV2Provider>
            </PermissionWrapper>
          }
        />
        <Route
          path="schedule"
          element={
            <PermissionWrapper currentPage="/schedule">
              <Schedule />
            </PermissionWrapper>
          }
        ></Route>
        <Route path="access-denied" element={<AccessDeniedPage />}></Route>
        <Route
          path="/restaurants"
          element={
            <PermissionWrapper currentPage="/restaurants">
              <MenuAddOnGroupContextProviders>
                <RestaurantPage />
              </MenuAddOnGroupContextProviders>
            </PermissionWrapper>
          }
        >
          <Route
            index
            element={
              <PermissionWrapper currentPage="/restaurants">
                <Restaurants />
              </PermissionWrapper>
            }
          />
          <Route
            path=":id"
            element={
              <PermissionWrapper currentPage="/restaurants">
                <RestaurantDetail />
              </PermissionWrapper>
            }
          >
            <Route
              path="menu"
              element={
                <PermissionWrapper currentPage="/restaurants">
                  <Menu />
                </PermissionWrapper>
              }
            />
            <Route
              path="addons"
              element={
                <PermissionWrapper currentPage="/restaurants">
                  <AddOns />
                </PermissionWrapper>
              }
            />
            <Route
              path="group"
              element={
                <PermissionWrapper currentPage="/restaurants">
                  <Groups />
                </PermissionWrapper>
              }
            />
            <Route
              path="group/:groupId"
              element={
                <PermissionWrapper currentPage="/restaurants">
                  <OptionAddon />
                </PermissionWrapper>
              }
            />
          </Route>
        </Route>
        <Route
          path="notifications"
          element={
            <PermissionWrapper currentPage="/notifications">
              <NotificationsContextV2Provider>
                <Notification />
              </NotificationsContextV2Provider>
            </PermissionWrapper>
          }
        />
        <Route
          path="users"
          element={
            <PermissionWrapper currentPage="/users">
              <SnackBarContextProvider>
                <UserStoreContextProvider>
                  <RoleStoreContextProvider>
                    <UserPage />
                  </RoleStoreContextProvider>
                </UserStoreContextProvider>
              </SnackBarContextProvider>
            </PermissionWrapper>
          }
        ></Route>
        <Route
          path="/users/:userId/order-history"
          element={
            <PermissionWrapper currentPage="/users">
              <UserOrderHistoryContextProvider>
                <UserOrderHistory />
              </UserOrderHistoryContextProvider>
            </PermissionWrapper>
          }
        />
      </Route>
      <Route path="/error" element={<Error history={history} />}></Route>
      <Route path="/redirect" element={<RedirectPage history={history} />}></Route>
    </>
  );
  return (
    <React.Fragment>
      {!loading ? (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AccessMenuContextProvider>
              <CombineContextProviders>
                <Router>
                  {isEnableSentry ? <SentryRoutes>{allRoutes}</SentryRoutes> : <Routes>{allRoutes}</Routes>}
                  <UserSessionExpired history={history} />
                </Router>
              </CombineContextProviders>
            </AccessMenuContextProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <LoadingIcon setCenter={true}></LoadingIcon>
        </ThemeProvider>
      )}
    </React.Fragment>
  );
}

const AppWithSentry = isEnableSentry ? Sentry.withProfiler(App) : App;
export default AppWithSentry;

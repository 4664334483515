import { createContext, Dispatch, SetStateAction } from 'react';
import {
  GetRestaurantBookingList,
  PostRestaurantBooking,
  PostRestaurantBookingList,
  PutRestaurantBooking,
  RemoveRestaurantBooking,
  PutRestaurantBookingStatus,
  RestaurantBookingSchema,
  RestaurantBookingStatus,
} from '@/types/index';
import { createRestaurantBookingSchema } from '@/types/schema/createRestaurantBooking';
import { updateRestaurantBookingSchema } from '@/types/schema/updateRestaurantBooking';
import { statusRestaurantType, updateRestaurantBookingStatusSchema } from '@/types/schema/updateRestaurantBookingStatus';
const RestaurantBookingContext: React.Context<{
  year: string;
  month: string;

  restaurantBookingList: GetRestaurantBookingList;
  getRestaurantBookingList: (prefix: string, year: string, month: string) => void;

  restaurantBookingCreateData: PostRestaurantBooking;
  setRestaurantBookingCreate: (prefix: string, payload: createRestaurantBookingSchema, callBackFunc?: () => void) => void;
  setRestaurantBookingCreateData: Dispatch<SetStateAction<PostRestaurantBooking>>;
  getRestaurantBookingCreate: (restaurantBookings: RestaurantBookingSchema) => void;

  restaurantBookingCreateListData: PostRestaurantBookingList;
  setRestaurantBookingCreateList: (prefix: string, payload: createRestaurantBookingSchema[]) => void;
  setRestaurantBookingCreateListData: Dispatch<SetStateAction<PostRestaurantBookingList>>;
  getRestaurantBookingCreateList: (restaurantBookings: RestaurantBookingSchema[]) => void;

  restaurantBookingUpdateData: PutRestaurantBooking;
  setRestaurantBookingUpdate: (prefix: string, payload: updateRestaurantBookingSchema, bookingId: string, callBackFunc?: () => void) => void;

  restaurantBookingRemoveData: RemoveRestaurantBooking;
  setRestaurantBookingRemove: (prefix: string, bookingId: string) => void;
  setRestaurantBookingRemoveData: Dispatch<SetStateAction<RemoveRestaurantBooking>>;

  getRestaurantBookingUpdateList: (date: string, restaurantId: string, status: statusRestaurantType, thName: string, enName: string) => void;
  setRestaurantBookingUpdateData: Dispatch<SetStateAction<PutRestaurantBooking>>;

  restaurantBookingStatusUpdateData: PutRestaurantBookingStatus;
  setRestaurantBookingStatusUpdate: (prefix: string, payload: updateRestaurantBookingStatusSchema, bookingId: string, callBackFunc?: () => void) => void;

  setRestaurantBookingStatusUpdateData: Dispatch<SetStateAction<PutRestaurantBookingStatus>>;

  getRestaurantBookingUpdateStatusList: (date: string, status: statusRestaurantType) => void;

  restaurantBookingStatusCheckingData: RestaurantBookingStatus;
  setRestaurantBookingStatusCheckingData: Dispatch<SetStateAction<RestaurantBookingStatus>>;
  setRestaurantBookingStatusChecking: (prefix: string, bookingId: string, callBackFunc?: () => void) => void;
}> = createContext({
  // Initial RestaurantBookingContext
  year: '',
  month: '',

  restaurantBookingList: {
    error: '',
    state: '',
    status: {
      code: 0,
      message: '',
    },
    data: [],
    pagination: {
      page: 0,
      total: 0,
      perPage: 0,
    },
  } as GetRestaurantBookingList,

  getRestaurantBookingList: (prefix: string, year: string, month: string) => {},

  restaurantBookingCreateData: {} as PostRestaurantBooking,
  setRestaurantBookingCreate: (prefix: string, payload: createRestaurantBookingSchema, callBackFunc?: () => void) => {},
  setRestaurantBookingCreateData: (val: SetStateAction<PostRestaurantBooking>) => {},
  getRestaurantBookingCreate: (restaurantBookings: RestaurantBookingSchema) => {},

  restaurantBookingCreateListData: {} as PostRestaurantBookingList,
  setRestaurantBookingCreateList: (prefix: string, payload: createRestaurantBookingSchema[]) => {},
  setRestaurantBookingCreateListData: (val: SetStateAction<PostRestaurantBookingList>) => {},
  getRestaurantBookingCreateList: (restaurantBookings: RestaurantBookingSchema[]) => {},

  restaurantBookingUpdateData: {} as PutRestaurantBooking,
  setRestaurantBookingUpdate: (prefix: string, payload: updateRestaurantBookingSchema, bookingId: string, callBackFunc?: () => void) => {},
  setRestaurantBookingUpdateData: (val: SetStateAction<PutRestaurantBooking>) => {},

  restaurantBookingRemoveData: {} as RemoveRestaurantBooking,
  setRestaurantBookingRemove: (prefix: string, bookingId: string) => {},
  setRestaurantBookingRemoveData: (val: SetStateAction<RemoveRestaurantBooking>) => {},

  getRestaurantBookingUpdateList: (date: string, restaurantId: string, status: statusRestaurantType, thName: string, enName: string) => {},

  restaurantBookingStatusUpdateData: {} as PutRestaurantBookingStatus,
  setRestaurantBookingStatusUpdate: (prefix: string, payload: updateRestaurantBookingStatusSchema, bookingId: string, callBackFunc?: () => void) => {},
  setRestaurantBookingStatusUpdateData: (val: SetStateAction<PutRestaurantBookingStatus>) => {},

  getRestaurantBookingUpdateStatusList: (date: string, status: statusRestaurantType) => {},

  restaurantBookingStatusCheckingData: {} as RestaurantBookingStatus,
  setRestaurantBookingStatusCheckingData: (val: SetStateAction<RestaurantBookingStatus>) => {},
  setRestaurantBookingStatusChecking: (prefix: string, bookingId: string) => {},
});
export { RestaurantBookingContext };
